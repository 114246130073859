import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useLocation, useNavigate} from 'react-router-dom'
import AuthContextMiddleware from '../middleware/AuthContextMiddleware.js';
import {Button, Link, Slide, TextField} from "@mui/material";
import jwt_decode from 'jwt-decode';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import wideLogoLightBetaBig from '../../images/wide-logo-white.svg';
import wideLogoLight from "../../images/wideLogo-icon.svg";
import Divider from "@mui/material/Divider";
import {makeStyles} from "@mui/styles";
import back from "../../images/back-icon.svg";
import 'react-phone-number-input/style.css'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import {useForm} from "react-hook-form"
import MaterialPhoneNumber from "../middleware/PhoneNumber.js";
import DialogSmall from "../components/DialogSmall.js";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";
import {StyledBackdrop} from "../styles/Styles.js";
import ErrorPage from "../pages/ErrorPage.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
    dialogTitle: {
        'textAlign': 'center !important',
        'lineHeight': '1.3 !important',
        'padding': '16px 24px !important',
        'fontSize': '15px !important',
        'fontWeight': '500 !important'
    },
}));

export default function SendOtp() {
    const {
        // Either pass a `control` property to the component
        // or wrap it in a `<FormProvider/>`.
        control,
        handleSubmit
    } = useForm()

    window.addEventListener('offline', () => {
        window.location.reload();
        return 0;
    });

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const invitationToken = query.get('invitation_token');
    const authCtx = useContext(AuthContextMiddleware);
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const [passwordDialog, setPasswordDialog] = useState(false);
    const checkPasswordUrl = `${process.env.REACT_APP_BASE_URL}/management/profile/checkPassword`;
    const acceptInvitationUrl = `${process.env.REACT_APP_BASE_URL}/management/auth/invitation/accept`;
    const [passwordCriteriasError, setPasswordCriteriasError] = useState(false);
    const [passwordNotEqualError, setPasswordNotEqualError] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [email, setEmail] = useState("")
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [openSupportDialog, setOpenSupportDialog] = useState(false);
    const classes = useStyles();
    const [openOTPDialog, setOpenOTPDialog] = useState(false);
    const [phoneValue, setPhoneValue] = useState(sessionStorage.getItem('phone'))
    const [password, setPassword] = useState(sessionStorage.getItem('newPassword'))
    const [phoneLang, setPhoneLang] = useState(sessionStorage.getItem('phone-lang'))
    const [isValid, setIsValid] = useState(true);
    const sendOtpUrl = `${process.env.REACT_APP_BASE_URL}/management/otp/send`;
    const otpTimer = process.env.REACT_APP_ENV === 'STAGE' || process.env.REACT_APP_ENV === 'DEV' ? 10 : 60;
    const [seconds, setSeconds] = useState(otpTimer);
    const [disabled, setDisabled] = useState(true)



    useEffect(() => {
        if (navigator.onLine) {
            //checkPassword();
        }
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                clearInterval(interval);
                setDisabled(false)
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const defaultValues = {
        otpCode: "",
    };

    const handleOTPClose = () => {
        setOpenOTPDialog(false)
    }
    const formValues = useRef(defaultValues);

    const acceptInvitation = () => {
        let otpCode = formValues.current.otpCode;
        fetch(acceptInvitationUrl, {
            method: 'POST', headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                invitationToken: invitationToken,
                newPassword: password,
                phone: phoneValue,
                code: otpCode
            }),
        }).then((res) => {
            return res.json();
        }).then((data) => {
            let decoded = jwt_decode(data.accessToken);
            if (localStorage.getItem('username-wide')) {
                let prevUsername = localStorage.getItem('username-wide');
                if (prevUsername !== decoded.email) {
                    sessionStorage.clear();
                    localStorage.removeItem('webauthn')
                }
            }
            localStorage.setItem('username-wide', decoded.email);
            authCtx.login(data.accessToken, data.refreshToken, data.pinCode);
            if (localStorage.getItem('people')) {
                navigate('/settings/people');
                localStorage.removeItem('people')
            } else {
                navigate('/dashboard');
            }
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const checkPassword = () => {
        setIsLoadingData(true)
        fetch(checkPasswordUrl, {
            method: 'POST', headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                token: invitationToken
            }),
        }).then((res) => {
            return res.json();
        }).then((res) => {
            if (res.ok) {
                setEmail(res.message)
            } else {
                if (res.status === 500) {
                    setIsValid(false)
                }
            }
            setIsLoadingData(false)
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        formValues.current = {...formValues.current, [name]: value}
    };

    const handlePasswordDialog = () => {
        setPasswordDialog(current => !current);
    }

    function reload() {
        window.location.reload();
    }

    function MailTo({email, subject, body, ...props}) {
        return (
            <a style={{'color': themeContext.color.main, 'textAlign': "center", 'textDecoration': 'none', 'width': '100%'}}
               href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
                {props.children}
            </a>
        );
    }

    const linkToRequestCredentials = () => {
        return navigate('/login/request-credentials');
    }

    const handleCloseSupportDialog = () => {
        setOpenSupportDialog(false);
    }

    const turnBack = () => {
        return navigate(-1);
    }

    const resendOtp = () => {
        setSeconds(otpTimer)
        setDisabled(true)
        fetch(sendOtpUrl, {
            method: 'POST', body: JSON.stringify({phone: phoneValue, lang: phoneLang}),
            headers: {'Content-Type': 'application/json'},
        }).then((res) => {
            return res.json();
        }).then((res) => {
            if (res.ok) {
                setOpenOTPDialog(true)
            }
        });
    }

    const navigateToLogin = e => {
        return navigate('/login')
    }

    return (
        <div className="container-fluid" style={{'backgroundColor': themeContext.color.white}}>
            <div className="row no-gutter">
                <div className="col-md-6 d-none d-md-flex bg-image">
                    <Row style={{'margin': 'auto'}}>
                        <Col>
                            <img style={{
                                'height': '74.67px',
                                'marginBottom': '27px'
                            }} src={wideLogoLightBetaBig} alt="wide-logo-light"/>
                            <h6 style={{
                                'color': themeContext.color.white,
                                'fontSize': '16px',
                                'fontWeight': '300'
                            }}>{t('login.payoff-left-1')}</h6>
                            <h6 style={{
                                'color': themeContext.color.white,
                                'fontSize': '16px',
                                'fontWeight': '300'
                            }}>{t('login.payoff-left-2')}</h6>
                            <h6 style={{
                                'color': themeContext.color.white,
                                'fontSize': '16px',
                                'fontWeight': '300'
                            }}>{t('login.payoff-left-3')}</h6>
                        </Col>
                    </Row>
                </div>
                <div className="col-md-6">
                    <div className="login d-flex align-items-center">
                        <div className="container">
                            <Row className="d-flex justify-content-center align-items-center">
                                {  errorPage && <ErrorPage/> }
                                {!errorPage && isLoadingData &&
                                    <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                                }
                                {!errorPage && !isLoadingData && isValid &&
                                    <Container style={{'backgroundColor': themeContext.color.white}}>
                                        <Row className="nav-top container"
                                             style={{
                                                 'backgroundColor': themeContext.color.white,
                                                 'paddingTop': '19.5px',
                                                 'paddingBottom': '20px',
                                                 'paddingLeft': '0',
                                                 'paddingRight': '0',
                                             }}>
                                            <Col className="col-2 float-left">
                                                <img src={back} onClick={turnBack}
                                                     className="nav-top-logo float-left cursor-pointer"
                                                     height="27px"
                                                     alt="back"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <img src={wideLogoLight} height="60px"
                                                     alt="wide-logo"/>
                                            </Col>
                                        </Row>
                                        <Row style={{'marginTop': '32px'}}>
                                            <Col>
                                                <h2 className="login-title align-center">{t('newOtp.title')}</h2>
                                                <h6 className="mt-4" style={{
                                                    'color': themeContext.color.neutral600,
                                                    'fontSize': '14px'
                                                }}>{t('newOtp.description-1')}</h6>
                                            </Col>
                                        </Row>
                                        <Row style={{'marginTop': '54px'}}>
                                            <Col>
                                                <PhoneInputWithCountry
                                                    disabled
                                                    label={t('newOtp.phone')}
                                                    value={phoneValue}
                                                    defaultValue={phoneValue}
                                                    //onChange={setPhoneValue}
                                                    defaultCountry={phoneLang.toUpperCase()}
                                                    name="phoneInput"
                                                    control={control}
                                                    rules={{required: true}}
                                                    smartCaret
                                                    limitMaxLength
                                                    inputComponent={MaterialPhoneNumber}
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{'marginTop': '25px'}}>
                                            <Col>
                                                <TextField
                                                    id="otpCode"
                                                    className="custom-textfield"
                                                    fullWidth
                                                    label={t('sendOtp.otp-code')}
                                                    variant="outlined"
                                                    name="otpCode"
                                                    type={"number"}
                                                    required={true}
                                                    value={formValues.otpCode}
                                                    onChange={handleInputChange}
                                                    color="primary"
                                                    InputProps={{inputProps: {min: 0}, style: {'borderRadius': '4px'}}}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col>
                                                <Link
                                                    className="float-left login-subtitle cursor-pointer"
                                                    color="primary" target="_new"
                                                    onClick={() => setOpenSupportDialog(true)} style={{
                                                    'color': themeContext.color.main
                                                }}>
                                                    {t('login.support-title')}
                                                </Link>
                                            </Col>
                                        </Row>
                                        <Row style={{'marginTop': '48px'}}>
                                            <Col>
                                                <Button className="btn-custom-2 text-uppercase"
                                                        onClick={acceptInvitation}
                                                        variant="outlined"
                                                        size="large" style={{'color': themeContext.color.white}}
                                                        type="submit">
                                                    {t('sendOtp.button')}
                                                </Button>
                                            </Col>
                                        </Row>
                                        {seconds !== 60 &&
                                            <Row className="mt-4">
                                                <Col>
                                                    {
                                                        disabled &&
                                                        <Link
                                                            className="login-subtitle cursor-pointer"
                                                            target="_new"
                                                            style={{'color': themeContext.color.neutral400}}>
                                                            {t('sendOtp.resend-disabled-1')} {seconds}{t('sendOtp.resend-disabled-2')}
                                                        </Link>
                                                    }
                                                    {
                                                        !disabled &&
                                                        <Link
                                                            className="login-subtitle cursor-pointer"
                                                            onClick={resendOtp}
                                                            target="_new"
                                                            style={{'color': themeContext.color.main}}>
                                                            {t('sendOtp.resend')}
                                                        </Link>
                                                    }
                                                </Col>
                                            </Row>
                                        }
                                    </Container>
                                }
                                {!errorPage && !isLoadingData && !isValid &&
                                    <Container>
                                        <Row style={{'marginTop': '36%'}}>
                                            <Col>
                                                <img src={wideLogoLight} height="60px"
                                                     alt="wide-logo"/>
                                            </Col>
                                        </Row>
                                        <Row style={{'marginTop': '32px'}}>
                                            <Col>
                                                <h6 className="login-title align-center">
                                                    {t('newPassword.isNotValid-title')}
                                                </h6>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                <h6 className="mt-2 align-center">
                                                    {t('newPassword.isNotValid-description')}
                                                </h6>
                                            </Col>
                                        </Row>
                                        <Row style={{
                                            'marginTop': '100px'
                                        }}>
                                            <Col>
                                                <Button className="btn-custom text-uppercase"
                                                        variant="outlined"
                                                        size="large"
                                                        type="submit"
                                                        style={{'color': themeContext.color.white, 'width': '265px'}}
                                                        onClick={navigateToLogin}>
                                                    {t('newPassword.isPassword-button')}
                                                </Button>
                                            </Col>

                                        </Row>
                                        <Row className="mt-4">
                                            <Col>
                                                <Link id="resend-otp"
                                                      className="float-left login-subtitle cursor-pointer"
                                                      color="primary" target="_new"
                                                      onClick={() => setOpenSupportDialog(true)}
                                                      style={{'color': themeContext.color.main}}>
                                                    {t('login.support-title')}
                                                </Link>
                                            </Col>
                                        </Row>
                                    </Container>
                                }
                                <DialogSmall open={passwordDialog}
                                             title={
                                                 <Fragment>
                                                     {passwordNotEqualError && t('newPassword.modal-text')}
                                                     {passwordCriteriasError && t('newPassword.modal-criterias-title')}
                                                 </Fragment>
                                             }
                                             description={passwordCriteriasError &&
                                                 <div>{t('newPassword.modal-criterias-description')}</div>}
                                             handleClose={handlePasswordDialog}
                                             buttonText={t('change-password.modal-button')}/>
                                <Dialog
                                    components={{Backdrop: StyledBackdrop}}
                                    open={openSupportDialog}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleCloseSupportDialog}
                                    aria-labelledby="alert-dialog-slide-title"
                                    aria-describedby="alert-dialog-slide-description"
                                    PaperProps={{
                                        style: {
                                            'position': 'absolute',
                                            'bottom': '0',
                                            'left': 'auto',
                                            'right': 'auto',
                                            'width': '95%',
                                            'borderRadius': '10px',
                                            'color': themeContext.color.neutral600
                                        },
                                    }}>
                                    <span className={classes.dialogTitle}>
                                        {t('login.support-title')}
                                    </span>
                                    <Divider/>
                                    <DialogActions>
                                        <MailTo email={process.env.REACT_APP_MY_WIDE_SUPPORT_EMAIL} subject={t('login.email-subject')}
                                                body={`${t('login.email-body-problem')}%0D%0A%0D%0A`}>
                                            <Button style={{'color': themeContext.color.main, 'textTransform': 'none'}} fullWidth>
                                                {t('login.support-contact-us')}
                                            </Button>
                                        </MailTo>
                                    </DialogActions>
                                    <Divider/>
                                    <DialogActions>
                                        <Button onClick={handleCloseSupportDialog}
                                                style={{'color': themeContext.color.neutral400, 'textTransform': 'none'}} fullWidth>
                                            {t('login.refuse-button')}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <DialogSmall open={openOTPDialog}
                                             title={t('newOtp.dialog-title')}
                                             description={t('newOtp.dialog-text')}
                                             handleClose={handleOTPClose}
                                             buttonText={t('newOtp.dialog-button')}/>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}