import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../images/back-icon.svg";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from 'react-i18next'
import {withSize} from "react-sizeme";
import {Button} from "@mui/material";
import documentPlaceholder from "../../images/document-placeholder.svg";
import selectedIcon from "../../images/selected-icon.svg";
import notSelectedIcon from "../../images/not-selected-icon.svg";
import exit from "../../images/exit-icon.svg";
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";
import spinner from "../../images/spinner.svg";
import {useMediaQuery} from "react-responsive";
import DialogDoubleAction from "../components/DialogDoubleAction.js";
import DialogSmall from "../components/DialogSmall.js";
import themeContext from "../components/ThemeContext.js";
import ErrorPage from "../pages/ErrorPage.js";

function PersonalDocumentsSelection({size}) {
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const {
        t,
        i18n
    } = useTranslation();
    const params = useParams();
    const documentGroupId = params.documentGroupId;
    const personId = params.personId;
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const documentId = query.get('documentId');
    const policyId = query.get('policyId');
    const titleId = query.get('titleId');
    const getGroupDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/personal/group`;
    const getGroupDocumentByPersonIdUrl = `${process.env.REACT_APP_BASE_URL}/media/personal/group/person`;
    const deleteDocumentFromGroupUrl = `${process.env.REACT_APP_BASE_URL}/media/personal/group/removeDocument`;
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [images, setImages] = useState([]);
    const [imagesUrl, setImagesUrl] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialog2, setOpenDialog2] = useState(false);
    const [indexToDelete, setIndexToDelete] = useState(-1);
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [openPhotoLimitsDialog, setOpenPhotoLimitsDialog] = useState(false);
    const [selectedImage, setSelectedImage] = useState(false);
    const [openNoImageSelectedDialog, setOpenNoImageSelectedDialog] = useState(false);
    const [openExitDialog, setOpenExitDialog] = useState(false);
    const authCtx = useContext(AuthContextMiddleware);
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'});
    const [openFallbackDialog, setOpenFallbackDialog] = useState(false);

    function reload() {
        window.location.reload();
    }

    useEffect(() => {
        if (navigator.onLine) {
            getDocumentGroupById();
            if (window.location.href.includes('refunds') || window.location.href.includes('bank-references')) {
                if (authCtx.bankRefsToUpdate.iban === '' && authCtx.bankRefsToUpdate.personId === '' && authCtx.bankRefsToUpdate.accountHolder === '') {
                    //console.log('dati bancari non presenti')
                    setOpenFallbackDialog(true)
                }
            }
        }
    }, []);

    const turnBack = () => {
        if (window.location.href.includes('signing')) {
            return navigate(`/signing/${personId}/upload/personalDocuments?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}`)
        } else if (window.location.href.includes('refunds')) {
            return navigate(`/refunds/${personId}/upload/personalDocuments`)
        } else if (window.location.href.includes('bank-references')) {
            return navigate(`/settings/people/${personId}/bank-references/upload/personalDocuments`)
        } else if (window.location.href.includes('invitation')) {
            return navigate(`/settings/people/${personId}/invitation/personalDocuments`)
        }
    }

    const getDocumentGroupById = () => {
        setIsLoadingData(true)
        setImages([])
        setImagesUrl([])
        fetch(`${getGroupDocumentUrl}/${documentGroupId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let lengthDocs = Object.keys(data.documents).length;
            setDocuments(data)
            data.documents.map((doc, index) => {
                if (doc.isFavorite === true) {
                    setSelectedImage(index)
                    authCtx.storeReqImage(doc.document)
                    authCtx.storeReqImageBase64(doc.base64)
                }
            })
            if (lengthDocs !== 0) {
                for (let i = 0; i < lengthDocs; i++) {
                    setImages(current => [...current, data.documents[i]])
                    setImagesUrl(current => [...current, data.documents[i].document])
                }
            }
            setIsLoadingData(false)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const handleDeleteGroup = () => {
        fetch(`${getGroupDocumentByPersonIdUrl}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: documentGroupId,
            }),
        }).then((response) => {
            return response.json();
        }).then((data) => {
            navigate(-1)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const handleDeleteImage = (index) => {
        fetch(`${deleteDocumentFromGroupUrl}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                groupId: documentGroupId,
                documentIndex: index
            }),
        }).then((response) => {
            return response.json();
        }).then((data) => {
            getDocumentGroupById();
            setOpenDialog2(false)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const handleImageSelection = (index, url, base64) => {
        if (index === selectedImage) {
            setSelectedImage(false);
        } else {
            setSelectedImage(index);
            authCtx.storeReqImage(url)
            authCtx.storeReqImageBase64(base64)
        }
    }

    const navigateToCameraRecognition = () => {
        if (window.location.href.includes('signing')) {
            return navigate(`/signing/${personId}/upload/personalDocuments/${documentGroupId}/identify?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}`)
        } else if (window.location.href.includes('refunds')) {
            return navigate(`/refunds/${personId}/upload/personalDocuments/${documentGroupId}/identify`)
        } else if (window.location.href.includes('bank-references')) {
            return navigate(`/settings/people/${personId}/bank-references/upload/personalDocuments/${documentGroupId}/identify`)
        } else if (window.location.href.includes('invitation')) {
            return navigate(`/settings/people/${personId}/invitation/personalDocuments/${documentGroupId}/identify`)
        }
    }

    return (
        errorPage ?
            <ErrorPage/> :
            <Fragment>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                    <Row className="nav-top container col-list" style={{
                        'backgroundColor': themeContext.color.white,
                        'paddingTop': '19.5px',
                        'paddingBottom': '20px',
                        'paddingLeft': '0',
                        'paddingRight': '0',
                    }}>
                        <Col className="col-1 float-left">
                            <img src={back} onClick={turnBack} className="nav-top-logo float-left cursor-pointer"
                                 height="27px"
                                 alt="back"/>
                        </Col>
                        <Col className="col-10">
                            <h6 className="header-title ellipsis-1-v2">
                                {isLoadingData &&
                                    <img src={spinner} className="spinner-img" alt="spinner"/>
                                }
                                {
                                    !isLoadingData && documents.type === "1" &&
                                    <span>{t('documentsList.title-document-1')}</span>
                                }
                                {
                                    !isLoadingData && documents.type === "2" &&
                                    <span>{t('documentsList.title-document-2')}</span>
                                }
                                {
                                    !isLoadingData && documents.type === "3" &&
                                    <span>{t('documentsList.title-document-3')}</span>
                                }
                                {
                                    !isLoadingData && documents.type === "4" &&
                                    <span>{t('documentsList.title-document-4')}</span>
                                }
                                {
                                    !isLoadingData && documents.type === "5" &&
                                    <span>{t('documentsList.title-document-5')}</span>
                                }
                            </h6>
                        </Col>
                        <Col className="col-1">
                            <img src={exit} onClick={() => setOpenExitDialog(true)}
                                 className="nav-top-logo float-right cursor-pointer" alt="exit-icon" height="27px"/>
                        </Col>
                    </Row>
                    <Container className="top-of-the-preferences"></Container>
                    {
                        isLoadingData &&
                        <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                    }
                    {
                        !isLoadingData && images.length === 0 &&
                        <Container>
                            <Row style={{
                                'marginTop': '43.5px',
                                'marginBottom': '44.5px'
                            }}>
                                <Col className="pl-5 pr-5">
                        <span style={{
                            'fontSize': '18px',
                            'color': themeContext.color.mainActive,
                            'fontWeight': 'bold'
                        }}>{t('personalDocuments.placeholder-text')}</span>
                                </Col>
                            </Row>
                            <Row>
                                <img src={documentPlaceholder} alt="document-placeholder" width="100%"
                                     style={{'maxHeight': '500px'}}/>
                            </Row>
                        </Container>
                    }
                    {
                        !isLoadingData &&
                        <Fragment>
                            <div className="mt-4 flex-wrap justify-content-center" style={{
                                display: "flex",
                                paddingLeft: '10%',
                                paddingRight: '10%'
                            }}>
                                <h6 className={'darkgrey'} style={{
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                }}>
                                    {t('personalDocumentsSelection.description')}
                                </h6>
                            </div>
                            <div className="mt-4 flex-wrap justify-content-between" style={{
                                display: "flex",
                                gap: "24px",
                                paddingBottom: "120px"
                            }}>
                                {
                                    images.map((item, index) => (
                                        <div key={index}
                                             onClick={() => handleImageSelection(index, item.document, item.base64)}
                                             className={(isDesktop || documents.photosNr > 2) ? "div-grid-full-desktop" : "div-grid-full-mobile"}>
                                            <img src={item.document} className={'image-grid'}
                                                 alt={`image-${index}`}/>
                                            <button
                                                className={"selected-button"}>
                                                <img src={selectedImage !== index ? notSelectedIcon : selectedIcon}
                                                     alt="selected-icon" height={'50px'}/>
                                            </button>
                                        </div>
                                    ))
                                }
                            </div>
                        </Fragment>
                    }
                </Container>

                {
                    !isLoadingData && images.length !== 0 &&
                    <Container style={{
                        'background': themeContext.color.white,
                        'paddingBottom': "80px"
                    }}></Container>
                }
                <Container className="fixed-bottom"
                           style={{'paddingBottom': "40px"}}>
                    <Row>
                        <Col>
                            <Button id="attachImage"
                                    className="btn-custom text-uppercase"
                                    variant="outlined"
                                    size="large"
                                    style={{'color': themeContext.color.white}} type="submit"
                                    onClick={() => {
                                        if (selectedImage !== false) {
                                            navigateToCameraRecognition();
                                        } else {
                                            setOpenNoImageSelectedDialog(true)
                                        }
                                    }}
                            >{t('personalDocumentsSelection.button')}</Button>
                        </Col>
                    </Row>
                </Container>
                <DialogDoubleAction open={openDialog}
                                    title={t('documentsList.modal-title')}
                                    description={t('documentsList.modal-description')}
                                    handleClose={() => setOpenDialog(false)}
                                    handleNo={() => setOpenDialog(false)}
                                    handleYes={handleDeleteGroup}
                                    buttonYesText={t('documentsList.modal-confirm-button')}
                                    buttonNoText={t('documentsList.modal-refuse-button')}/>

                <DialogSmall open={openPhotoLimitsDialog}
                             title={t('documentsList.limit-photo-modal-title')}
                             description={t('documentsList.limit-photo-modal-description')}
                             handleClose={() => setOpenPhotoLimitsDialog(false)}
                             buttonText={t('documentsList.limit-photo-modal-button')}/>

                <DialogDoubleAction open={openDialog2}
                                    title={t('documentsList.modal-title-2')}
                                    description={t('documentsList.modal-description-2')}
                                    handleClose={() => setOpenDialog2(false)} handleNo={() => setOpenDialog2(false)}
                                    handleYes={() => handleDeleteImage(indexToDelete)}
                                    buttonYesText={t('documentsList.modal-confirm-button')}
                                    buttonNoText={t('documentsList.modal-refuse-button')}/>
                <DialogSmall open={openNoImageSelectedDialog}
                             title={t('personalDocumentsSelection.no-selection-title')}
                             description={t('personalDocumentsSelection.no-selection-description')}
                             handleClose={() => setOpenNoImageSelectedDialog(false)}
                             buttonText={t('personalDocumentsSelection.no-selection-button')}/>
                <DialogDoubleAction
                    open={openExitDialog}
                    handleClose={() => setOpenExitDialog(false)}
                    title={t('dialog.interruption-title')}
                    description={t('dialog.interruption-description')}
                    buttonYesText={t('dialog.button-yes')}
                    handleYes={() => {
                        if (window.location.href.includes('signing')) {
                            navigate('/signing');
                        } else if (window.location.href.includes('refunds')) {
                            navigate(`/refunds`);
                        } else if (window.location.href.includes('invitation')) {
                            navigate(`/settings/people/${personId}`);
                        } else if (window.location.href.includes('bank-references')) {
                            navigate(`/settings/people/${personId}/bank-references`);
                        }
                    }}
                    buttonNoText={t('dialog.button-no')}
                    handleNo={() => setOpenExitDialog(false)}
                ></DialogDoubleAction>
                <DialogSmall buttonText={t('uploadDocumentToVerify.fallback-dialog-button')}
                             title={t('uploadDocumentToVerify.fallback-dialog-title')}
                             description={t('uploadDocumentToVerify.fallback-dialog-description')}
                             handleClose={() => {
                                 if (window.location.href.includes('refunds')) {
                                     navigate(`/refunds`);
                                 } else if (window.location.href.includes('bank-references')) {
                                     navigate(`/settings/people/${personId}/bank-references`);
                                 }
                             }}
                             open={openFallbackDialog}/>
            </Fragment>
    )
}

export default withSize()(PersonalDocumentsSelection)