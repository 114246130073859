import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {Col, Form, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {fetchToken} from "../middleware/Firebase.js";
import {Button, Dialog, InputAdornment, ListItemButton, Slide, TextField} from "@mui/material";
import {isIpad, isMac, isStandalone, putElementFromIndexedDB} from "../service/UtilityService.js";
import BellOff from '../../images/bell-icon.svg'
import BellOn from '../../images/bellOn-icon.svg'
import wideLogoLight from '../../images/wideLogo-icon.svg';
import {ClientJS} from 'clientjs';
import {makeStyles} from '@mui/styles';
import Card from '@mui/material/Card';
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Avatar from "@mui/material/Avatar";
import baseMaleAvatar from "../../images/male-icon.svg";
import baseFemaleAvatar from "../../images/female-icon.svg";
import euroIcon from '../../images/euro-icon.svg';
import pdfIcon from "../../images/file-png-icon.png";
import addIcon from "../../images/add.svg";
import vectorIcon from "../../images/policy_branches/vector.svg";
import artCollectionIcon from "../../images/policy_branches/art-collections-icon.svg";
import autoCvtIcon from "../../images/policy_branches/auto-cvt-icon.svg";
import aviationIcon from "../../images/policy_branches/aviation-icon.svg";
import buildingsIcon from "../../images/policy_branches/buildings-icon.svg";
import cablewayIcon from "../../images/policy_branches/cableway-icon.svg";
import certifiedTransportationIcon from "../../images/policy_branches/certified-transportation-icon.svg";
import civiliansIcon from "../../images/policy_branches/civilians-icon.svg";
import collectiveLivesIcon from "../../images/policy_branches/collective-lives-icon.svg";
import companiesIcon from "../../images/policy_branches/companies-icon.svg";
import depositsIcon from "../../images/policy_branches/deposits-icon.svg";
import electronicIcon from "../../images/policy_branches/electronic-icon.svg";
import energyIcon from "../../images/policy_branches/energy-icon.svg";
import farmingIcon from "../../images/policy_branches/farming-icon.svg";
import horecaIcon from "../../images/policy_branches/horeca-icon.svg";
import illnessIcon from "../../images/policy_branches/illness-icon.svg";
import individualLivesIcon from "../../images/policy_branches/individual-lives-icon.svg";
import injuresIcon from "../../images/policy_branches/injures-icon.svg";
import jewellersIcon from "../../images/policy_branches/jewellers-icon.svg";
import legalProtectionIcon from "../../images/policy_branches/legal-protection-icon.svg";
import officeLaboratoriesIcon from "../../images/policy_branches/office-laboratories-icon.svg";
import petInsuranceIcon from "../../images/policy_branches/pet-insurance-icon.svg";
import rcAdminIcon from "../../images/policy_branches/rc-admin-icon.svg";
import rcClinicalTrialsIcon from "../../images/policy_branches/rc-clinical-trials-icon.svg";
import rcIndividuals from "../../images/policy_branches/rc-individuals-icon.svg";
import rcMedicalMalpracticeIcon from "../../images/policy_branches/rc-medical-malpractice-icon.svg";
import rcPollutionIcon from "../../images/policy_branches/rc-pollution-icon.svg";
import rcProfessionalIcon from "../../images/policy_branches/rc-professional-icon.svg";
import shipIcon from "../../images/policy_branches/ship-icon.svg";
import technologiesIcon from "../../images/policy_branches/technologies-icon.svg";
import theftIcon from "../../images/policy_branches/theft-icon.svg";
import thirdSectorIcon from "../../images/policy_branches/third-sector-icon.svg";
import tourOperatorIcon from "../../images/policy_branches/tour-operator-icon.svg";
import tradeCreditsIcon from "../../images/policy_branches/trade-credits-icon.svg";
import travelBusinnessLineIcon from "../../images/policy_branches/travel-businnes-line-icon.svg";
import wheaterIcon from "../../images/policy_branches/wheater-icon.svg";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import exitIcon from "../../images/exit-icon.svg";
import {useMediaQuery} from "react-responsive";
import {Carousel} from "react-responsive-carousel";
import Badge from '@mui/material/Badge';
import Moment from "react-moment";
import CardContent from "@mui/material/CardContent";
import {useForm} from "react-hook-form";
import nothing from "../../images/nothing-icon.svg";
import CurrencyFormat from 'react-currency-format';
import Header from "../components/Header.js";
import DialogDoubleAction from "../components/DialogDoubleAction.js";
import baseCompanyAvatar from "../../images/company-avatar.svg";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";
import ErrorPage from "../pages/ErrorPage.js";
import {NavbarContext} from "../middleware/NavbarContext.js";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100% !important',
    },
    card: {
        margin: "0 auto !important",
        marginBottom: "18px !important",
        background: `${themeContext.color.white} 0% 0% no-repeat padding-box !important`,
        boxShadow: `2px 3px 10px ${themeContext.color.boxShadow} !important`,
        borderRadius: "10px !important",
        opacity: "1 !important",
        minHeight: "140px !important"
    },
    cardTextMid: {
        color: `${themeContext.color.neutral600} !important`,
        textAlign: 'left !important',
        lineHeight: '1 !important',
        fontWeight: '400 !important',
        fontSize: '12px !important',
        paddingTop: '10px !important',
        paddingBottom: '7px !important',
    },
    personAvatar: {
        width: "30px !important",
        height: "30px !important",
    },
    listItem: {
        width: "100% !important",
        fontSize: "14px !important",
        color: `${themeContext.color.neutral400} !important`,
        paddingTop: '18px !important',
        paddingBottom: '18px !important',
    },
    subtitle: {
        textAlign: "left !important",
        fontSize: "18px !important",
        fontStyle: "normal !important",
        lineHeight: "normal !important",
        fontWeight: "700 !important",
        color: `${themeContext.color.neutral400} !important`,
    },
    CTAButton: {
        fontWeight: '500 !important',
        fontSize: '14px !important',
        fontStyle: 'normal !important',
        lineHeight: 'normal !important',
        color: `${themeContext.color.mainActive} !important`,
        textTransform: 'uppercase !important',
        float: 'left !important',
        marginBottom: '8px !important'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Regulations() {
    let defaultValues = {};
    const checkReadUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/checkRead`;
    const getRegulationsUrl = `${process.env.REACT_APP_BASE_URL}/payments/regulations/getAllRegulations`;
    const getRegulationDetailsUrl = `${process.env.REACT_APP_BASE_URL}/payments/regulations/getRegulationDetailById`;
    const uploadRegulationDetailUrl = `${process.env.REACT_APP_BASE_URL}/payments/regulations/uploadRegulation`;
    const userInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/info`;
    const token = sessionStorage.getItem('token');
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const navigate = useNavigate();
    const hiddenFileInput = useRef(null);
    const [openNestedNotSigned, setOpenNestedNotSigned] = useState(0);
    const [images, setImages] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [file, setFile] = useState(null);
    const formValues = useRef(defaultValues);
    const {sendControl, handleSubmit: handleSendSubmit} = useForm();
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'})
    const {t, i18n} = useTranslation();
    const {toggleNavbar} = useContext(NavbarContext);


    useEffect(() => {
        toggleNavbar(true)
        sessionStorage.setItem('root', '/signing');
        if (navigator.onLine) {
            if (sessionStorage.getItem("notificationId") != null) {
                const url = sessionStorage.getItem("notificationId").replaceAll('"', '')
                navigateToMessageDetails(url);
            }
            getInfo();
            handleRinging();
            getRegulations();
            setImages([addIcon])
        } else {
            //
        }
    }, []);

    const client = new ClientJS();
    const [bellRinging, setBellRinging] = useState(false);
    const classes = useStyles();
    let [people, setPeople] = useState([]);
    let [regulationDetails, setRegulationDetails] = useState([]);
    const [getPermission, setGetPermission] = useState(false);
    const [selectedRegulationId, setSelectedRegulationId] = useState(null);
    const [selectedDateEnd, setSelectedDateEnd] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedRegulationType, setSelectedRegulationType] = useState(null);
    const [selectedCompetenceDateStart, setSelectedCompetenceDateStart] = useState(null);
    const [selectedCompetenceDateEnd, setSelectedCompetenceDateEnd] = useState(null);
    const [selectedFinalDays, setSelectedFinalDays] = useState(null);
    const [selectedPaymentDays, setSelectedPaymentDays] = useState(null);
    const [selectedParameterId, setSelectedParameterId] = useState(null);
    const [selectedParameter, setSelectedParameter] = useState(null);
    const [selectedPolicyBranch, setSelectedPolicyBranch] = useState(null);
    const [isDocumentPresent, setIsDocumentPresent] = useState(false);
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [openUploadConfirmDialog, setOpenUploadConfirmDialog] = useState(false);
    const [noRegulations, setNoRegulations] = useState(false);
    const [user, setUser] = useState("");
    const [contentTypeError, setContentTypeError] = useState(false);

    if (((client.isMobileIOS() || isIpad()) && client.getOSVersion() < "16.4") || (isMac() && client.getOSVersion() < "13") || ((client.isMobileIOS() || isIpad()) && !(client.getOSVersion() < "16.4") && !isStandalone())) {
        console.log('notification not allowed')
    } else if (!getPermission) {
        requestPermission();
        setGetPermission(true);
    }
    fetchToken();

    function requestPermission() {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
            }
        })
    }

    if (((client.isMobileIOS() || isIpad()) && client.getOSVersion() < "16.4") || (isMac() && client.getOSVersion() < "13") || ((client.isMobileIOS() || isIpad()) && !(client.getOSVersion() < "16.4") && !isStandalone())) {
        console.log('notification not allowed')
    } else {
        const channel = new BroadcastChannel('sw-messages');
        channel.onmessage = (event) => {
            setBellRinging(true)
        };
        channel.onmessageerror = (event) => {
        };
    }

    const navigateToMessageDetails = (url) => {
        return navigate(`/messages/principal/${url}`);
    }

    function getInfo() {
        fetch(userInfoUrl, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            i18n.changeLanguage(data.preferredLanguage).then(r => {
            })
            if (data.status !== -1) {
                setUser(data)
                localStorage.setItem('wideAPP_userId', data.id)
                putElementFromIndexedDB('profileInfo', data)
            }
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const handleRinging = () => {
        fetch(checkReadUrl, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json',},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.toRead) {
                setBellRinging(true);
            } else {
                setBellRinging(false);
            }
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const getRegulations = () => {
        setIsLoadingData(true)
        fetch(getRegulationsUrl, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length === 0) {
                setNoRegulations(true)
            } else {
                let finder = false;
                data = data.map((person) => {
                    person.regulations = person.regulations.map((regulation) => {
                        finder = true
                        let masked = false
                        regulation.details.map((detail, index) => {
                            if (detail.filledInAt !== null) {
                                switch (detail.regulationType) {
                                    case 'money':
                                        if (detail.valueMoneyFinal !== null) {
                                            masked = true;
                                        }
                                        break;
                                    case 'int':
                                        if (detail.valueIntFinal !== null) {
                                            masked = true;
                                        }
                                        break;
                                    case 'doc':
                                        if (detail.docs !== 0) {
                                            masked = true;
                                        }
                                        break;
                                    case 'money, doc':
                                        if (detail.docs !== 0 || detail.valueMoneyFinal !== null) {
                                            masked = true;
                                        }
                                        break;
                                    case 'int, doc':
                                        if (detail.docs !== 0 || detail.valueIntFinal !== null) {
                                            masked = true;
                                        }
                                        break;
                                }
                            }
                            if (index + 1 === regulation.details.length) {
                                if (masked === true) {
                                    regulation['masked'] = true;
                                } else {
                                    regulation['masked'] = false;
                                }
                            }
                        });
                        return regulation;
                    }).filter(e => e.policyStatus !== 5);
                    if (person.regulations.length > 0) {
                        return person;
                    }
                }).filter(e => e)
                if (data.length === 0) {
                    setNoRegulations(true)
                }
                setPeople(data)
                setIsLoadingData(false)
            }
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const getRegulationDetailById = (regulationId) => {
        setIsLoadingData(true)
        fetch(`${getRegulationDetailsUrl}/${regulationId}`, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setSelectedRegulationType(data.regulationType)
            setSelectedCompetenceDateStart(data.dateStart)
            setSelectedCompetenceDateEnd(data.dateEnd)
            setSelectedFinalDays(data.daysFinal)
            setSelectedPaymentDays(data.daysPayment)
            let formattedDetails = data.details.map(detail => {
                let result = detail;
                if (detail.valueMoneyFinal) {
                    result = {...detail, valueMoneyFinal: detail.valueMoneyFinal / 100}
                }
                return result;
            })
            setRegulationDetails(formattedDetails)
            setIsDocumentPresent(false)
            data.details.forEach((element) => {
                if (element.thumbUrl !== "ND") {
                    setIsDocumentPresent(true)
                }
            })
            setIsLoadingData(false)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const submitForm = () => {
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        formValues.current = {...formValues.current, [name]: value}
    };

    const uploadRegulationDetail = (regulationDetails) => {
        setIsLoadingData(true)
        regulationDetails.forEach((item) => {
                for (const [key, value] of Object.entries(formValues.current)) {
                    if (item.detailType === 'money') {
                        if (key === item.detailId + item.parameter) {
                            item.valueMoneyFinal = value;
                        }
                    }
                    if (item.detailType === 'int') {
                        if (key === item.detailId + item.parameter) {
                            item.valueIntFinal = value;
                        }
                    }
                    if (item.detailType === 'doc') {
                        let docFinded = documents.find((element) => element.parameter === item.detailId + item.parameter)
                        if (key === item.detailId + item.parameter) {
                            if (docFinded) {
                                item.doc = docFinded.doc.split(',')[1];
                                item.filename = docFinded.filename;
                                item.type = docFinded.type
                            } else {
                                item.doc = null
                                item.filename = null;
                                item.type = null;
                            }
                        }
                    }
                    if (item.detailType === 'money, doc') {
                        let docFinded = documents.find((element) => element.parameter === item.detailId + item.parameter)
                        if (key === item.detailId + item.parameter) {
                            if (value !== 'doc') {
                                item.valueMoneyFinal = value;
                            }
                            if (docFinded) {
                                item.doc = docFinded.doc.split(',')[1];
                                item.filename = docFinded.filename;
                                item.type = docFinded.type
                            } else {
                                item.doc = null
                                item.filename = null;
                                item.type = null;
                            }
                        }
                    }
                    if (item.detailType === 'int, doc') {
                        let docFinded = documents.find((element) => element.parameter === item.detailId + item.parameter)
                        if (key === item.detailId + item.parameter) {
                            if (value !== 'doc') {
                                item.valueIntFinal = value;
                            }
                            if (docFinded) {
                                item.doc = docFinded.doc.split(',')[1];
                                item.filename = docFinded.filename;
                                item.type = docFinded.type
                            } else {
                                item.doc = null
                                item.filename = null;
                                item.type = null;
                            }
                        }
                    }
                }
                fetch(uploadRegulationDetailUrl, {
                    method: 'POST',
                    headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        regulationDetailId: item.detailId,
                        valueIntAdvanced: item.valueIntAdvanced,
                        valueMoneyAdvanced: item.valueMoneyAdvanced,
                        valueIntFinal: item.valueIntFinal,
                        valueMoneyFinal: item.valueMoneyFinal * 100,
                        rate: item.rate,
                        note: item.note,
                        doc: item.doc,
                        filename: item.filename,
                        contentType: item.type
                    })
                }).then((response) => {
                    return response.json();
                }).then(() => {
                    setIsLoadingData(false)
                    getRegulations();
                }).catch(() => {
                    setErrorPage(true)
                });
            }
        )
    }

    function reload() {
        window.location.reload();
    }

    const handleClick = (index) => {
        if (openNestedNotSigned === index) {
            setOpenNestedNotSigned(-1)
        } else {
            setOpenNestedNotSigned(index)
        }
    }

    const getBranchIcon = (policyType) => {
        switch (policyType) {
            case "TUTELA LEGALE":
                return legalProtectionIcon;
            case "AGRICOLTURA":
                return farmingIcon;
            case "AUTO (RCA - CVT)":
            case "AUTO (CVT)":
            case "CVT AUTO D'EPOCA E SPORTIVE":
            case "CVT SOCCORSO":
            case "KASKO CHILOMETRICA":
                return autoCvtIcon;
            case "PET INSURANCE":
                return petInsuranceIcon;
            case "CAUZIONI":
                return depositsIcon;
            case "CREDITI COMMERCIALI":
                return tradeCreditsIcon;
            case "ELETTRONICA":
                return electronicIcon;
            case "ENERGY":
                return energyIcon;
            case "RC AMMINISTRATORI (D&O)":
            case "RC COLPA GRAVE":
                return rcAdminIcon;
            case "FINE ART E COLLEZIONI":
                return artCollectionIcon;
            case "FURTO":
            case "FURTO PRIVATI":
                return theftIcon;
            case "GLOBALE GIOIELLERIE":
                return jewellersIcon;
            case "GLOBALE FABBRICATI":
                return buildingsIcon;
            case "INFORTUNI":
            case "INFORTUNI COLLETTIVA":
            case "INFORTUNI CONDUCENTI":
            case "INFORTUNI E MALATTIA":
            case "INFORTUNI TERZO SETTORE":
            case "PRESTAZIONI DI ASSISTENZA SANITARIA":
                return injuresIcon;
            case "MALATTIA":
                return illnessIcon;
            case "METEO":
                return wheaterIcon;
            case "VIAGGI LINEA BUSINESS":
            case "VIAGGI LINEA PERSONA":
                return travelBusinnessLineIcon;
            case "MULTIRISCHI AZIENDE":
            case "PROPERTY AZIENDE":
            case "RC AZIENDE":
                return companiesIcon;
            case "MULTIRISCHI HO.RE.CA.":
                return horecaIcon;
            case "MULTIRISCHI TERZO SETTORE":
            case "RC TERZO SETTORE":
                return thirdSectorIcon;
            case "MULTIRISCHI CIVILI":
            case "PROPERTY RISCHI CIVILI":
                return civiliansIcon;
            case "MULTIRISCHI UFFICI/LABORATORI":
                return officeLaboratoriesIcon;
            case "RC INQUINAMENTO":
                return rcPollutionIcon;
            case "RC MEDICAL MALPRACTICE":
                return rcMedicalMalpracticeIcon;
            case "RC PRIVATI":
                return rcIndividuals;
            case "RC PROFESSIONALE":
                return rcProfessionalIcon;
            case "RC SPERIMENTAZIONE CLINICA":
                return certifiedTransportationIcon;
            case "TECNOLOGICI":
                return technologiesIcon;
            case "AGENZIE VIAGGI":
            case "TOUR OPERATOR / AGENZIA VIAGGI":
                return tourOperatorIcon;
            case "TRASPORTI CERTIFICATI IN ABBONAMENTO":
            case "TRASPORTO MERCI":
            case "STOCK & TRANSIT":
            case "SPEDIZIONIERI E VETTORI":
                return rcClinicalTrialsIcon;
            case "CORPI":
            case "RC E CORPI NATANTI":
            case "P&I PROTECTION & INDEMNITY":
                return shipIcon;
            case "VITA COLLETTIVE":
            case "RC PATRIMONIALE COLPA LIEVE":
                return collectiveLivesIcon;
            case "VITA INDIVIDUALI":
                return individualLivesIcon;
            case "AVIAZIONE":
                return aviationIcon;
            case "PERDITE PECUNIARIE":
                return depositsIcon;
            case "CYBER RISK":
                //icona mancante
                return theftIcon;
            case "MULTIRISCHI IMPIANTI A FUNE":
                return cablewayIcon;
            default:
                return vectorIcon;
        }
    }

    const setStatusColor = (policyStatus) => {
        switch (policyStatus) {
            case 1:
                return themeContext.color.neutral200;
            case 2:
                return themeContext.color.green;
            case 3:
                return themeContext.color.yellow;
            case 4:
                return themeContext.color.red;
        }
    }

    const handleClickFile = event => {
        hiddenFileInput.current.click();
    };

    const handleChange = (event, parameter) => {
        const fileUploaded = event.target.files[0];
        if (event.target.files[0]) {
            let name = event.target.files[0].name
            let type = event.target.files[0].type
            if (type !== undefined && (type === 'application/pdf' || type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg')) {
                setContentTypeError(false)
                getBase64(fileUploaded).then(res => {
                    setFile(res)
                    setImages(current => [...current, pdfIcon])
                    let doc = {
                        doc: res,
                        filename: name,
                        parameter: parameter,
                        type: type
                    }
                    setDocuments(current => [...current, doc])
                    formValues.current[parameter] = 'doc'
                });
                handleFile(fileUploaded);
            } else {
                setContentTypeError(true)
            }
        }
    }

    const handleFile = (file) => {
        const formData = new FormData();
        formData.append('file', file);
    }

    async function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = reject
        })
    }

    const renderCustomThumbs = () => {
        const thumbList = images.map((item, index) => (
            index === 0 ?
                <div key={index}
                     className={index === 0 && images.length !== 1 ? 'add-icon' : 'add-active-icon add-icon'}
                     onClick={() => {
                         if (images.length === 1) {
                             handleClickFile()
                         }
                     }}>
                    <img
                        key={index}
                        src={index === 0 ? addIcon : pdfIcon}
                        alt="First Thumbnail"
                    />
                </div>
                :
                <div key={index}>
                    <Badge color="secondary" badgeContent={"X"} className="upload-badge"
                           style={{display: index === 0 ? 'none' : 'transparent'}}
                           onClick={(event) => {
                               let index = documents.findIndex((item) => item.parameter === selectedParameterId)
                               documents.splice(index, 1)
                               images.splice(1, 1)
                               delete formValues.current[selectedParameterId]
                           }}>
                    </Badge>
                    <div onClick={(event) => {
                        let docFinded = documents.find((item) => item.parameter === selectedParameterId)
                        let link = document.createElement("a");
                        link.setAttribute('download', '');
                        link.href = docFinded.doc;
                        document.body.appendChild(link);
                        link.click();
                    }}>
                        <img style={{
                            width: '94px',
                            marginTop: '5px'
                        }}
                             key={index}
                             src={index === 0 ? addIcon : pdfIcon}
                             alt="First Thumbnail"
                        />
                    </div>
                </div>
        ))
        return thumbList;
    }

    return (errorPage ? <ErrorPage/> :
        <Fragment>
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                <Row className="nav-top container" style={{
                    paddingLeft: '0',
                    paddingRight: '0',
                }}>
                    <Col>
                        <Header iconSrc={wideLogoLight}
                                rightSrc={bellRinging ? BellOn : BellOff}
                                rightAction={() => navigate('/messages')}/>
                    </Col>
                </Row>
                <Container className="top-of-the-language"></Container>
                <Row className="mt-2" style={{'backgroundColor': themeContext.color.neutral10}}>
                    <Col className="col-list">
                        {isLoadingData &&
                            <div style={{'height': '85vh'}} className="background-loading-spinner"></div>
                        }
                    </Col>
                    {!isLoadingData && noRegulations &&
                        <Container style={{
                            margin: '10vh auto',
                            backgroundColor: themeContext.color.neutral10
                        }}>
                            <Row>
                                <Col>
                                    <img src={nothing} alt={t('signing.nothing-text-1')}></img>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <span className="no-messages-text">{t('regulations.nothing-text-1')}</span>
                                </Col>
                            </Row>
                        </Container>
                    }
                    {!isLoadingData && !noRegulations &&
                        <Fragment>
                            <List
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                className={classes.root}>
                                {people.map((value, index) => (
                                    <Fragment key={index}>
                                        {index === 0 && <Container>
                                            <Row className="float-left mt-2 mb-2">
                                                <Col>
                                                    <h6 className={classes.subtitle}>{t('regulations.title')}</h6>
                                                </Col>
                                            </Row>
                                        </Container>}
                                        <ListItemButton onClick={() => handleClick(index)}
                                                  className={classes.listItem}>
                                            <ListItemIcon>
                                                {value.image === null && value.gender === 'M' &&
                                                    <Avatar className={classes.personAvatar}
                                                            src={baseMaleAvatar}
                                                            alt="avatar-image"/>}
                                                {value.image === null && value.gender === 'F' &&
                                                    <Avatar className={classes.personAvatar}
                                                            src={baseFemaleAvatar}
                                                            alt="avatar-image"/>}
                                                {value.image === null && value.gender === 'C' &&
                                                    <Avatar className={classes.personAvatar}
                                                            src={baseCompanyAvatar}
                                                            alt="avatar-image"/>}
                                                {value.image === null && value.gender === null &&
                                                    <Avatar className={classes.personAvatar}
                                                            src={baseMaleAvatar}
                                                            alt="avatar-image"/>}
                                                {value.image &&
                                                    <Avatar className={classes.personAvatar} src={value.image}
                                                            alt="avatar-image"/>}
                                            </ListItemIcon>
                                            {((value.firstName === null || value.firstName === "") && (value.lastName === null || value.lastName === "")) ?
                                                <ListItemText className="list-label"
                                                              secondary={value.name.toLowerCase()}
                                                              style={{
                                                                  textTransform: 'capitalize',
                                                                  color: themeContext.color.neutral600
                                                              }}/> :
                                                <ListItemText className="list-label"
                                                              secondary={`${value.firstName.toLowerCase()} ${value.lastName.toLowerCase()}`}
                                                              style={{
                                                                  textTransform: 'capitalize',
                                                                  color: themeContext.color.neutral600
                                                              }}/>}
                                            <span style={{
                                                textAlign: 'right',
                                                fontSize: '12px',
                                                fontWeight: '700'
                                            }}></span>
                                            {openNestedNotSigned === index ? <ExpandMore/> : <ExpandLess/>}
                                        </ListItemButton>
                                        <Collapse in={openNestedNotSigned === index} timeout="auto" unmountOnExit>
                                            <Container>
                                                <Row>
                                                    <List component="div" className="col div-under-list">
                                                        {value.regulations.map((regulation) => {
                                                            return (
                                                                <Fragment key={regulation.id}>
                                                                    <Card className={classes.card} key={regulation.id}
                                                                          style={{'borderRight': `12px solid ${setStatusColor(regulation.policyStatus)}`}}>
                                                                        <CardContent style={{'paddingBottom': '12px'}}>
                                                                            <Row>
                                                                                <Col className="col-auto mr-0"
                                                                                     style={{'paddingRight': '0px'}}>
                                                                                    <img
                                                                                        src={getBranchIcon(regulation.branchName)}
                                                                                        alt="type-policy-icon"/>
                                                                                </Col>
                                                                                <Col className="col-7">
                                                                                    <h6 className={classes.cardTextMid}>{regulation.branchName}</h6>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col>
                                                                                    <h6 className={"card-text-content ellipsis-1-v2"}>
                                                                                        {t('dashboard.expiration-label')}
                                                                                        <span
                                                                                            className="font-weight-bold">
                                                                                        <Moment locale="it"
                                                                                                format="DD/MM/YYYY"
                                                                                                className="black">
                                                                                            {regulation.dateEnd}
                                                                                        </Moment>
                                                                                    </span>
                                                                                    </h6>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col>
                                                                                    <h6 className={"card-text-content ellipsis-1-v2"}>
                                                                                        {t('dashboard.company-label')}
                                                                                        <span
                                                                                            className="font-weight-bold black">
                                                                                        {regulation.companyName}
                                                                                    </span>
                                                                                    </h6>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col>
                                                                                    <h6 className={"card-text-content ellipsis-1-v2"}>
                                                                                        {t('dashboard.policy-number-label')}
                                                                                        <span
                                                                                            className="font-weight-bold black">
                                                                                        {regulation.policyId}
                                                                                    </span>
                                                                                    </h6>
                                                                                </Col>
                                                                            </Row>
                                                                            {regulation.masked === true &&
                                                                                <Row>
                                                                                    <Col className="div-masked">
                                                                                        {regulation.details.map((detail, index) => (
                                                                                            detail.filledInAt !== null &&
                                                                                            <Fragment key={index}>
                                                                                                {
                                                                                                    index === 0 &&
                                                                                                    <Row>
                                                                                                        <Col>
                                                                                                            <h6 className={"card-text-content ellipsis-1-v2"}
                                                                                                                style={{
                                                                                                                    textAlign: 'center'
                                                                                                                }}>
                                                                                                                {t('regulations.updated-at-label')}
                                                                                                                <Moment
                                                                                                                    locale="it"
                                                                                                                    format="DD/MM/YYYY"
                                                                                                                    className="black font-weight-bold">
                                                                                                                    {detail.filledInAt}
                                                                                                                </Moment>
                                                                                                            </h6>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                }
                                                                                                {
                                                                                                    (detail.regulationType === 'money' && detail.valueMoneyFinal !== null) &&
                                                                                                    <Fragment>
                                                                                                        <Row>
                                                                                                            <Col>
                                                                                                                <h6 className={"card-text-content ellipsis-1-v2"}>
                                                                                                                    {detail.parameter} {t('regulations.money-label')} {t('regulations.after-detailType-label')}
                                                                                                                    <span
                                                                                                                        className="font-weight-bold black">
                                                                                                                            <CurrencyFormat
                                                                                                                                displayType={'text'}
                                                                                                                                value={`${detail.valueMoneyFinal / 100}`}
                                                                                                                                thousandSeparator={'.'}
                                                                                                                                decimalSeparator={','}/>
                                                                                                                    </span>
                                                                                                                </h6>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                        {
                                                                                                            detail.note &&
                                                                                                            <Row>
                                                                                                                <Col>
                                                                                                                    <h6 className={"card-text-content ellipsis-1-v2"}
                                                                                                                        style={{
                                                                                                                            'marginTop': '-8px',
                                                                                                                            'paddingBottom': '8px'
                                                                                                                        }}>
                                                                                                                        {t('regulations.note')}
                                                                                                                        <span
                                                                                                                            className="font-weight-bold black">
                                                                                                                                {detail.note}
                                                                                                                        </span>
                                                                                                                    </h6>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        }
                                                                                                    </Fragment>
                                                                                                }
                                                                                                {
                                                                                                    (detail.regulationType === 'int' && detail.valueIntFinal !== null) &&
                                                                                                    <Fragment>
                                                                                                        <Row>
                                                                                                            <Col>
                                                                                                                <h6 className={"card-text-content ellipsis-1-v2"}>
                                                                                                                    {detail.parameter} {t('regulations.after-detailType-label')}
                                                                                                                    <span
                                                                                                                        className="font-weight-bold black">
                                                                                                                            {detail.valueIntFinal}
                                                                                                                </span>
                                                                                                                </h6>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                        {
                                                                                                            detail.note &&
                                                                                                            <Row>
                                                                                                                <Col>
                                                                                                                    <h6 className={"card-text-content ellipsis-1-v2"}
                                                                                                                        style={{
                                                                                                                            'marginTop': '-8px',
                                                                                                                            'paddingBottom': '8px'
                                                                                                                        }}>
                                                                                                                        {t('regulations.note')}
                                                                                                                        <span
                                                                                                                            className="font-weight-bold black">
                                                                                                                                {detail.note}
                                                                                                                        </span>
                                                                                                                    </h6>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        }
                                                                                                    </Fragment>
                                                                                                }
                                                                                                {
                                                                                                    detail.regulationType === 'doc' &&
                                                                                                    <Fragment>
                                                                                                        <Row>
                                                                                                            <Col>
                                                                                                                <h6 className={"card-text-content ellipsis-1-v2"}>
                                                                                                                    {t('regulations.documents-label')}
                                                                                                                    <span
                                                                                                                        className="font-weight-bold black">
                                                                                                                            {detail.docs}
                                                                                                                </span>
                                                                                                                </h6>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </Fragment>
                                                                                                }
                                                                                                {
                                                                                                    detail.regulationType === 'money, doc' &&
                                                                                                    <Fragment>
                                                                                                        {detail.valueMoneyFinal !== null &&
                                                                                                            <Fragment>
                                                                                                                <Row>
                                                                                                                    <Col>
                                                                                                                        <h6 className={"card-text-content ellipsis-1-v2"}>
                                                                                                                            {detail.parameter} {t('regulations.money-label')} {t('regulations.after-detailType-label')}
                                                                                                                            <span
                                                                                                                                className="font-weight-bold black">
                                                                                                                            <CurrencyFormat
                                                                                                                                displayType={'text'}
                                                                                                                                value={`${detail.valueMoneyFinal / 100}`}
                                                                                                                                thousandSeparator={'.'}
                                                                                                                                decimalSeparator={','}/>
                                                                                                                    </span>
                                                                                                                        </h6>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                                {
                                                                                                                    detail.note &&
                                                                                                                    <Row>
                                                                                                                        <Col>
                                                                                                                            <h6 className={"card-text-content ellipsis-1-v2"}
                                                                                                                                style={{
                                                                                                                                    'marginTop': '-8px',
                                                                                                                                    'paddingBottom': '8px'
                                                                                                                                }}>
                                                                                                                                {t('regulations.note')}
                                                                                                                                <span
                                                                                                                                    className="font-weight-bold black">
                                                                                                                                {detail.note}
                                                                                                                        </span>
                                                                                                                            </h6>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                }
                                                                                                            </Fragment>
                                                                                                        }
                                                                                                        <Row>
                                                                                                            <Col>
                                                                                                                <h6 className={"card-text-content ellipsis-1-v2"}>
                                                                                                                    {t('regulations.documents-label')}
                                                                                                                    <span
                                                                                                                        className="font-weight-bold black">
                                                                                                                            {detail.docs}
                                                                                                                </span>
                                                                                                                </h6>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </Fragment>
                                                                                                }
                                                                                                {
                                                                                                    detail.regulationType === 'int, doc' &&
                                                                                                    <Fragment>
                                                                                                        {detail.valueIntFinal &&
                                                                                                            <Fragment>
                                                                                                                <Row>
                                                                                                                    <Col>
                                                                                                                        <h6 className={"card-text-content ellipsis-1-v2"}>
                                                                                                                            {detail.parameter} {t('regulations.after-detailType-label')}
                                                                                                                            <span
                                                                                                                                className="font-weight-bold black">
                                                                                                                            {detail.valueIntFinal}
                                                                                                                    </span>
                                                                                                                        </h6>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                                {
                                                                                                                    detail.note &&
                                                                                                                    <Row>
                                                                                                                        <Col>
                                                                                                                            <h6 className={"card-text-content ellipsis-1-v2"}
                                                                                                                                style={{
                                                                                                                                    'marginTop': '-8px',
                                                                                                                                    'paddingBottom': '8px'
                                                                                                                                }}>
                                                                                                                                {t('regulations.note')}
                                                                                                                                <span
                                                                                                                                    className="font-weight-bold black">
                                                                                                                                {detail.note}
                                                                                                                        </span>
                                                                                                                            </h6>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                }
                                                                                                            </Fragment>
                                                                                                        }
                                                                                                        <Row>
                                                                                                            <Col>
                                                                                                                <h6 className={"card-text-content ellipsis-1-v2"}>
                                                                                                                    {t('regulations.documents-label')}
                                                                                                                    <span
                                                                                                                        className="font-weight-bold black">
                                                                                                                            {detail.docs}
                                                                                                                </span>
                                                                                                                </h6>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </Fragment>
                                                                                                }
                                                                                            </Fragment>
                                                                                        ))}
                                                                                    </Col>
                                                                                </Row>
                                                                            }
                                                                            <Row className="float-right">
                                                                                <Col>
                                                                                    {
                                                                                        regulation.closed === null &&
                                                                                        <Button
                                                                                            style={{'marginTop': '0px'}}
                                                                                            onClick={() => {
                                                                                                setSelectedRegulationId(regulation.id)
                                                                                                setSelectedPolicyBranch(regulation.branchName)
                                                                                                setSelectedCompany(regulation.companyName)
                                                                                                setSelectedDateEnd(regulation.dateEnd)
                                                                                                getRegulationDetailById(regulation.id)
                                                                                                setOpenDetailsDialog(true)
                                                                                            }}
                                                                                            className={classes.CTAButton}>{t('regulations.regulation-button')}
                                                                                        </Button>
                                                                                    }
                                                                                    {
                                                                                        regulation.closed === false &&
                                                                                        <Button
                                                                                            style={{'marginTop': '0px'}}
                                                                                            onClick={() => {
                                                                                                setSelectedRegulationId(regulation.id)
                                                                                                setSelectedPolicyBranch(regulation.branchName)
                                                                                                setSelectedCompany(regulation.companyName)
                                                                                                setSelectedDateEnd(regulation.dateEnd)
                                                                                                getRegulationDetailById(regulation.id)
                                                                                                setOpenDetailsDialog(true)
                                                                                            }}
                                                                                            className={classes.CTAButton}>{t('regulations.regulation-button')}
                                                                                        </Button>
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                        </CardContent>
                                                                    </Card>
                                                                </Fragment>
                                                            )
                                                        })
                                                        }
                                                    </List>

                                                </Row>
                                            </Container>
                                        </Collapse>
                                    </Fragment>))
                                }
                            </List>
                        </Fragment>
                    }
                </Row>
                <Container className="bottom-with-navbar"
                           style={{'backgroundColor': themeContext.color.neutral10}}></Container>
            </Container>
            <Dialog
                onClose={() => {
                    defaultValues = {}
                    setImages([addIcon])
                    setDocuments([])
                    setOpenDetailsDialog(false)
                }}
                fullScreen={!isDesktop}
                fullWidth={isDesktop}
                maxWidth={'sm'}
                PaperProps={{
                    style: {
                        bottom: isDesktop ? '5%' : '0',
                        color: themeContext.color.neutral600,
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                        borderRadius: isDesktop ? '20px' : 'none',
                        height: isDesktop ? '90%' : '100%',
                        marginTop: '10%'
                    }
                }}
                open={openDetailsDialog}
                TransitionComponent={Transition}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle id="alert-dialog-slide-title" className="dialog-title"
                             style={{textAlign: 'center'}}>
                    <Row style={{justifyContent: 'end'}}>
                        <img src={exitIcon} className="exit-button" alt="close" onClick={() => {
                            defaultValues = {}
                            setImages([addIcon])
                            setDocuments([])
                            setOpenDetailsDialog(false)
                            setContentTypeError(false)
                        }} style={{float: 'inline-end'}}/>
                    </Row>
                    <Row style={{marginTop: '-12px'}}>
                        <Col className="col-1">
                            <img className="align-baseline" src={getBranchIcon(selectedPolicyBranch)}
                                 alt="type-policy-icon"/>
                        </Col>
                        <Col className="col-10">
                            <h6 className="dialog-form-title-1 float-left">{selectedPolicyBranch}</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className="dialog-form-title-2 float-left">{t('regulations.company-label')} {selectedCompany}</h6>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <h6 className="dialog-form-title-3 float-left">{t('regulations.expiration-label')}
                                <span className="dialog-form-title-3">
                                    <Moment locale="it" format="DD/MM/YYYY">{selectedDateEnd}</Moment>
                            </span>
                            </h6>
                        </Col>
                    </Row>
                </DialogTitle>
                <DialogContent className="no-scrollbar">
                    <div className="dialog-main-container"
                         style={{paddingBottom: "96px"}}>
                        <Row>
                            <Col>
                                <h6 className="dialog-form-field">
                                <span className="ellipsis-1">
                                      {t('regulations.regulation-type')}
                                    <span className="dialog-form-value">
                                              {selectedRegulationType}
                                        </span>
                                </span>
                                </h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h6 className="dialog-form-field ellipsis-1-v2">
                                    {t('regulations.date-start')}
                                    <span className="dialog-form-value">
                                        <Moment locale="it" format="DD/MM/YYYY">{selectedCompetenceDateStart}</Moment>
                                </span>
                                </h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h6 className="dialog-form-field ellipsis-1-v2">
                                    {t('regulations.date-end')}
                                    <span className="dialog-form-value">
                                        <Moment locale="it" format="DD/MM/YYYY">{selectedCompetenceDateEnd}</Moment>
                                        </span>
                                </h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h6 className="dialog-form-field ellipsis-1-v2">
                                    {t('regulations.final-balance-days')}
                                    <span className="dialog-form-value">{selectedFinalDays}</span>
                                </h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h6 className="dialog-form-field ellipsis-1-v2">
                                    {t('regulations.payment-days')}
                                    <span className="dialog-form-value">
                                              {selectedPaymentDays}
                                        </span>
                                </h6>
                            </Col>
                        </Row>
                        <Row style={{marginTop: '56px'}}>
                            <Col>
                                <h6 className="dialog-form-subtitle ellipsis-1-v2">{t('regulations.regulation-section')}</h6>
                            </Col>
                        </Row>
                        <Form onSubmit={handleSendSubmit(submitForm)} style={{marginBottom: '30px'}}>
                            {
                                regulationDetails && regulationDetails.map((detail) => {
                                    return (
                                        <Fragment key={detail.detailId}>
                                            <Row>
                                                <Col>
                                                    <h6 className="dialog-parameter mt-4 text-capitalize">
                                                        {detail.parameter.toLowerCase()}
                                                    </h6>
                                                    {
                                                        detail.note !== null &&
                                                        <h6 className="dialog-notes">
                                                            <b>{t('regulations.note')}</b> {detail.note.toLowerCase()}
                                                        </h6>
                                                    }
                                                    {
                                                        detail.detailType === 'money' &&
                                                        <Fragment>
                                                            <Row>
                                                                <Col>
                                                                    <CurrencyFormat
                                                                        customInput={TextField}
                                                                        thousandSeparator={'.'}
                                                                        decimalSeparator={','}
                                                                        allowNegative={false}
                                                                        onValueChange={(values) => {
                                                                            const {
                                                                                formattedValue,
                                                                                value,
                                                                                floatValue
                                                                            } = values;
                                                                            formValues.current = {
                                                                                ...formValues.current,
                                                                                [`${detail.detailId}${detail.parameter}`]: floatValue
                                                                            }
                                                                        }}
                                                                        id={`${detail.detailId}${detail.parameter}`}
                                                                        autoComplete={'off'}
                                                                        name={detail.detailId + detail.parameter}
                                                                        placeholder=""
                                                                        variant="outlined"
                                                                        value={`${detail.valueMoneyFinal}`}
                                                                        color="primary"
                                                                        style={{
                                                                            marginTop: '8px',
                                                                            backgroundColor: themeContext.color.white,
                                                                            width: '100%'
                                                                        }}
                                                                        InputProps={{
                                                                            style: {
                                                                                borderRadius: '4px',
                                                                                color: themeContext.color.neutral600
                                                                            },
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <img src={euroIcon}
                                                                                         alt="payments-icon"/>
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            {detail.valueMoneyAdvanced !== null &&
                                                                <Row className="mt-1">
                                                                    <Col>
                                                                        <h6 className="dialog-textfield-label ellipsis-1-v2">
                                                                            {t('regulations.advanced-value')}
                                                                            <b>
                                                                                <CurrencyFormat displayType={'text'}
                                                                                                value={`${detail.valueMoneyAdvanced / 100}`}
                                                                                                thousandSeparator={'.'}
                                                                                                decimalSeparator={','}/>
                                                                            </b>
                                                                        </h6>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        </Fragment>
                                                    }
                                                    {
                                                        detail.detailType === 'int' &&
                                                        <Fragment>
                                                            <Row>
                                                                <Col>
                                                                    <TextField
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        onChange={handleInputChange}
                                                                        id={`${detail.detailId}${detail.parameter}`}
                                                                        autoComplete={'off'}
                                                                        name={detail.detailId + detail.parameter}
                                                                        fullWidth
                                                                        placeholder=""
                                                                        variant="outlined"
                                                                        type="number"
                                                                        defaultValue={detail.valueIntFinal}
                                                                        color="primary"
                                                                        InputProps={{
                                                                            inputProps: {min: 0},
                                                                            style: {
                                                                                'borderRadius': '4px',
                                                                                'color': themeContext.color.neutral600
                                                                            }
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            {detail.valueIntAdvanced !== null &&
                                                                <Row className="mt-1">
                                                                    <Col>
                                                                        <h6 className="dialog-textfield-label ellipsis-1-v2">
                                                                            {t('regulations.advanced-value')}<b>{detail.valueIntAdvanced}</b>
                                                                        </h6>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        </Fragment>
                                                    }
                                                    {
                                                        detail.detailType === 'doc' &&
                                                        <Fragment>
                                                            {
                                                                detail.thumbUrl !== 'ND' &&
                                                                <Fragment>
                                                                    <Row>
                                                                        <Col>
                                                                            <img alt="thumb-pdf" className="thumb-pdf"
                                                                                 src={detail.thumbUrl}
                                                                                 onClick={() => {
                                                                                     window.open(detail.fileUrl, '_blank', 'noopener,noreferrer');
                                                                                 }}/>
                                                                        </Col>
                                                                    </Row>
                                                                </Fragment>
                                                            }
                                                            <Row className="mt-4">
                                                                <Col style={{textAlign: 'center'}}>
                                                                    <Button
                                                                        className="outline-button"
                                                                        onClick={() => {
                                                                            setSelectedParameterId(detail.detailId + detail.parameter)
                                                                            setSelectedParameter(detail.parameter)
                                                                            let docFinded = documents.findIndex((item) => item.parameter === detail.detailId + detail.parameter)
                                                                            if (docFinded !== -1)
                                                                                setImages(current => [...current, pdfIcon])
                                                                            else {
                                                                                setImages([addIcon])
                                                                            }
                                                                            setOpenUploadDialog(true)
                                                                        }}
                                                                        variant="outlined"
                                                                        size="large"
                                                                        type="submit"
                                                                        style={isDesktop ? {width: '300px'} : {width: '100%'}}
                                                                    >{t('regulations.upload-button')}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Fragment>
                                                    }
                                                    {
                                                        detail.detailType === 'money, doc' &&
                                                        <Fragment>
                                                            <Row>
                                                                <Col>
                                                                    <CurrencyFormat
                                                                        customInput={TextField}
                                                                        thousandSeparator={'.'}
                                                                        decimalSeparator={','}
                                                                        allowNegative={false}
                                                                        onValueChange={(values) => {
                                                                            const {
                                                                                formattedValue,
                                                                                value,
                                                                                floatValue
                                                                            } = values;
                                                                            formValues.current = {
                                                                                ...formValues.current,
                                                                                [`${detail.detailId}${detail.parameter}`]: floatValue
                                                                            }
                                                                        }}
                                                                        id={`${detail.detailId}${detail.parameter}`}
                                                                        autoComplete={'off'}
                                                                        name={detail.detailId + detail.parameter}
                                                                        placeholder=""
                                                                        variant="outlined"
                                                                        value={`${detail.valueMoneyFinal}`}
                                                                        color="primary"
                                                                        style={{
                                                                            marginTop: '8px',
                                                                            backgroundColor: themeContext.color.white,
                                                                            width: '100%'
                                                                        }}
                                                                        InputProps={{
                                                                            style: {
                                                                                borderRadius: '4px',
                                                                                color: themeContext.color.neutral600
                                                                            },
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <img src={euroIcon}
                                                                                         alt="payments-icon"/>
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            {detail.valueMoneyAdvanced !== null &&
                                                                <Row className="mt-1">
                                                                    <Col>
                                                                        <h6 className="dialog-textfield-label ellipsis-1-v2">
                                                                            {t('regulations.advanced-value')}
                                                                            <b>
                                                                                <CurrencyFormat displayType={'text'}
                                                                                                value={`${detail.valueMoneyAdvanced / 100}`}
                                                                                                thousandSeparator={'.'}
                                                                                                decimalSeparator={','}/>
                                                                            </b>
                                                                        </h6>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            {
                                                                detail.thumbUrl !== 'ND' &&
                                                                <Fragment>
                                                                    <Row>
                                                                        <Col>
                                                                            <img alt="thumb-pdf" className="thumb-pdf"
                                                                                 src={detail.thumbUrl}
                                                                                 onClick={() => {
                                                                                     window.open(detail.fileUrl, '_blank', 'noopener,noreferrer');
                                                                                 }}/>
                                                                        </Col>
                                                                    </Row>
                                                                </Fragment>
                                                            }
                                                            <Row className="mt-4">
                                                                <Col style={{textAlign: 'center'}}>
                                                                    <Button
                                                                        className="outline-button"
                                                                        onClick={() => {
                                                                            setSelectedParameterId(detail.detailId + detail.parameter)
                                                                            setSelectedParameter(detail.parameter)
                                                                            let docFinded = documents.findIndex((item) => item.parameter === detail.detailId + detail.parameter)
                                                                            if (docFinded !== -1)
                                                                                setImages(current => [...current, pdfIcon])
                                                                            else {
                                                                                setImages([addIcon])
                                                                            }
                                                                            setOpenUploadDialog(true)
                                                                        }}
                                                                        variant="outlined"
                                                                        size="large"
                                                                        type="submit"
                                                                        style={isDesktop ? {width: '100%'} : {maxWidth: '300px'}}
                                                                    >{t('regulations.upload-button')}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Fragment>
                                                    }
                                                    {
                                                        detail.detailType === 'int, doc' &&
                                                        <Fragment>
                                                            <Row>
                                                                <Col>
                                                                    <TextField
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        onChange={handleInputChange}
                                                                        id={`${detail.detailId}${detail.parameter}`}
                                                                        autoComplete={'off'}
                                                                        name={detail.detailId + detail.parameter}
                                                                        fullWidth
                                                                        placeholder=""
                                                                        variant="outlined"
                                                                        type="number"
                                                                        defaultValue={detail.valueIntFinal}
                                                                        color="primary"
                                                                        InputProps={{
                                                                            inputProps: {min: 0},
                                                                            style: {
                                                                                'borderRadius': '4px',
                                                                                'color': themeContext.color.neutral600
                                                                            }
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            {detail.valueIntAdvanced !== null &&
                                                                <Row className="mt-1">
                                                                    <Col>
                                                                        <h6 className="dialog-textfield-label ellipsis-1-v2">
                                                                            {t('regulations.advanced-value')}<b>{detail.valueIntAdvanced}</b>
                                                                        </h6>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            {
                                                                detail.thumbUrl !== 'ND' &&
                                                                <Fragment>
                                                                    <div className="container ml-2 mt-2" style={{
                                                                        position: 'relative',
                                                                        textAlign: 'left',
                                                                        padding: '0'
                                                                    }}>
                                                                        <img alt="thumb-pdf" className="thumb-pdf"
                                                                             src={detail.thumbUrl}
                                                                             onClick={() => {
                                                                                 window.open(detail.fileUrl, '_blank', 'noopener,noreferrer');
                                                                             }}/>
                                                                    </div>
                                                                </Fragment>
                                                            }
                                                            <Row className="mt-4">
                                                                <Col style={{textAlign: 'center'}}>
                                                                    <Button
                                                                        className="outline-button"
                                                                        onClick={() => {
                                                                            setSelectedParameterId(detail.detailId + detail.parameter)
                                                                            setSelectedParameter(detail.parameter)
                                                                            let docFinded = documents.findIndex((item) => item.parameter === detail.detailId + detail.parameter)
                                                                            if (docFinded !== -1)
                                                                                setImages(current => [...current, pdfIcon])
                                                                            else {
                                                                                setImages([addIcon])
                                                                            }
                                                                            setOpenUploadDialog(true)
                                                                        }}
                                                                        variant="outlined"
                                                                        size="large"
                                                                        type="submit"
                                                                        style={isDesktop ? {width: '100%'} : {maxWidth: '300px'}}
                                                                    >{t('regulations.upload-button')}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Fragment>
                                                    }
                                                </Col>
                                            </Row>
                                        </Fragment>
                                    )
                                })}
                        </Form>
                        <Container className="position-absolute"
                                   style={
                                       isDesktop ? {
                                           background: 'transparent',
                                           opacity: '1',
                                           left: 0,
                                           bottom: 48,
                                           right: 0
                                       } : {
                                           background: 'transparent',
                                           opacity: '1',
                                           left: 0,
                                           bottom: 68,
                                           right: 0
                                       }
                                   }>
                            <Row style={{textAlign: 'center'}}>
                                <Col>
                                    <Button className="close-button boxShadow"
                                            variant="outlined"
                                            size="large"
                                            style={{
                                                color: themeContext.color.white,
                                                width: '288px',
                                                textTransform: 'none'
                                            }}
                                            type="submit"
                                            disabled={false}
                                            onClick={() => {
                                                if (!isDocumentPresent) {
                                                    uploadRegulationDetail(regulationDetails)
                                                    setOpenDetailsDialog(false)
                                                    setImages([])
                                                    setDocuments([])
                                                    formValues.current = {}
                                                    setImages([addIcon])
                                                    getRegulations();
                                                } else {
                                                    setOpenUploadConfirmDialog(true)
                                                }
                                            }}
                                    >{t('regulations.save-and-close-button')}</Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                onClose={() => {
                    setOpenUploadDialog(false)
                    setImages([addIcon])
                }}
                fullScreen={!isDesktop}
                fullWidth={isDesktop}
                maxWidth={'sm'}
                PaperProps={{
                    style: {
                        bottom: isDesktop ? '5%' : '0',
                        color: themeContext.color.neutral600,
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                        borderRadius: isDesktop ? '20px' : 'none',
                        height: isDesktop ? '90%' : '100%',
                        marginTop: '10%'
                    }
                }}
                open={openUploadDialog}
                TransitionComponent={Transition}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description">


                <DialogTitle id="alert-dialog-slide-title" className="dialog-title ellipsis-1-v2"
                             style={{textAlign: 'center'}}>
                    <Row style={{justifyContent: 'end'}}>
                        <img src={exitIcon} className="exit-button" alt="close" onClick={() => {
                            setOpenUploadDialog(false)
                            setImages([addIcon])
                            setContentTypeError(false)
                        }} style={{float: 'inline-end'}}/>
                    </Row>
                    <Row style={{marginTop: '-12px'}}>
                        <Col className="col">
                            <h6 className="dialog-form-title-1 ellipsis-1-v2 float-left text-left">{selectedParameter}</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className="dialog-form-title-2 ellipsis-1-v2 float-left text-left">
                                {t('regulations.pdf-dialog-title')}
                            </h6>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <h6 className="dialog-form-title-3 ellipsis-1-v2 float-left">
                                {t('regulations.pdf-dialog-subtitle')}
                            </h6>
                        </Col>
                    </Row>
                </DialogTitle>
                <DialogContent className="no-scrollbar">
                    <div className="dialog-main-container">
                        <Row className="mt-4">
                            <Col>
                                <Carousel showThumbs={true} showArrows={false} showIndicators={false}
                                          showStatus={false} swipeable={true} axis={'vertical'}
                                          renderThumbs={renderCustomThumbs}>
                                    {
                                        images.map((item, index) => (
                                            <div key={index}>
                                                <input id="file" type="file"
                                                       onChange={event => handleChange(event, selectedParameterId)}
                                                       ref={hiddenFileInput}
                                                       style={{display: 'none'}}/>
                                            </div>
                                        ))
                                    }
                                </Carousel>
                            </Col>
                        </Row>
                        {
                            contentTypeError &&
                            <Row className="ml-4 mr-4 mt-4">
                                <Col className="mt-4 text-center dialog-form-title-3 red">
                                    {t('regulations.content-type-error')}
                                </Col>
                            </Row>
                        }
                        <Container className="position-absolute"
                                   style={
                                       isDesktop ? {
                                           background: 'transparent',
                                           opacity: '1',
                                           left: 0,
                                           bottom: 48,
                                           right: 0
                                       } : {
                                           background: 'transparent',
                                           opacity: '1',
                                           left: 0,
                                           bottom: 68,
                                           right: 0
                                       }
                                   }>
                            <Row style={{textAlign: 'center'}}>
                                <Col>
                                    <Button className="close-button boxShadow"
                                            variant="outlined"
                                            size="large"
                                            style={{
                                                color: themeContext.color.white,
                                                width: '288px',
                                                textTransform: 'none'
                                            }}
                                            type="submit"
                                            disabled={false}
                                            onClick={() => {
                                                setImages([addIcon])
                                                setOpenUploadDialog(false)
                                            }}
                                    >{t('regulations.save-pdf-button')}</Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </DialogContent>
            </Dialog>
            <DialogDoubleAction open={openUploadConfirmDialog}
                                title={t('regulations.regulation-confirm-title')}
                                description={t('regulations.regulation-confirm-description')}
                                handleClose={() => setOpenUploadConfirmDialog(false)}
                                handleNo={() => setOpenUploadConfirmDialog(false)}
                                handleYes={() => {
                                    uploadRegulationDetail(regulationDetails)
                                    setOpenDetailsDialog(false)
                                    setOpenUploadConfirmDialog(false)
                                    setImages([])
                                    setDocuments([])
                                    formValues.current = {}
                                    setImages([addIcon])
                                }}
                                buttonYesText={t('dialog.button-proceed')}
                                buttonNoText={t('dialog.button-cancel')}/>
        </Fragment>)
}