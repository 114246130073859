import React, {createContext, useState} from 'react';

export const NavbarContext = createContext();

export const NavbarProvider = ({children}) => {
    const [visibility, setVisibility] = useState(true);

    const toggleNavbar = (value) => {
        setVisibility(value);
    };

    return (
        <NavbarContext.Provider value={{visibility, toggleNavbar}}>
            {children}
        </NavbarContext.Provider>
    );
};