import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";
import Container from 'react-bootstrap/Container';
import {Col, Form, Row} from "react-bootstrap";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import * as idb from "idb";
import back from "../../images/back-icon.svg";
import bin from "../../images/bin.svg";
import dots from "../../images/dots-icon.svg";
import {makeStyles} from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Button, Menu, MenuItem, Slide, TextField} from "@mui/material";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import goTo from "../../images/goto-icon.svg";
import sinistri from "../../images/claims-icon.svg";
import policies from "../../images/shield-contracts-icon.svg";
import baseMaleAvatar from "../../images/male-icon.svg";
import baseFemaleAvatar from "../../images/female-icon.svg";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Resizer from "react-image-file-resizer";
import FsLightbox from "fslightbox-react";
import AppShareComponent from "../components/AppShare.js";
import exitIcon from "../../images/exit-icon.svg";
import {useMediaQuery} from "react-responsive";
import bankReferencesIcon from "../../images/bank-references.svg";
import DialogDoubleAction from "../components/DialogDoubleAction.js";
import baseCompanyAvatar from "../../images/company-avatar.svg";
import CropDialog from "../components/CropDialog.js";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";
import DialogSmall from "../components/DialogSmall.js";
import ErrorPage from "../pages/ErrorPage.js";

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1920,
            1080,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PersonDetail() {
    const root = 'personDetail';
    const {t, i18n} = useTranslation();
    const [isLoadingImg, setIsLoadingImg] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [openDialog, setOpenDialog] = useState(false)

    useEffect(() => {
        if (navigator.onLine) {
            getPerson();
        } else {
            getOfflineInfo();
        }
    }, []);

    const useStyles = makeStyles((theme) => ({
        divList: {
            width: '100% !important',
            backgroundColor: `${theme.palette.background.paper} !important`,
            padding: "0 0 0 0 !important"
        },
        list: {
            paddingTop: '0 !important',
            paddingBottom: '30px !important',
        },
        listItem: {
            color: `${themeContext.color.neutral600} !important`,
            padding: '16px 0 0 0 !important',
            fontSize: '18px !important',
            position: "relative !important"
        },
        listFullItem: {
            color: `${themeContext.color.neutral400} !important`,
            paddingTop: '18px !important',
            paddingBottom: '18px !important',
            fontSize: '18px !important'
        },
        listTextRight: {
            textAlign: "right !important",
            font: "normal normal bold 12px/22px Inter !important",
            letterSpacing: "0px !important",
            color: `${themeContext.color.neutral400} !important`,
            opacity: "1 !important",
            justifyContent: "end !important"
        },
        avatar: {
            margin: '0 auto !important',
            width: '94px !important',
            height: '94px !important',
        },
    }));

    const [person, setPerson] = useState([]);
    const [mails, setMails] = useState([]);
    const authCtx = useContext(AuthContextMiddleware);
    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = useState(null);
    const personInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/person`;
    const changeImageUrl = `${process.env.REACT_APP_BASE_URL}/media/person/changeImage`;
    const peopleMailUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/people/mail`;
    const removeMailUrl = `${process.env.REACT_APP_BASE_URL}/management/profile/mailCancel`;
    const userInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/info`;
    const inviteUserUrl = `${process.env.REACT_APP_BASE_URL}/management/auth/inviteUser`;
    const deleteImageUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/person`;
    const token = sessionStorage.getItem('token');
    const loggedMailUser = localStorage.getItem('username-wide');
    const [infoLoggedPerson, setInfoLoggedPerson] = useState([]);
    const params = useParams();
    const personId = params.personId;
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [openCropDialog, setOpenCropDialog] = useState(false);
    const [onPendingImage, setOnPendingImage] = useState("")
    const [cropper, setCropper] = useState(null);
    const cropperRef = useRef(null);
    const [scaleX, setScaleX] = useState(1);
    const [scaleY, setScaleY] = useState(1);
    const [toggler, setToggler] = useState(false);
    const [mail, setMail] = useState("");
    const [image, setImage] = useState(1)
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'})
    const [formValues, setFormValues] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        iban: ""
    });
    const [formDialogValues, setFormDialogValues] = useState({
        email: "",
    });
    const [oldIban, setOldIban] = useState("");
    const classes = useStyles();
    const [openInvitationDialog, setOpenInvitationDialog] = useState(false);
    const [emailDialogError, setEmailDialogError] = useState(null);
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const fromMessages = query.get('from_messages');
    const [openDialogDoubleAction, setOpenDialogDoubleAction] = useState(false);
    const [openSizeExceededDialog, setOpenSizeExceededDialog] = useState(false);
    const [openTypeNotSupportedDialog, setOpenTypeNotSupportedDialog] = useState(false);


    function getPerson() {
        setIsLoadingData(true)
        fetch(`${personInfoUrl}/${personId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.status !== -1) {
                setPerson(data);
                setFormValues({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    phone: data.phone,
                    email: data.email,
                    iban: data.iban
                })
                setOldIban(data.iban)
            }
            getUserInfo();
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const getUserInfo = () => {
        fetch(userInfoUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.status !== -1) {
                setInfoLoggedPerson(data);
            }
            getPeopleMails();
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const getPeopleMails = () => {
        fetch(`${peopleMailUrl}/${personId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setMails(data);
            setIsLoadingData(false)
        }).catch(() => {
            setErrorPage(true)
        })
    }

    function getOfflineInfo() {
        const dbPromise = idb.openDB('appDB', 1, {
            upgrade(upgradeDb) {
                if (!upgradeDb.objectStoreNames.contains('profileInfo')) {
                    upgradeDb.createObjectStore('profileInfo', {keyPath: 'key'});
                }
            }
        });
        dbPromise.then((db) => {
            let tx = db.transaction('profileInfo', 'readwrite').objectStore('profileInfo');
            return tx.getAll();
        }).then((array) => {
            for (let i = 0; i < array.length; i++) {
                if (array[i].key === 'firstName') {
                    person.firstName = array[i].value;
                    //setFirstName(array[i].value)
                }
                if (array[i].key === 'lastName') {
                    person.lastName = array[i].value;
                    //setLastName(array[i].value)
                }
                if (array[i].key === 'userID') {
                    person.id = array[i].value;
                    //setUserID(array[i].value)
                }
            }
        });
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const handleDialogInputChange = (e) => {
        if (emailDialogError) {
            setEmailDialogError(null)
        }
        const {name, value} = e.target;
        setFormDialogValues({
            ...formDialogValues,
            [name]: value
        });
    };

    const handlePinChange = () => {
        authCtx.changePin();
    }

    const handleOpenMenu = (event) => {
        setOpenMenu(event.currentTarget);
    }

    const handleCloseMenu = () => {
        setOpenMenu(null);
    }

    const handlePasswordChange = () => {
        //
    }

    const handleChangeImage = () => {
        const input = document.querySelector("#image-file");
        input.click();
    }

    const handleOnPendingImage = async (e) => {
        try {
            e.preventDefault();
            let file = e.target.files[0];
            if (file && !file.type.includes('image')) {
                throw 'File not supported';
            }
            if (file && file.size > 20000000) {
                throw 'Maximum upload size exceeded';
            }
            const imageResized = await resizeFile(file)
            setOnPendingImage(imageResized);
            setOpenCropDialog(true)
        } catch (err) {
            console.log(err)
            if (err === 'Maximum upload size exceeded') {
                setOpenSizeExceededDialog(true)
            } else if (err === 'File not supported') {
                setOpenTypeNotSupportedDialog(true)
            }
        }
    };

    const attachCroppedImage = () => {
        let cropImage = "#";
        if (typeof cropper !== "undefined") {
            cropImage = cropper.getCanvas().toDataURL()
        }
        if (cropImage !== "#") {
            const randomName = Math.floor((Math.random() * 100000000) + 1).toString()
            let split1 = cropImage.split(":");
            let split2 = split1[1].split(";");
            let mimetype = split2[0];
            let base64_fullString = cropImage.split(",");
            let base64_string = base64_fullString[1];
            setIsLoadingImg(true);
            fetch(changeImageUrl, {
                method: 'POST',
                body: JSON.stringify({
                    personId: personId,
                    mimeType: mimetype,
                    fileName: randomName,
                    base64: base64_string,
                    type: "people"
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Access-Control-Allow-Origin': true
                },
            }).then((res) => {
                if (res.ok) {
                    //alert upload completato
                    return res.json();
                }
            }).then((res) => {
                setIsLoadingImg(false);
                setOpenCropDialog(false)
                setOpenMenu(false);
                getPerson()
            }).catch(() => {
                setErrorPage(true)
            });
        }
    }

    const setStatusColor = (value) => {
        switch (value) {
            case 1:
                return themeContext.color.neutral200;
            case 2:
            case 5:
                return themeContext.color.green;
            case 3:
                return themeContext.color.yellow;
            case 4:
                return themeContext.color.red;
        }
    }

    function turnBack() {
        const currentUrl = window.location.pathname;
        if (fromMessages === 'true') {
            return navigate(-1);
        } else {
            return navigate(currentUrl.replace(`/${personId}`, ''));
        }
    }

    const setGrayScale = (value) => {
        switch (value) {
            case 1:
                return 'grayscale(1)';
            case 2:
            case 3:
            case 4:
                return '';
        }
    }

    function reload() {
        window.location.reload();
    }

    function handleOpenDialog(mail, image) {
        setMail(mail)
        setImage(image)
        setOpenDialog(true)
    }

    function handleRemoveMail(mail) {
        fetch(removeMailUrl, {
            method: 'POST',
            body: JSON.stringify({
                mail: mail,
                id: personId,
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Origin': true
            },
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }
        }).then((res) => {
            setOpenDialog(false)
        }).catch(() => {
            setOpenDialog(false)
            setErrorPage(true)
        });
    }

    function handleInvitation(e) {
        e.preventDefault();
        if (mails.includes(formDialogValues.email)) {
            setEmailDialogError(t('personDetail.user-already-invited'));
        } else {
            sessionStorage.setItem('invitation_fiscalCode', person.fiscalCode);
            sessionStorage.setItem('invitation_email', formDialogValues.email);
            return navigate(`/settings/people/${personId}/invitation`);
        }
    }

    function handleDeleteImage(image) {
        if (image !== null)
            fetch(`${deleteImageUrl}/${personId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            })
                .then((responseData) => {
                    if (responseData.ok) {
                        // Image removed successfully
                        setIsLoadingData(true)
                        // Refresh person details
                        fetch(`${personInfoUrl}/${personId}`, {
                            method: 'GET',
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json'
                            }
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                setPerson(data);
                                setIsLoadingData(false)
                                setOpenMenu(false)
                                setOpenDialogDoubleAction(false)
                            })
                            .catch(() => {
                                setOpenMenu(false)
                                setOpenDialogDoubleAction(false)
                                setErrorPage(true);
                            });
                    } else {
                        // Error removing image
                        setOpenMenu(false)
                        setOpenDialogDoubleAction(false)
                        setErrorPage(true);
                    }
                })
                .catch(() => {
                    setOpenMenu(false)
                    setOpenDialogDoubleAction(false)
                    setErrorPage(true);
                });
    }

    return (
        errorPage ?
            <ErrorPage/> :
            <Fragment>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                    <Row className="nav-top container border-bottom" style={{
                        'backgroundColor': themeContext.color.white,
                        'paddingTop': '19.5px',
                        'paddingLeft': '0',
                        'paddingRight': '0',
                    }}>
                        <Col>
                            <Row>
                                <Col className='col-2 float-left'>
                                    <img src={back} onClick={turnBack}
                                         className="nav-top-logo float-left cursor-pointer"
                                         height="27px"
                                         alt="back"/>
                                </Col>
                                <Col className="col-8">
                                    {
                                        ((person.firstName === null || person.firstName === "") && (person.lastName === null || person.lastName === "")) ?
                                            <h6 className="header-title ellipsis-1-v2">{person.name}</h6> :
                                            <h6 className="header-title ellipsis-1-v2">{person.firstName} {person.lastName}</h6>
                                    }
                                </Col>
                                <Col className="col-2 float-right">
                                    <img src={dots} onClick={handleOpenMenu}
                                         className="nav-top-logo float-right cursor-pointer" alt="dots" height="27px"/>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={openMenu}
                                        keepMounted
                                        open={Boolean(openMenu)}
                                        onClose={handleCloseMenu}
                                        className="menu">
                                        <MenuItem className="menu-item" onClick={handleChangeImage}>
                                            <input hidden id="image-file" type="file" onChange={handleOnPendingImage}
                                                   accept="image/*"/>
                                            {t(`${root}.change-image`)}
                                        </MenuItem>
                                        <MenuItem className="menu-item"
                                                  onClick={() => setOpenDialogDoubleAction(true)}
                                                  style={{
                                                      color: themeContext.color.red,
                                                      display: person.image ? 'flex' : 'none'
                                                  }}>
                                            <input hidden id="image-file" type="file" accept="image/*"/>
                                            {t('personDetail.delete-image')}
                                        </MenuItem>
                                    </Menu>
                                </Col>
                            </Row>
                            {
                                isLoadingImg ?
                                    <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                                    :
                                    <Row style={{
                                        'marginTop': '33.5px',
                                        'marginBottom': '20px'
                                    }}>
                                        <Col>
                                            {
                                                person.image === null && person.gender === 'M' &&
                                                <Avatar src={baseMaleAvatar} className={classes.avatar}
                                                        id="person-image"
                                                        style={{
                                                            filter: `${setGrayScale(person.status)}`,
                                                            'border': `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                        }}/>
                                            }
                                            {
                                                person.image === null && person.gender === 'F' &&
                                                <Avatar src={baseFemaleAvatar} className={classes.avatar}
                                                        id="person-image"
                                                        style={{
                                                            filter: `${setGrayScale(person.status)}`,
                                                            'border': `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                        }}/>
                                            }
                                            {
                                                person.image === null && person.gender === 'C' &&
                                                <Avatar src={baseCompanyAvatar} className={classes.avatar}
                                                        id="person-image"
                                                        style={{
                                                            filter: `${setGrayScale(person.status)}`,
                                                            'border': `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                        }}/>
                                            }
                                            {
                                                person.image === null && person.gender === null &&
                                                <Avatar src={baseMaleAvatar} className={classes.avatar}
                                                        id="person-image"
                                                        style={{
                                                            filter: `${setGrayScale(person.status)}`,
                                                            'border': `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                        }}/>
                                            }
                                            {
                                                person.image &&
                                                <Avatar src={person.image} className={classes.avatar} id="person-image"
                                                        onClick={() => setToggler(!toggler)}
                                                        style={{
                                                            filter: `${setGrayScale(person.status)}`,
                                                            'border': `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                        }}/>
                                            }
                                        </Col>
                                    </Row>
                            }
                        </Col>
                    </Row>
                    <Container className="top-of-the-person-detail"></Container>

                    {
                        isLoadingData ?
                            <div style={{'height': '60vh'}} className="background-loading-spinner"></div>
                            :
                            <Fragment>
                                <Container style={{
                                    'backgroundColor': themeContext.color.white,
                                    'paddingLeft': '0px',
                                    'paddingRight': '0px',
                                }}>
                                    <Row>
                                        <Col style={{
                                            'paddingRight': '0px',
                                            'paddingLeft': '19.5px'
                                        }}>
                                            <div className={classes.divList}>
                                                <List className={classes.list}>
                                                    {
                                                        ((person.firstName === null || person.firstName === "") && (person.lastName === null || person.lastName === "")) ?
                                                            <Fragment>
                                                                <ListItem className={classes.listItem}>
                                                                    <TextField
                                                                        variant={'standard'}
                                                                        label={t(`${root}.first-name`)}
                                                                        name="firstName"
                                                                        id="firstName"
                                                                        fullWidth
                                                                        className="custom-textField-baseline-disable"
                                                                        //defaultValue={`${person.firstName}`}
                                                                        value={person.name || ''}
                                                                        //onChange={handleInputChange}
                                                                        InputProps={{
                                                                            style: {
                                                                                'color': themeContext.color.neutral600
                                                                            },
                                                                        }}/>
                                                                </ListItem>
                                                            </Fragment> :
                                                            <Fragment>
                                                                <ListItem className={classes.listItem}>
                                                                    <TextField
                                                                        variant={'standard'}
                                                                        label={t(`${root}.first-name`)}
                                                                        name="firstName"
                                                                        id="firstName"
                                                                        fullWidth
                                                                        className="custom-textField-baseline-disable"
                                                                        //defaultValue={`${person.firstName}`}
                                                                        value={person.firstName || ''}
                                                                        //onChange={handleInputChange}
                                                                        InputProps={{
                                                                            style: {
                                                                                'color': themeContext.color.neutral600
                                                                            },
                                                                        }}/>
                                                                </ListItem>
                                                                <ListItem className={classes.listItem}>
                                                                    <TextField
                                                                        variant={'standard'}
                                                                        label={t(`${root}.last-name`)}
                                                                        id="lastName"
                                                                        fullWidth
                                                                        className="custom-textField-baseline-disable"
                                                                        value={person.lastName || ''}
                                                                        //defaultValue={`${person.lastName}`}
                                                                        InputProps={{
                                                                            style: {
                                                                                'color': themeContext.color.neutral600
                                                                            },
                                                                        }}/>
                                                                </ListItem>
                                                            </Fragment>
                                                    }
                                                    <ListItem className={classes.listItem}
                                                              style={{alignItems: 'center'}}>
                                                        <ListItemText>
                                                            <span className={"custom-title-mail"}>
                                                                   {t(`${root}.mail`)}
                                                            </span>
                                                        </ListItemText>
                                                        <ListItemIcon style={{
                                                            padding: '5px 12px 15px 12px'
                                                        }}>
                                                            {person.gender === 'C' &&
                                                                <Button
                                                                    className="custom-button-add-mail"
                                                                    type={'button'}
                                                                    onClick={() => {
                                                                        setOpenInvitationDialog(true)
                                                                    }}>
                                                                    {t(`${root}.add`)}
                                                                </Button>
                                                            }
                                                        </ListItemIcon>
                                                    </ListItem>
                                                    {mails.map((val, index) => (
                                                        <Fragment key={index}>
                                                            {index === 0 && <Divider/>}
                                                            <ListItem style={{padding: '16px 0 16px 0'}}>
                                                                <Col
                                                                    className={(infoLoggedPerson.fiscalCode === person.fiscalCode && val === loggedMailUser) ? "custom-mail-text custom-disabled-mail-text" : "custom-mail-text"}>{val}</Col>
                                                                <Col className="custom-mail-remove">
                                                                    {mails.length > 1 &&
                                                                        <img src={bin}
                                                                             onClick={() => handleOpenDialog(val)}
                                                                             className="cursor-pointer custom-img-mail"
                                                                             height="30px"
                                                                             hidden={infoLoggedPerson.fiscalCode === person.fiscalCode && val === loggedMailUser}
                                                                             alt="bin"/>
                                                                    }
                                                                </Col>
                                                            </ListItem>
                                                            <Divider/>
                                                        </Fragment>
                                                    ))}
                                                </List>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            <div>
                                                <List style={{'paddingTop': '0px'}}>
                                                    <Divider/>
                                                    <Link to={`/settings/people/${personId}/bank-references`}
                                                          style={{'textDecoration': 'none'}}>
                                                        <ListItem className={classes.listFullItem} button>
                                                            <ListItemIcon>
                                                                <img src={bankReferencesIcon} alt="image" width="28px"/>
                                                            </ListItemIcon>
                                                            <ListItemText primary={t(`${root}.bank-references`)}/>
                                                            <ListItemIcon className="justify-content-end">
                                                                <img src={goTo} alt="image" height="20px"/>
                                                            </ListItemIcon>
                                                        </ListItem>
                                                    </Link>
                                                    <Divider/>
                                                    <Link to={`/settings/people/${personId}/policies`}
                                                          style={{'textDecoration': 'none'}}>
                                                        <ListItem className={classes.listFullItem} button>
                                                            <ListItemIcon>
                                                                <img src={policies} alt="image" width="28px"/>
                                                            </ListItemIcon>
                                                            <ListItemText primary={t(`${root}.list-policies`)}/>
                                                            <ListItemIcon className="justify-content-end">
                                                        <span
                                                            className={classes.listTextRight}>{t(`${root}.list-active-policies`)} {person.activePoliciesNr}</span>
                                                                <img src={goTo} alt="image" height="20px"/>
                                                            </ListItemIcon>
                                                        </ListItem>
                                                    </Link>
                                                    <Divider/>
                                                    <Link to={`/settings/people/${personId}/claims`}
                                                          style={{'textDecoration': 'none'}}>
                                                        <ListItem className={classes.listFullItem} button>
                                                            <ListItemIcon>
                                                                <img src={sinistri} alt="image" width="28px"/>
                                                            </ListItemIcon>
                                                            <ListItemText primary={t(`${root}.list-claims`)}/>
                                                            <ListItemIcon className="justify-content-end">
                                                        <span
                                                            className={classes.listTextRight}>{t(`${root}.list-opened-claims`)} {person.openClaimsNr}</span>
                                                                <img src={goTo} alt="image" height="20px"/>
                                                            </ListItemIcon>
                                                        </ListItem>
                                                    </Link>
                                                    <Divider/>
                                                </List>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Fragment>
                    }
                    <AppShareComponent type={'people'} person={person}></AppShareComponent>
                </Container>
                <FsLightbox
                    toggler={toggler}
                    sources={[person.image]}
                    type="image"
                />
                <CropDialog open={openCropDialog}
                            handleAttach={attachCroppedImage}
                            handleClose={() => {
                                setOpenCropDialog(false)
                                setIsLoadingImg(false)
                            }}
                            isLoading={isLoadingImg}
                            onPendingImage={onPendingImage}
                            cropperRef={cropperRef}
                            setCropper={(data) => {
                                if(data) {
                                    setCropper(data)
                                }
                            }}/>
                <Dialog
                    fullScreen={!isDesktop}
                    fullWidth={isDesktop}
                    maxWidth={'sm'}
                    PaperProps={{
                        style: {
                            //position: "absolute",
                            bottom: isDesktop ? '5%' : '0',
                            height: isDesktop ? '90%' : '100%',
                            marginTop: '10%'
                        },
                    }}
                    open={openInvitationDialog}
                    TransitionComponent={Transition}
                    onClose={() => {
                        setOpenInvitationDialog(false)
                    }}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle id="alert-dialog-slide-title" className="dialog-title"
                                 style={{textAlign: 'center'}}>
                        <Row style={{justifyContent: 'end'}}>
                            <img src={exitIcon} className="exit-button" alt="close" onClick={() => {
                                setOpenInvitationDialog(false)
                            }} style={{float: 'inline-end'}}/>
                        </Row>
                        <Row style={{marginTop: '-12px'}}>
                            <Col>
                                <h6 className="dialog-form-title-1 text-left">{t(`${root}.add-user-title`)}</h6>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <h6 className="dialog-form-title-2 text-left">
                                    {t(`${root}.add-user-description`)}
                                </h6>
                            </Col>
                        </Row>
                    </DialogTitle>
                    <DialogContent className="no-scrollbar">
                        <Form onSubmit={handleInvitation}>
                            <Container className="dialog-main-container"
                                       style={{paddingBottom: "96px"}}>
                                <div>
                                    <Row className="mt-2">
                                        <Col>
                                            <h6 className="dialog-form-title-2">
                                                {t(`${root}.email`)}
                                            </h6>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pb-4 mb-4">
                                            <TextField
                                                onChange={handleDialogInputChange}
                                                id={`email`}
                                                name={'email'}
                                                autoComplete={'off'}
                                                value={formDialogValues.email || ""}
                                                required={true}
                                                error={!!emailDialogError}
                                                helperText={emailDialogError}
                                                fullWidth
                                                placeholder=""
                                                variant="outlined"
                                                type="email"
                                                color="primary"
                                                InputProps={{
                                                    style: {
                                                        'borderRadius': '4px',
                                                        'color': themeContext.color.neutral600
                                                    }
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Container className="position-absolute"
                                               style={
                                                   isDesktop ? {
                                                       background: 'transparent',
                                                       opacity: '1',
                                                       left: 0,
                                                       bottom: 48,
                                                       right: 0
                                                   } : {
                                                       background: 'transparent',
                                                       opacity: '1',
                                                       left: 0,
                                                       bottom: 68,
                                                       right: 0
                                                   }
                                               }>
                                        <Row style={{textAlign: 'center'}}>
                                            <Col>
                                                <Button
                                                    className={formDialogValues.email === "" ? "disable-button" : "primary-button"}
                                                    variant="outlined"
                                                    size="large"
                                                    style={{
                                                        color: themeContext.color.white,
                                                        width: '288px',
                                                        textTransform: 'none'
                                                    }}
                                                    type="submit"
                                                    disabled={formDialogValues.email === ""}>
                                                    {t(`${root}.add`)}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Container>
                        </Form>
                    </DialogContent>
                </Dialog>
                <DialogDoubleAction open={openDialog}
                                    title={t('personDetail.dialog-mail-title')}
                                    description={<div>
                                        {t('personDetail.dialog-mail-text-1')}<br/>{mail}{t('personDetail.dialog-mail-text-2')}
                                        <br/>
                                        {t('personDetail.dialog-mail-text-3')}
                                    </div>}
                                    handleClose={() => setOpenDialog(false)}
                                    handleNo={() => setOpenDialog(false)}
                                    handleYes={() => {
                                        handleRemoveMail(mail)
                                    }}
                                    deleteAction={true}
                                    buttonYesText={t('personDetail.dialog-mail-confirm')}
                                    buttonNoText={t('personDetail.dialog-mail-cancel')}/>
                <DialogDoubleAction
                    open={openDialogDoubleAction}
                    onClose={() => setOpenDialogDoubleAction(false)}
                    title={t('personDetail.delete-image')}
                    description={t('personDetail.dialog-delete-image')}
                    buttonYesText={t('personDetail.remove-image-confirm')}
                    handleYes={() => handleDeleteImage(image)}
                    buttonNoText={t('dialog.button-cancel')}
                    handleNo={() => setOpenDialogDoubleAction(false)}
                    deleteAction={true}
                />
                <DialogSmall open={openSizeExceededDialog} title={t('Maximum upload size exceeded')}
                             buttonText={t('dialog.button-ok')}
                             handleClose={() => {
                                 setOpenSizeExceededDialog(false)
                             }} description={t('The maximum file upload size is 20MB')}/>
                <DialogSmall open={openTypeNotSupportedDialog} title={t('The uploaded format is not supported')}
                             buttonText={t('dialog.button-ok')}
                             handleClose={() => {
                                 setOpenTypeNotSupportedDialog(false)
                             }} description={t('Only image formats are supported')}/>
            </Fragment>
    )
}

