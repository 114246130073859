import {useNavigate} from "react-router-dom";
import {useContext, useEffect} from "react";
import {InterceptorContext} from "./InterceptorContext.js";

export default function FetchMiddleware() {
    const originalFetch = window.fetch;
    const navigate = useNavigate()
    const whitelistApi = `${process.env.REACT_APP_BASE_URL}/management/retrieveWhitelistApi`;
    let apiList;
    const {handleTitle, handleMessage} = useContext(InterceptorContext);

    useEffect(() => {
        fetch(whitelistApi, {
            method: 'GET',
            header: {
                'Content-Type': 'application/js'
            },
        }).then((res) => {
            return res.json();
        }).then((data) => {
            apiList = data;
        });
    }, []);

    window.fetch = async function (url, options) {
        let startUrl = window.location.pathname;
        try {
            let result = await originalFetch(url, options)
            if (startUrl === window.location.pathname && !window.location.pathname.includes('login')) {
                if (result.status && result.status != 401 && result.status >= 300 && !result.url.includes('vidi')) {
                    if (!apiList.some(api => url.includes(api))) {
                        handleTitle(startUrl);
                        handleMessage(result.statusText, result.ok, result.status, result.url);
                        navigate('/error-page')
                    } else {
                        navigate('/service-error')
                    }
                }
            }
            return result
        } catch (error) {
            // Handle the error here
            return null; // or return a default response
        }
    };
    return null;
}
