import {useNavigate, useSearchParams} from "react-router-dom";
import React from "react";

export default function TelegramHook() {
    const [searchParam, setSearchParam] = useSearchParams();
    const telegramId = searchParam.get("id");
    const navigate = useNavigate();
    const url = `${process.env.REACT_APP_BASE_URL}/management/connectUserToTelegram`;
    const token = sessionStorage.getItem('token');
    React.useEffect(
        () => {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    telegramUserId: telegramId,
                }),
            }).then((response) => {
                if(response && response.ok) {
                    navigate('/dashboard');
                }
            }).catch((err) => {
                navigate('/login');
            });
        }, []
    );
    return null;
}
