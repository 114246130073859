import React, {Fragment, useEffect, useRef, useState} from 'react'
import Dialog from "@mui/material/Dialog";
import {Button, InputAdornment, MenuItem, Select, Slide, TextField} from "@mui/material";
import {makeStyles} from '@mui/styles';
import {t} from "i18next";
import DialogContent from "@mui/material/DialogContent";
import {Col, Form, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {useMediaQuery} from "react-responsive";
import themeContext from "./ThemeContext.js";
import DialogTitle from "@mui/material/DialogTitle";
import exitIcon from "../../images/exit-icon.svg";
import Chips from "./Chips.js";
import spinner from "../../images/spinner.svg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import accountExecFemale from "../../images/accountExec-female-icon.svg";
import accountExecMale from "../../images/accountExec-male-icon.svg";
import share from "../../images/external-link.svg";
import {getClientDatas, putElementFromIndexedDB} from "../service/UtilityService.js";
import Resizer from "react-image-file-resizer";
import addIcon from "../../images/add.svg";
import trashIcon from "../../images/trash-icon.svg";
import FileSaver from "file-saver";
import FsLightbox from "fslightbox-react";
import CropDialog from "./CropDialog.js";
import DialogDoubleAction from "./DialogDoubleAction.js";
import {StyledBackdrop} from "../styles/Styles.js";

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1920,
            1080,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

const useStyles = makeStyles((theme) => ({
    accountExecCard: {
        background: `${themeContext.color.mainActive} 0% 0% no-repeat padding-box !important`,
        boxShadow: `2px 3px 10px ${themeContext.color.boxShadow} !important`,
        borderRadius: "10px !important",
        opacity: "1 !important",
        height: "190px !important",
    },
    accountExecCardTextHigh: {
        color: `${themeContext.color.white} !important`,
        textAlign: 'left !important',
        lineHeight: '1 !important',
        fontWeight: '100 !important',
        fontSize: '12px !important'
    },
    accountExecCardTextMid: {
        color: `${themeContext.color.white} !important`,
        textAlign: 'left !important',
        lineHeight: '1 !important',
        fontWeight: 'bold !important',
        fontSize: '21px !important'
    },
    accountExecCardTextLow: {
        color: `${themeContext.color.white} !important`,
        textAlign: 'left !important',
        lineHeight: '1.6 !important',
        fontWeight: '600 !important',
        fontSize: '12px !important',
        textDecoration: "underline !important",
        float: 'left !important'
    },
    large: {
        width: '64px !important',
        height: '64px !important',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SupportTicketDialog = ({
                                 openNewRequestDialog, handleClose, handleSave, handleServiceError, hasChips, title
                             }) => {
    useEffect(() => {
        if (navigator.onLine) {
            getPeopleLite();
            if (hasChips) {
                getAccountExec();
            }
        }
    }, []);

    const newTicketUrl = `${process.env.REACT_APP_BASE_URL}/profile/support/new`;
    const baseTicketUrl = `${process.env.REACT_APP_BASE_URL}/profile/support`;
    const attachImagesUrl = `${process.env.REACT_APP_BASE_URL}/profile/support/attach`;
    const detachImagesUrl = `${process.env.REACT_APP_BASE_URL}/profile/support/detach`;
    const accountExecUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/accountExec`;
    const accountExecVCFUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/accountExecVcf`;
    const getPeopleLiteUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/peopleLite`;

    const classes = useStyles();
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'})
    const root = 'support';
    const chips = [
        {value: 0, label: t(`${root}.problems-with-mywide`)},
        {value: 1, label: t(`${root}.other-problems`)}
    ];
    const [tab, setTab] = useState(0);
    const defaultValues = {
        personId: "",
        title: "",
        description: "",
        deviceData: JSON.stringify(getClientDatas()),
    };
    const [formValues, setFormValues] = useState(defaultValues)
    const [isFormInvalid, setIsFormInvalid] = useState(true);
    const [isFormTouched, setIsFormTouched] = useState(false);
    const [isLoadingAttach, setIsLoadingAttach] = useState(false);
    const [openCropDialog, setOpenCropDialog] = useState(false);
    const [isLoadingImg, setIsLoadingImg] = useState(false);
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const new_ids = useRef([]);
    const [lightboxController, setLightboxController] = useState({toggler: false, slide: 1});
    const [openDoNotSaveDialog, setOpenDoNotSaveDialog] = useState(false);
    const [images, setImages] = useState([]);
    const [imagesUrl, setImagesUrl] = useState([]);
    const [tempImages, setTempImages] = useState([]);
    const [tempImagesUrl, setTempImagesUrl] = useState([]);
    const ids = useRef([]);
    const cropperRef = useRef(null);
    const [onPendingImage, setOnPendingImage] = useState("")
    const [openPhotoLimitsDialog, setOpenPhotoLimitsDialog] = useState(false);
    const [cropper, setCropper] = useState(null);
    const [activeDialog, setActiveDialog] = useState("attach");
    const [ticketId, setTicketId] = useState(undefined);
    const [documents, setDocuments] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isLoadingPeopleLite, setIsLoadingPeopleLite] = useState(false);
    const [isLoadingAccountExec, setIsLoadingAccountExec] = useState(false)
    const [phone, setPhone] = useState("");
    const [accountExec, setAccountExec] = useState([]);
    const [toggler, setToggler] = useState(false);
    const [openDeleteImageDialog, setOpenDeleteImageDialog] = useState(false);
    const [openDoNotSaveRequestDialog, setOpenDoNotSaveRequestDialog] = useState(false);
    const [imageIdToDelete, setImageIdToDelete] = useState(-1);
    const imagesMaxLength = 4;
    const titleMinLength = 4;
    const titleMaxLength = 255;
    const descriptionMinLength = 10;
    const descriptionMaxLength = 65535;
    const [uploadError, setUploadError] = useState(null);
    const [isLoadingForm, setIsLoadingForm] = useState(false)
    const [errors, setErrors] = useState({})
    const token = sessionStorage.getItem('token');
    const [peopleLite, setPeopleLite] = useState([]);

    const getPeopleLite = () => {
        setPeopleLite([])
        setIsLoadingPeopleLite(true)
        fetch(getPeopleLiteUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setPeopleLite(data)
            setIsLoadingPeopleLite(false)
        }).catch(() => {
            handleServiceError();
        });
    }

    function getAccountExec() {
        setIsLoadingAccountExec(true)
        fetch(accountExecUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.phone && data.phone.startsWith("+39")) {
                setPhone(data.phone.slice(3))
            }
            setAccountExec(data);
            setIsLoadingAccountExec(false)
            putElementFromIndexedDB('accountExec', data)
        }).catch(() => {
            handleServiceError();
        });
    }


    function handleTabs(value) {
        setTab(value);
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormValues({...formValues, [`${name}`]: value,});
        setErrors({
            ...errors,
            [`${name}`]: null
        })
        checkValidForm();
    }

    const submitForm = async (event) => {
        event.preventDefault();
        const newErrors = {};
        if (!formValues.personId) {
            newErrors.personId = t('Required field');
        }
        if (!formValues.title) {
            newErrors.title = t('Required field');
        }
        if (!formValues.description) {
            newErrors.description = t('Required field');
        }
        if (formValues.title && formValues.title.length < titleMinLength) {
            newErrors.title = t('Insert minimum') + titleMinLength + t('characters');
        }
        if (formValues.description && formValues.description.length < descriptionMinLength) {
            newErrors.description = t('Insert minimum') + descriptionMinLength + t('characters');
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        if (ticketId) {
            const response = await updateTicket();
            const data = await response.json();
            if (data.ok) {
                const completeTicketResponse = await completeTicket(ticketId);
                const complete = await completeTicketResponse.json();
                if (complete.ok) {
                    cleanForm();
                    handleSave();
                }
            } else {
                handleServiceError();
            }
        } else {
            const response = await newTicket();
            const data = await response.json();
            if (data.id) {
                setTicketId(data.id)
                const complete_response = await completeTicket(data.id);
                if (complete_response.ok) {
                    cleanForm();
                    handleSave();
                } else {
                    handleServiceError();
                }
            } else {
                handleServiceError();
            }
        }
    }

    const newTicket = () => {
        return fetch(newTicketUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formValues),
        });
    }

    const updateTicket = () => {
        let updateFormValues = {...formValues, ['ticketId']: ticketId,}
        return fetch(`${baseTicketUrl}/update`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updateFormValues),
        });
    }

    const completeTicket = (id) => {
        return fetch(`${baseTicketUrl}/${id}/complete`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
    }

    const checkValidForm = () => {
        //check if is touched
        if ((formValues.personId !== '' || formValues.personId !== 0) || formValues.title !== '' || formValues.description !== '') {
            setIsFormTouched(true);
        }
        //check if is valid
        if (formValues.personId !== '' && formValues.personId !== 0 && formValues.title !== '' && formValues.description !== '') {
            setIsFormInvalid(false);
        }
    }

    const doNotSave = (type) => {
        setOpenDoNotSaveDialog(false)
        setOpenUploadDialog(false)
        setImages(tempImages)
        setImagesUrl(tempImagesUrl)
        setUploadError(null)
        ids.current = ids.current.filter(id_to_maintain => {
            if (!new_ids.current.includes(id_to_maintain)) {
                return id_to_maintain
            }
        })
        new_ids.current = []
    }

    const cleanForm = () => {
        setTicketId(undefined)
        setFormValues(defaultValues)
        setImages([])
        setImagesUrl([])
        setTempImages([])
        setTempImagesUrl([])
        ids.current = []
        new_ids.current = []
        setErrors({})
        setIsFormTouched(false)
        setIsFormInvalid(true)

        setOpenDoNotSaveRequestDialog(false)
        handleClose();
    }

    const handleOnPendingImage = async (e) => {
        setUploadError(null)
        try {
            e.preventDefault();
            let file = e.target.files[0];
            if (file && !file.type.includes('image')) {
                throw 'File not supported';
            }
            if (file && file.size > 20000000) {
                throw 'Maximum upload size exceeded';
            }
            const imageResized = await resizeFile(file)
            setOnPendingImage(imageResized);
            setOpenCropDialog(true)
        } catch (err) {
            if (err === 'Maximum upload size exceeded') {
                setUploadError(t('File not supported') + '<br />' + t('The maximum file upload size is 20MB'))
            } else if ('File not supported') {
                setUploadError(t('The uploaded format is not supported') + '<br />' + t('Only image formats are supported'))
            }
        }
    }

    const attachImageActions = (documentType, imagesLength) => {
        if (imagesLength >= imagesMaxLength) {
            setOpenPhotoLimitsDialog(true);
        } else {
            handleChangeImage();
        }
    }

    const handleChangeImage = () => {
        const input = document.querySelector("#image-file");
        input.click();
    }

    const handleDeleteImageDialog = (id) => {
        setImageIdToDelete(id);
        setOpenDeleteImageDialog(true)
    }

    const handleAttachImages = async () => {
        if (!ticketId) {
            const response = await newTicket();
            const data = await response.json();
            if (data.id) {
                setTicketId(data.id)
            }
        }
        setTempImagesUrl(imagesUrl)
        setTempImages(images)
        setOpenUploadDialog(true)
    }

    const attachCroppedImage = () => {
        let cropImage = "#";
        if (typeof cropper !== "undefined") {
            cropImage = cropper.getCanvas().toDataURL()
        }
        if (cropImage !== "#") {
            const randomName = Math.floor((Math.random() * 100000000) + 1)
            let split1 = cropImage.split(":");
            let split2 = split1[1].split(";");
            let mimetype = split2[0];
            let base64_fullString = cropImage.split(",");
            let base64_string = base64_fullString[1];
            setIsLoadingImg(true);
            fetch(`${attachImagesUrl}`, {
                method: 'POST',
                body: JSON.stringify({
                    ticketId: ticketId,
                    name: randomName,
                    mimeType: mimetype,
                    base64: base64_string,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Access-Control-Allow-Origin': true
                },
            }).then((res) => {
                if (res.ok) {
                    //alert upload completato
                    return res.json();
                }
            }).then((res) => {
                setIsLoadingImg(false);
                setOpenCropDialog(false);
                ids.current.push(res.attachmentId)
                new_ids.current.push(res.attachmentId)
                return res.attachmentId;
            }).then((attachment) => {
                getClaimAttachment(attachment);
            }).catch(() => {
                handleServiceError();
            });
        }
    }

    const getClaimAttachment = (attachmentId) => {
        setIsLoadingData(true)
        fetch(`${baseTicketUrl}/${ticketId}/attachment/${attachmentId}/download`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.blob();
        }).then((data) => {
            let urlCreator = window.URL || window.webkitURL;
            let imageUrl = urlCreator.createObjectURL(data);
            setImages(current => [...current, {id: attachmentId, url: imageUrl}])
            setImagesUrl(current => [...current, imageUrl])
            setIsLoadingData(false)
        }).catch(() => {
            handleServiceError();
        });
    }

    function shareVCard() {
        fetch(`${accountExecVCFUrl}`, {
            method: 'GET',
            headers: {
                //'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        }).then((res) => {
            if (res) {
                return res.json();
            }
        }).then((res) => {
            fetch(res.message, {
                method: 'GET',
            }).then((res) => {
                return res.text();
            }).then((res) => {
                let file = new Blob([res], {type: "text/vcard;charset=utf-8"});
                FileSaver.saveAs(file, `${accountExec.firstName}_${accountExec.lastName}`);
            });
        }).catch(() => {
            handleServiceError();
        })
    }

    const handleDeleteImage = (attachmentId) => {
        fetch(`${detachImagesUrl}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ticketId: ticketId,
                attachmentId: attachmentId,
            }),
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.id) {
                ids.current = ids.current.filter(id => {
                    return id !== attachmentId;
                })
                setImages([])
                setImagesUrl([])
                if (ids.current.length > 0) {
                    for (let i = 0; i < ids.current.length; i++) {
                        if (ids.current[i] !== attachmentId) {
                            getClaimAttachment(ids.current[i]);
                        }
                    }
                }
            }
            setOpenDeleteImageDialog(false)
        }).catch(() => {
            handleServiceError();
        });
    }

    function openLightboxOnSlide(index) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: index
        });
    }

    const handleCloseOnPending = () => {
        if (isFormTouched) {
            setOpenDoNotSaveRequestDialog(true)
        } else {
            handleClose();
            setErrors({})
        }
    }


    return (
        <Fragment>
            <Dialog
                components={{Backdrop: StyledBackdrop}}
                onClose={() => handleCloseOnPending()}
                fullScreen={!isDesktop}
                fullWidth={isDesktop}
                maxWidth={'sm'}
                PaperProps={{
                    style: {
                        bottom: isDesktop ? '5%' : '0',
                        color: themeContext.color.neutral600,
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                        borderRadius: isDesktop ? '20px' : 'none',
                        height: isDesktop ? '90%' : '100%',
                        marginTop: '10%'
                    }
                }}
                open={openNewRequestDialog}
                TransitionComponent={Transition}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle id="alert-dialog-slide-title"
                             className="dialog-title dialog-title-div"
                             style={{textAlign: 'center'}}>
                    <Row style={{justifyContent: 'end'}}>
                        <img src={exitIcon} className="exit-button" alt="close"
                             onClick={() => handleCloseOnPending()} style={{float: 'inline-end'}}/>
                    </Row>
                    <Row style={{marginTop: '-12px'}}>
                        <Col className="col">
                            <h6 className="dialog-form-title-1 float-left">{title}</h6>
                        </Col>
                    </Row>
                    {hasChips && <div style={{marginTop: 19.5}}>
                        <Chips chips={chips} selected={tab}
                               onChange={(value) => {
                                   handleTabs(value)
                               }}/>
                    </div>}
                </DialogTitle>
                <DialogContent className="no-scrollbar">
                    <Container className="dialog-main-container px-0 pt-2"
                               style={{paddingBottom: "96px"}}>
                        {
                            (isLoadingForm || isLoadingAccountExec) &&
                            <Row style={{'height': '200px'}}
                                 className="background-loading-spinner">
                            </Row>
                        }
                        {
                            !(isLoadingForm || isLoadingAccountExec) && tab === 0 &&
                            <Form onSubmit={submitForm} style={{marginBottom: '60px'}}>
                                <div className={"p-0 position-relative d-flex flex-column"}
                                     style={{gap: 16, marginBottom: '60px'}}>
                                    <div className={"p-0 position-relative d-flex flex-column"}
                                         style={{gap: 12}}>
                                            <span className="dialog-parameter text-capitalize">
                                            {t(`${root}.policy-holder-select`)}
                                            </span>
                                        <Select
                                            disabled={isLoadingPeopleLite}
                                            autoComplete={'off'}
                                            error={!!errors.personId}
                                            //helperText={errors.personId}
                                            variant={'outlined'}
                                            labelId="personId-label"
                                            id="personId"
                                            name="personId"
                                            value={formValues.personId}
                                            onChange={handleInputChange}
                                            fullWidth
                                            endAdornment={isLoadingPeopleLite &&
                                                <InputAdornment position="end" style={{marginRight: 10}}>
                                                    <img src={spinner} className="spinner-img"
                                                         alt="spinner"/>
                                                </InputAdornment>
                                            }
                                            style={{
                                                'backgroundColor': themeContext.color.white,
                                                'textAlign': 'left',
                                                'borderRadius': '8px',
                                            }}
                                            SelectDisplayProps={{
                                                style: {
                                                    'textAlign': 'left',
                                                    'color': themeContext.color.neutral600,
                                                    'width': '100%',
                                                }
                                            }}>
                                            {
                                                peopleLite.map((person, index) => (
                                                    <MenuItem key={index + 1}
                                                              value={person.id.toString()}>
                                                        {person.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                    <div className={"p-0 position-relative d-flex flex-column"}
                                         style={{gap: 12}}>
                                            <span className="dialog-parameter text-capitalize">
                                            {t(`${root}.title-input`)}
                                            </span>
                                        <div className={"p-0 position-relative d-flex flex-column"}
                                             style={{gap: 4}}>
                                            <div>
                                                <TextField
                                                    error={!!errors.title}
                                                    helperText={errors.title}
                                                    onChange={handleInputChange}
                                                    id={`title`}
                                                    name={`title`}
                                                    value={formValues.title}
                                                    fullWidth
                                                    placeholder=""
                                                    variant="outlined"
                                                    color="primary"
                                                    InputProps={{
                                                        inputProps: {
                                                            min: titleMinLength,
                                                            maxLength: titleMaxLength
                                                        },
                                                        style: {
                                                            'borderRadius': '8px',
                                                            'color': themeContext.color.neutral600
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className={'d-flex justify-content-end'}>
                                                    <span>
                                                        {formValues.title.length} / {titleMaxLength}
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"p-0 position-relative d-flex flex-column"}
                                         style={{gap: 12}}>
                                            <span className="dialog-parameter text-capitalize">
                                                    {t(`${root}.description-input`)}
                                            </span>
                                        <TextField
                                            error={!!errors.description}
                                            helperText={errors.description}
                                            multiline
                                            minRows={5}
                                            onChange={handleInputChange}
                                            id={`description`}
                                            name={`description`}
                                            autoComplete={'off'}
                                            fullWidth
                                            placeholder=""
                                            variant="outlined"
                                            value={formValues.description}
                                            color="primary"
                                            InputProps={{
                                                inputProps: {min: descriptionMinLength, max: descriptionMaxLength},
                                                style: {
                                                    'borderRadius': '8px',
                                                    'color': themeContext.color.neutral600
                                                }
                                            }}
                                        />
                                    </div>
                                    <Button name="attach"
                                            disabled={isFormInvalid}
                                            type={"button"}
                                            onClick={handleAttachImages}
                                            style={{height: 40}}
                                            className={isFormInvalid ? "btn-custom-disable-2 cursor-pointer text-transform-none" : "btn-primary-custom cursor-pointer text-transform-none"}>
                                        {
                                            isLoadingAttach &&
                                            <img src={spinner} className="spinner-img"
                                                 alt="spinner"/>
                                        }
                                        {
                                            !isLoadingAttach && t(`${root}.attach-images`)
                                        }
                                    </Button>
                                    <div className={'d-flex flex-row'} style={{gap: 24}}>
                                        {
                                            isDesktop && imagesUrl.map((item, index) => (
                                                <div key={index}>
                                                    {
                                                        index <= 2 &&
                                                        <Fragment>
                                                            <div className="div-grid-thumb">
                                                                <img className={'image-grid'} src={item}
                                                                     onClick={handleAttachImages}/>
                                                            </div>
                                                        </Fragment>
                                                    }
                                                    {
                                                        index === 3 &&
                                                        <Fragment>
                                                            <div className="div-grid-thumb">
                                                                <img className={'image-grid'} style={{opacity: '0.5'}}
                                                                     src={item}
                                                                     onClick={handleAttachImages}/>
                                                                <div style={{
                                                                    position: 'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform: 'translate(-50%, -50%)',
                                                                    fontWeight: '600',
                                                                    fontSize: '32px',
                                                                    color: themeContext.color.neutral600
                                                                }}
                                                                     onClick={handleAttachImages}>
                                                                    +{imagesUrl.length - 3}</div>
                                                            </div>
                                                        </Fragment>
                                                    }
                                                </div>
                                            ))
                                        }
                                        {
                                            !isDesktop && imagesUrl.map((item, index) => (
                                                <div key={index}>
                                                    {
                                                        index <= 1 &&
                                                        <Fragment>
                                                            <div className="div-grid-thumb">
                                                                <img className={'image-grid'} src={item}
                                                                     onClick={handleAttachImages}/>
                                                            </div>
                                                        </Fragment>
                                                    }
                                                    {
                                                        index === 2 &&
                                                        <Fragment>
                                                            <div className="div-grid-thumb">
                                                                <img className={'image-grid'} style={{opacity: '0.5'}}
                                                                     src={item}
                                                                     onClick={handleAttachImages}/>
                                                                <div style={{
                                                                    position: 'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform: 'translate(-50%, -50%)',
                                                                    fontWeight: '600',
                                                                    fontSize: '32px',
                                                                    color: themeContext.color.neutral600
                                                                }}
                                                                     onClick={handleAttachImages}>
                                                                    +{imagesUrl.length - 2}</div>
                                                            </div>
                                                        </Fragment>
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <span className="dialog-parameter text-center">
                                            {t(`${root}.attach-images-info`)}
                                        </span>
                                </div>
                                <Container className="position-absolute linear-gradient-white"
                                           style={
                                               isDesktop ? {
                                                   opacity: '1',
                                                   left: 0,
                                                   bottom: 0,
                                                   right: 0,
                                                   paddingBottom: 32,
                                                   display: "flex",
                                                   flexDirection: "column",
                                                   gap: 24
                                               } : {
                                                   opacity: '1',
                                                   left: 0,
                                                   bottom: 0,
                                                   paddingBottom: 68,
                                                   right: 0,
                                                   display: "flex",
                                                   flexDirection: "column",
                                                   gap: 24
                                               }
                                           }>
                                    <Row style={{textAlign: 'center'}}>
                                        <Col>
                                            <Button
                                                className={"close-button"}
                                                variant="outlined"
                                                size="large"
                                                style={{
                                                    color: themeContext.color.white,
                                                    width: '288px',
                                                    textTransform: 'none'
                                                }}
                                                type="submit"
                                            >
                                                {isLoadingForm ?
                                                    <img src={spinner} className="spinner-img" alt="spinner"/> :
                                                    t(`${root}.send-request`)}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </Form>
                        }
                        {
                            !(isLoadingForm || isLoadingAccountExec) && tab === 1 &&
                            <div className={"p-0 position-relative d-flex flex-column"}
                                 style={{gap: 16, marginBottom: '60px'}}>
                                <Card className={classes.accountExecCard}>
                                    <CardContent>
                                        <Row>
                                            <Col className="col-3">
                                                {
                                                    accountExec.photoUrl === null ?
                                                        <Avatar alt="avatar"
                                                                src={accountExec.gender === 'F' ? accountExecFemale : accountExecMale}
                                                                className={classes.large}
                                                        />
                                                        :
                                                        <Avatar alt="avatar"
                                                                src={accountExec.photoUrl}
                                                                className={classes.large}
                                                                onClick={() => setToggler(!toggler)}
                                                        />
                                                }
                                            </Col>
                                            <Col className="col-7">
                                                <Row className="mt-2">
                                                    <Col>
                                                        <h6 className={classes.accountExecCardTextHigh}>{t('contacts.card-title')}</h6>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col>
                                                        <h3 className={classes.accountExecCardTextMid}>{accountExec.firstName}</h3>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h3 className={classes.accountExecCardTextMid}>{accountExec.lastName}</h3>
                                                    </Col>
                                                </Row>
                                                {accountExec.phone !== "" && accountExec.phone !== null && phone !== "" &&
                                                    <Row className="mt-2 float-left w-100">
                                                        <Col>
                                                            <a id="accountExec-phone"
                                                               href={`tel:${accountExec.phone}`}
                                                               className={classes.accountExecCardTextLow}>+39 {phone}</a>
                                                        </Col>
                                                    </Row>
                                                }
                                                {accountExec.phone !== "" && accountExec.phone !== null && phone === "" &&
                                                    <Row className="mt-2 float-left w-100">
                                                        <Col>
                                                            <a id="accountExec-phone"
                                                               href={`tel:${accountExec.phone}`}
                                                               className={classes.accountExecCardTextLow}>{accountExec.phone}</a>
                                                        </Col>
                                                    </Row>
                                                }
                                                <Row className="mt-2 float-left w-100">
                                                    <Col>
                                                        <a id="accountExec-email"
                                                           href={`mailto:${accountExec.email}`}
                                                           className={classes.accountExecCardTextLow}>{accountExec.email}</a>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="col-2">
                                                <img src={share} alt="external-link" height="15px"
                                                     className="float-right"
                                                     onClick={shareVCard}/>
                                            </Col>
                                        </Row>
                                    </CardContent>
                                </Card>
                                <FsLightbox
                                    toggler={toggler}
                                    sources={[accountExec.photoUrl]}
                                    type="image"
                                />
                                <span className="dialog-parameter text-center">
                                            {t(`${root}.other-problems-info`)}
                                    </span>
                            </div>
                        }
                    </Container>
                </DialogContent>
            </Dialog>


            <Dialog
                onClose={() => {
                    if (new_ids.current.length > 0) {
                        setOpenDoNotSaveDialog(true)
                    } else {
                        setOpenUploadDialog(false)
                        setUploadError(null)
                    }
                }}
                fullScreen={!isDesktop}
                fullWidth={isDesktop}
                maxWidth={'sm'}
                PaperProps={{
                    style: {
                        bottom: isDesktop ? '5%' : '0',
                        color: themeContext.color.neutral600,
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                        borderRadius: isDesktop ? '20px' : 'none',
                        height: isDesktop ? '89%' : '100%',
                        marginTop: isDesktop ? '11%' : '20%'
                    }
                }}
                open={openUploadDialog}
                TransitionComponent={Transition}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle id="alert-dialog-slide-title" className="dialog-title"
                             style={{textAlign: 'center'}}>
                    <Row style={{justifyContent: 'end'}}>
                        <img src={exitIcon} className="exit-button" alt="close"
                             onClick={() => {
                                 if (new_ids.current.length > 0) {
                                     setOpenDoNotSaveDialog(true)
                                 } else {
                                     setOpenUploadDialog(false)
                                     setUploadError(null)
                                 }
                             }} style={{float: 'inline-end'}}/>
                    </Row>
                    <Row style={{marginTop: '-12px'}}>
                        <Col className="col">
                            <h6 className="dialog-form-title-1 float-left text-left">{t('attachImagesToClaimForm.title')}</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className="dialog-form-title-2 float-left text-left">
                                {t('carClaimForm.upload-description')}
                            </h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className="dialog-form-title-2 float-left text-left">
                                {t('support.attachments-max-size')}
                            </h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className="dialog-form-title-2 float-left text-left">
                                {imagesUrl.length} / {imagesMaxLength} {t('ticketDetail.attachments')}
                            </h6>
                        </Col>
                    </Row>
                </DialogTitle>
                <DialogContent className="no-scrollbar">
                    <div className="dialog-main-container" style={{paddingBottom: '120px'}}>
                        <div style={{display: "flex", gap: "24px"}}
                             className={isDesktop ? 'flex-wrap justify-content-center mt-4' : 'flex-wrap justify-content-between mt-4'}>
                            <div className={imagesUrl.length < 4 ? 'add-active-icon add-icon' : 'add-icon'}>
                                <img key={0} src={addIcon}
                                     alt={`image-0`} width={'100%'} height={'100%'}
                                     onClick={() => {
                                         attachImageActions(documents.type, imagesUrl.length)
                                     }}
                                />
                                <img hidden className="trash-button" src={trashIcon} alt="trash-icon"/>
                                <input hidden id="image-file" type="file" onChange={handleOnPendingImage}
                                       accept="image/*"/>
                            </div>
                            {
                                images.map((item, index) => (
                                    <div key={index} className={'div-grid'}>
                                        <img src={item.url} alt={`image-${index}`} className={'image-grid'}
                                             onClick={() => {
                                                 openLightboxOnSlide(index);
                                             }}
                                        />
                                        <img className="trash-button" src={trashIcon} alt="trash-icon"
                                             onClick={() => handleDeleteImageDialog(item.id)}/>
                                    </div>
                                ))
                            }
                            <FsLightbox
                                toggler={lightboxController.toggler}
                                sources={imagesUrl}
                                type="image"
                                sourceIndex={lightboxController.slide}
                                key={imagesUrl.length}
                            />
                            {
                                uploadError &&
                                <Row className="ml-4 mr-4 mt-4">
                                    <Col className="mt-4 text-center dialog-form-title-3 red">
                                        <span dangerouslySetInnerHTML={{__html: uploadError}}/>
                                    </Col>
                                </Row>
                            }
                        </div>
                        <Container className="position-absolute"
                                   style={
                                       isDesktop ? {
                                           background: 'transparent',
                                           opacity: '1',
                                           left: 0,
                                           bottom: 48,
                                           right: 0
                                       } : {
                                           background: 'transparent',
                                           opacity: '1',
                                           left: 0,
                                           bottom: 68,
                                           right: 0
                                       }
                                   }>
                            <Row style={{textAlign: 'center'}}>
                                <Col>
                                    <Button className="close-button boxShadow"
                                            variant="outlined"
                                            size="large"
                                            style={{
                                                color: themeContext.color.white,
                                                width: '288px',
                                                textTransform: 'none'
                                            }}
                                            type="submit"
                                            disabled={false}
                                            onClick={() => {
                                                new_ids.current = []
                                                setOpenUploadDialog(false)
                                            }}
                                    >{t('regulations.save-pdf-button')}</Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </DialogContent>
            </Dialog>
            <CropDialog open={openCropDialog}
                        handleAttach={attachCroppedImage}
                        handleClose={() => {
                            setOpenCropDialog(false)
                            setIsLoadingImg(false)
                        }}
                        isLoading={isLoadingImg}
                        onPendingImage={onPendingImage}
                        cropperRef={cropperRef}
                        setCropper={(data) => {
                            if (data) {
                                setCropper(data)
                            }
                        }}/>
            <DialogDoubleAction open={openDoNotSaveDialog}
                                title={t('genericClaimForm.do-not-save-title')}
                                description={t('genericClaimForm.do-not-save-description')}
                                handleClose={() => setOpenDoNotSaveDialog(false)}
                                handleNo={() => setOpenDoNotSaveDialog(false)}
                                deleteAction={true}
                                handleYes={() => doNotSave(activeDialog)}
                                buttonYesText={t('genericClaimForm.do-not-save-confirm-button')}
                                buttonNoText={t('genericClaimForm.do-not-save-refuse-button')}/>
            <DialogDoubleAction open={openDoNotSaveRequestDialog}
                                title={t('genericClaimForm.do-not-save-title')}
                                description={t('genericClaimForm.do-not-save-description')}
                                handleClose={() => setOpenDoNotSaveRequestDialog(false)}
                                handleNo={() => setOpenDoNotSaveRequestDialog(false)}
                                deleteAction={true}
                                handleYes={() => cleanForm(activeDialog)}
                                buttonYesText={t('genericClaimForm.do-not-save-confirm-button')}
                                buttonNoText={t('genericClaimForm.do-not-save-refuse-button')}/>
            <DialogDoubleAction open={openDeleteImageDialog}
                                title={t('genericClaimForm.irreversible-action-title')}
                                description={t('genericClaimForm.irreversible-action-description')}
                                handleClose={() => setOpenDeleteImageDialog(false)}
                                handleNo={() => setOpenDeleteImageDialog(false)}
                                deleteAction={true}
                                handleYes={() => handleDeleteImage(imageIdToDelete)}
                                buttonYesText={t('genericClaimForm.irreversible-action-done')}
                                buttonNoText={t('genericClaimForm.irreversible-action-undone')}/>
        </Fragment>
    )
}
export default SupportTicketDialog;