import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../images/back-icon.svg";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {useTranslation} from 'react-i18next'
import Webcam from "react-webcam";
import {Button} from "@mui/material";
import Resizer from "react-image-file-resizer";
import identityConfirmedPh from "../../images/identityConfirmed-ph.svg";
import identityNotConfirmedPh from "../../images/identityNotConfirmed-ph.svg";
import themeContext from "../components/ThemeContext.js";
import ErrorPage from "../pages/ErrorPage.js";

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1920,
            1080,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "file"
        );
    });

const useStyles = makeStyles((theme) => ({
    rootList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: "0 0 0 0",
    },
    list: {
        paddingTop: '0',
        paddingBottom: '30px',
    },
    listItem: {
        color: themeContext.color.neutral400,
        padding: '16px 0 0 0',
        fontSize: '18px'
    },
    listFullItem: {
        color: themeContext.color.neutral400,
        paddingTop: '16px',
        paddingBottom: '16px',
        fontSize: '18px'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    primary: {
        color: themeContext.color.main,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
};

export default function TestCameraRecognition() {
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const classes = useStyles();
    const {
        t,
        i18n
    } = useTranslation();
    const [images, setImages] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [screenshot, setScreenshot] = useState(null)
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const webcamRef = React.useRef(null);
    const params = useParams();
    const documentGroupId = params.documentGroupId;
    const getGroupDocumentByIdUrl = `${process.env.REACT_APP_BASE_URL}/media/personal/group`;
    const vidiIdentifyUrl = `https://viidi.herokuapp.com/api/v1.0/viidi/identify`;
    const [identityConfirmed, setIdentityConfirmed] = useState(false);
    const [identityNotConfirmed, setIdentityNotConfirmed] = useState(0);

    const capture = React.useCallback(
        async () => {
            let base64_fullString = webcamRef.current.getScreenshot().split(",");
            setScreenshot(base64_fullString[1]);
        },
        [webcamRef]
    );

    useEffect(() => {
        if (navigator.onLine) {
            getDocumentGroupById();
            let runCount = 0;

            function timerMethod() {
                runCount++;
                if ((runCount > 19) || identityNotConfirmed === 11 || identityConfirmed) clearInterval(timerId);
                if (webcamRef.current) {
                    let base64_fullString = webcamRef.current.getScreenshot().split(",");
                    setScreenshot(base64_fullString[1]);
                    //vidiIdentify(base64_fullString[1]);
                }
            }

            let timerId = setInterval(timerMethod, 2000);
        }
    }, []);

    const restartTimer = () => {
        setIdentityNotConfirmed(current => current + 1)
    }

    function turnBack() {
        return navigate(-1);
    }

    const base64toBlob = (b64Data, contentType = 'image/jpeg', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, {type: contentType});
    }

    const getDocumentGroupById = () => {
        //setIsLoadingData(true)
        setImages([])
        fetch(`${getGroupDocumentByIdUrl}/${documentGroupId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            //console.log('result', data, data.documents[0])
            let lengthDocs = Object.keys(data.documents).length;
            setDocuments(data)
            if (lengthDocs !== 0) {
                for (let i = 0; i < lengthDocs; i++) {
                    setImages(current => [...current, data.documents[i]])
                }
            }
            //setIsLoadingData(false)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    function reload() {
        window.location.reload();
    }

    const vidiIdentify = (frame) => {
        const formData = new FormData();
        formData.append("req_frame", base64toBlob(frame))
        formData.append("ref_img", base64toBlob(frame))
        fetch(vidiIdentifyUrl, {
            method: 'POST',
            headers: {
                'token': process.env.REACT_APP_VIDI_AUTH_TOKEN,
            },
            body: formData,
        }).then((res) => {
            if (res.status === 200) {
                setIdentityConfirmed(true)
            } else {
                setIdentityNotConfirmed(current => current + 1)
            }
        }).catch(() => {
            setErrorPage(true)
        });
    }

    return (
        errorPage ?
            <ErrorPage/> :
            <Container className="mt-0 full-height linear-gradient">
                <div style={{'opacity': '1'}}>
                    <Row className="nav-top container border-bottom" style={{
                        'paddingBottom': '20px',
                        'paddingLeft': '0',
                        'paddingRight': '0',
                    }}>
                        <Col className="col-1 float-left">
                            <img src={back} onClick={turnBack} className="nav-top-logo float-left cursor-pointer"
                                 height="27px"
                                 alt="back"/>
                        </Col>
                        <Col className="col-10">
                            <h6 className="header-title ellipsis-1-v2">{t('cameraRecognition.title')}</h6>
                        </Col>

                    </Row>
                    <Container className="top-of-the-preferences"></Container>
                    {
                        !identityConfirmed && (identityNotConfirmed !== 3 && identityNotConfirmed !== 7 && identityNotConfirmed !== 11) &&
                        <Container className="col-list">
                            <Row>
                                <Col>
                                    <Webcam
                                        style={{
                                            // filter:'blur(30px)'
                                        }}
                                        className="webcam"
                                        audio={false}
                                        height={720}
                                        ref={webcamRef}
                                        screenshotFormat="image/jpeg"
                                        width={1280}
                                        videoConstraints={videoConstraints}
                                        mirrored={true}
                                    />
                                </Col>
                            </Row>
                            {
                                /*
                                <Row>
                                <Col>
                                    <Button onClick={vidiIdentify}>vidi identify</Button>
                                    <Button onClick={capture}>Capture photo</Button>
                                </Col>
                            </Row>
                                 */
                            }
                            <Row style={{
                                margin: '-240px auto'
                            }}>
                                <Col>
                                    <Row>
                                        <Col>
                                            {
                                                window.location.href.includes('logged') &&
                                                <h6 style={{
                                                    textAlign: 'center',
                                                    font: 'normal normal bold 16px/20px Inter',
                                                    letterSpacing: '0px',
                                                    color: themeContext.color.white,
                                                    opacity: '1',
                                                }}>{t('cameraRecognition.document-title')}</h6>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {
                                                window.location.href.includes('logged') &&
                                                <img src={images[0]} width="200px" alt='document'/>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    }
                    {
                        identityConfirmed &&
                        <Row className="pr-0 pl-0">
                            <Col className="col-list">
                                <Container>
                                    <Row style={{'marginTop': '43.5px'}}>
                                        <Col className="pl-5 pr-5">
                        <span style={{
                            'fontSize': '16px',
                            'color': themeContext.color.neutral600,
                            'fontWeight': 'bold'
                        }}>{t('uploadDocumentToVerify.subtitle')}</span>
                                        </Col>
                                    </Row>
                                    <Row style={{
                                        'marginTop': '12px',
                                        'marginBottom': '44.5px'
                                    }}>
                                        <Col>
                        <span style={{
                            'fontSize': '14px',
                            'color': themeContext.color.neutral600,
                            'fontWeight': 'normal'
                        }}>{t('uploadDocumentToVerify.description')}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <img src={identityConfirmedPh} alt="document-placeholder"
                                                 width="100%"
                                                 style={{'maxHeight': '500px'}}/>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    }
                    {
                        !identityConfirmed && (identityNotConfirmed === 3 || identityNotConfirmed === 7) &&
                        <Row className="pr-0 pl-0">
                            <Col className="col-list">
                                <Container>
                                    <Row style={{'marginTop': '43.5px'}}>
                                        <Col className="pl-5 pr-5">
                        <span style={{
                            'fontSize': '16px',
                            'color': themeContext.color.neutral600,
                            'fontWeight': 'bold'
                        }}>{t('uploadDocumentToVerify.subtitle')}</span>
                                        </Col>
                                    </Row>
                                    <Row style={{
                                        'marginTop': '12px',
                                        'marginBottom': '44.5px'
                                    }}>
                                        <Col>
                        <span style={{
                            'fontSize': '14px',
                            'color': themeContext.color.neutral600,
                            'fontWeight': 'normal'
                        }}>{t('uploadDocumentToVerify.description')}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <img src={identityNotConfirmedPh} alt="document-placeholder"
                                                 width="100%"
                                                 style={{'maxHeight': '500px'}}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button className="btn-custom text-uppercase"
                                                    variant="outlined"
                                                    size="large"
                                                    style={{'color': themeContext.color.white}} type="submit"
                                                    onClick={restartTimer}
                                            >{t('cameraRecognition.verify-not-ok-button')}</Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    }
                    {
                        !identityConfirmed && identityNotConfirmed === 11 &&
                        <Row className="pr-0 pl-0">
                            <Col className="col-list">
                                <Container>
                                    <Row style={{'marginTop': '43.5px'}}>
                                        <Col className="pl-5 pr-5">
                        <span style={{
                            'fontSize': '16px',
                            'color': themeContext.color.neutral600,
                            'fontWeight': 'bold'
                        }}>{t('uploadDocumentToVerify.subtitle')}</span>
                                        </Col>
                                    </Row>
                                    <Row style={{
                                        'marginTop': '12px',
                                        'marginBottom': '44.5px'
                                    }}>
                                        <Col>
                        <span style={{
                            'fontSize': '14px',
                            'color': themeContext.color.neutral600,
                            'fontWeight': 'normal'
                        }}>{t('uploadDocumentToVerify.description')}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <img src={identityNotConfirmedPh} alt="document-placeholder"
                                                 width="100%"
                                                 style={{'maxHeight': '500px'}}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button className="btn-custom text-uppercase"
                                                    variant="outlined"
                                                    size="large"
                                                    style={{'color': themeContext.color.white}} type="submit"
                                            >{t('cameraRecognition.verify-not-ok-final-button')}</Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    }
                </div>
            </Container>
    )
}