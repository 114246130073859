import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../images/back-icon.svg";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import {makeStyles} from "@mui/styles";
import {Button, Radio, Slide} from "@mui/material";
import {useTranslation} from 'react-i18next'
import nexiLogo from "../../images/nexi-logo.svg";
import americanExpressLogo from "../../images/americanExpress-logo.svg";
import mastercardLogo from "../../images/mastercard-logo.svg";
import visaLogo from "../../images/visa-logo.svg";
import slimPayLogo from "../../images/slimpay-logo.svg";
import creditCardIcon from "../../images/credit-card-icon.svg";
import bankIcon from "../../images/bank-icon.svg";
import infoPaymentIcon from "../../images/payment-info-icon.svg";
import themeContext from "../components/ThemeContext.js";
import ErrorPage from "../pages/ErrorPage.js";

const useStyles = makeStyles((theme) => ({
    rootList: {
        width: '100% !important',
        backgroundColor: `${theme.palette.background.paper} !important`,
        padding: "0 0 0 0 !important",
    },
    listItem: {
        color: `${themeContext.color.neutral400} !important`,
        paddingTop: '15px !important',
        paddingBottom: '15px !important',
        border: `${themeContext.spacing.borders.border1} ${themeContext.color.neutral100} !important`,
        borderRadius: "4px !important",
    },
    listTextSelected: {
        color: `${themeContext.color.neutral600} !important`,
        fontSize: '16px !important',
        fontWeight: '600 !important'
    },
    listText: {
        color: `${themeContext.color.neutral400} !important`,
        fontSize: '16px !important',
        fontWeight: '600 !important'
    },
    subTexts: {
        fontSize: '12px !important',
        fontWeight: 'normal !important',
        color: `${themeContext.color.neutral400} !important`,
        lineHeight: '0.5px !important'
    },
    infoText: {
        fontSize: '12px !important',
        fontWeight: 'normal !important',
        color: `${themeContext.color.neutral400} !important`,
    },
}));

export default function PaymentsMethod() {
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const classes = useStyles();
    const [selectedRadio, setSelectedRadio] = useState('nexi');
    const { t, i18n } = useTranslation();
    const userInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/info`;
    const getPeoplePaymentNotificationUrl = `${process.env.REACT_APP_BASE_URL}/payments/notifications/people?payableOnly=true`;
    const generatePaymentUrlUrl = `${process.env.REACT_APP_BASE_URL}/payments/notifications/getPaymentUrl`;
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [total, setTotal] = useState('');
    const [reason, setReason] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const notificationIds = query.get('notificationIds');
    const personId = query.get('personId');
    const params = useParams();
    const notificationId = params.notificationId;
    let notificationArray = [];
    if (notificationIds) {
        notificationArray = notificationIds.split(',');
    } else {
        notificationArray[0] = notificationId
    }
    const [url, setUrl] = useState("")

    useEffect(() => {
        if (navigator.onLine) {
            getPeoplePaymentNotification();
        }
    }, []);

    const getPeoplePaymentNotification = () => {
        fetch(`${getPeoplePaymentNotificationUrl}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            data.map((value, index) => {
                if (parseInt(notificationArray[0]) === value.id) {
                    setTotal(value.requestedPremium)
                    setReason(value.nr)
                    sessionStorage.setItem('reason', value.nr)
                    sessionStorage.setItem('total', value.requestedPremium)
                }
            })
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const navigateToCheckout = () => {
        if (window.location.href.includes('summary')) {
            navigate(`/payments/${notificationId}/summary/payment-method/checkout?paymentType=${selectedRadio}&notificationId=${notificationArray[0]}&personId=${personId}`)
        } else {
            navigate(`/payments/payment-method/checkout?paymentType=${selectedRadio}&notificationId=${notificationArray[0]}&personId=${personId}`)
        }
    };

    const handleClose = () => {
        setOpenDialog(false);
        if (selectedRadio !== 'bankTransfer') {
            navigate('/payments')
        }
    };

    function turnBack() {
        return navigate(-1);
    }

    function handleRadio(event) {
        setSelectedRadio(event.target.value)
    }

    function handleListItem(method) {
        setSelectedRadio(method)
    }

    function reload() {
        window.location.reload();
    }

    return (
        errorPage ?
            <ErrorPage/> :
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Row className="container nav-top" style={{
                    'backgroundColor': themeContext.color.white,
                    'paddingTop': '19.5px',
                    'paddingBottom': '20px',
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col className="col-2 float-left">
                        <img src={back} onClick={turnBack} className="nav-top-logo float-left cursor-pointer"
                             height="27px"
                             alt="back"/>
                    </Col>
                    <Col className="col-8">
                        <h6 className="header-title ellipsis-1-v2">{t('paymentsMethod.title')}</h6>
                    </Col>
                    <Col className="col-2">
                    </Col>
                </Row>
                <Container className="top-of-the-language"></Container>
                <Row style={{
                    'marginTop': '5px',
                    'paddingLeft': '0',
                    'paddingRight': '0px',
                }}>
                    <Col>
                        <div className={classes.rootList}>
                            <List component="nav" aria-label="main mailbox folders">
                                <ListItem className={classes.listItem} button onClick={() => handleListItem('nexi')}>
                                    <Radio
                                        checked={selectedRadio === 'nexi'}
                                        onChange={handleRadio}
                                        value="nexi"
                                        name="radio-buttons"
                                        inputProps={{'aria-label': 'nexi'}}
                                        className="justify-content-end"
                                        color="primary"
                                        size="small"
                                    />
                                    <img src={creditCardIcon} style={{'marginRight': '5px'}} alt="credit-card-icon"/>
                                    <span
                                        className={selectedRadio === 'nexi' ? classes.listTextSelected : classes.listText}>
                                        {t('paymentsMethod.nexi-title')}
                                    </span>
                                </ListItem>
                            </List>
                        </div>
                        <Row>
                            <Col style={{'textAlign': 'left'}}>
                                <span className={classes.subTexts}>{t('paymentsMethod.nexi-subtitle')}</span>
                                <img src={nexiLogo} alt="nexi-logo" height={"10px"} style={{'paddingLeft': '5px'}}/>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col className="col-8" style={{'textAlign': 'left'}}>
                                <span className={classes.subTexts}>{t('paymentsMethod.nexi-description')}</span>
                            </Col>
                            <Col className="col-4" style={{'textAlign': 'right'}}>
                                <img src={americanExpressLogo} alt="americanExpress-logo" height={"16px"}
                                     style={{'paddingLeft': '2px'}}/>
                                <img src={mastercardLogo} alt="mastercard-logo" height={"16px"}
                                     style={{'paddingLeft': '2px'}}/>
                                <img src={visaLogo} alt="visa-logo" height={"16px"} style={{'paddingLeft': '2px'}}/>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col className="col-auto">
                                <img src={infoPaymentIcon} alt="info-payment-icon"/>
                            </Col>
                            <Col style={{'textAlign': 'left'}}>
                                <span className={classes.infoText}>{t('paymentsMethod.nexi-info')}</span>
                            </Col>
                        </Row>
                        <div className={classes.rootList}>
                            <List component="nav" aria-label="main mailbox folders">
                                <ListItem className={classes.listItem} button onClick={() => handleListItem('sdd')}>
                                    <Radio
                                        checked={selectedRadio === 'sdd'}
                                        onChange={handleRadio}
                                        value="sdd"
                                        name="radio-buttons"
                                        inputProps={{'aria-label': 'sdd'}}
                                        className="justify-content-end"
                                        color="primary"
                                        size="small"
                                    />
                                    <img src={bankIcon} style={{'marginRight': '5px'}} alt="bank-icon"/>
                                    <span
                                        className={selectedRadio === 'sdd' ? classes.listTextSelected : classes.listText}>
                                        {t('paymentsMethod.slimPay-title')}
                                    </span>
                                </ListItem>
                            </List>
                        </div>
                        <Row>
                            <Col style={{'textAlign': 'left'}}>
                                <span className={classes.subTexts}>{t('paymentsMethod.slimPay-subtitle')}</span>
                                <img src={slimPayLogo} alt="slimpay-logo" height={"60px"}/>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col style={{'textAlign': 'left'}}>
                                <span className={classes.subTexts}>{t('paymentsMethod.slimPay-description')}</span>
                            </Col>
                        </Row>
                        <Divider/>
                        <div className={classes.rootList}>
                            <List component="nav" aria-label="main mailbox folders">
                                <ListItem className={classes.listItem} button
                                          onClick={() => handleListItem('bankTransfer')}>
                                    <Radio
                                        checked={selectedRadio === 'bankTransfer'}
                                        onChange={handleRadio}
                                        value="bankTransfer"
                                        name="radio-buttons"
                                        inputProps={{'aria-label': 'bankTransfer'}}
                                        className="justify-content-end"
                                        color="primary"
                                        size="small"
                                    />
                                    <span
                                        className={selectedRadio === 'bankTransfer' ? classes.listTextSelected : classes.listText}>
                                        {t('paymentsMethod.bankTransfer-title')}
                                    </span>
                                </ListItem>
                            </List>
                        </div>
                        <Row>
                            <Col style={{'textAlign': 'left'}}>
                                <span className={classes.subTexts}>{t('paymentsMethod.bankTransfer-description')}</span>
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-4">
                            <Col>
                                <Button className="btn-custom-3 text-uppercase"
                                        variant="outlined"
                                        size="large"
                                        type="submit"
                                        style={{
                                            'color': themeContext.color.white,
                                            'width': '201px'
                                        }} onClick={navigateToCheckout}
                                >
                                    {selectedRadio === 'nexi' && <span>
                                        {t('paymentsMethod.nexi-button')} {total}
                                    </span>
                                    }
                                    {selectedRadio === 'sdd' && <span>
                                        {t('paymentsMethod.slimpay-button')}
                                    </span>
                                    }
                                    {selectedRadio === 'bankTransfer' && <span>
                                        {t('paymentsMethod.bankTransfer-button')}
                                    </span>
                                    }
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
    )
}