import React, {useRef} from 'react';
import Dialog from "@mui/material/Dialog";
import {Button} from "@mui/material";
import rotateIcon from "../../images/rotate-icon.svg";
import flipIcon from "../../images/flip-icon.svg";
import DialogContent from "@mui/material/DialogContent";
import {Cropper, CropperRef} from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css'
import DialogActions from "@mui/material/DialogActions";
import spinner from "../../images/spinner.svg";
import {useTranslation} from "react-i18next";
import themeContext from "./ThemeContext.js";
import {DialogTitle, StyledBackdrop} from "../styles/Styles.js";

const CropDialog = ({open, handleAttach, handleClose, isLoading, onPendingImage, setCropper, cropperRef}) => {
    const ref = useRef(null);
    const {t, i18n} = useTranslation();


    const onReady = (cropper) => {
        setCropper(cropper)
    };

    const onChange = (cropper: CropperRef) => {
        setCropper(cropper)
    };

    const handleRotate = () => {
        if (ref.current) {
            ref.current.rotateImage(-90)
        }
    };

    const handleFlipHorizontal = () => {
        if (ref.current) {
            ref.current.flipImage(true, false)
        }
    };

    return (
        <Dialog
            components={{Backdrop: StyledBackdrop}}
            PaperProps={{
                style: {
                    'color': themeContext.color.neutral600,
                    'backgroundColor': 'transparent',
                    'boxShadow': 'none',
                    'width': '100%',
                    'margin': '0',
                }
            }}
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <div className={'d-flex justify-content-between align-items-center px-4'}>
                <div className={'d-flex justify-content-start'}>
                    <div id="alert-dialog-title" className="dialog-title justify-content-start"
                                 style={{'color': themeContext.color.white}}>
                        {t('personalDocuments.modal-crop-title')}
                    </div>
                </div>
                <div className={'d-flex justify-content-end'}>
                    <Button onClick={handleRotate} style={{'color': themeContext.color.white}}>
                        <img src={rotateIcon} alt="rotate-icon"/>
                    </Button>
                    <Button onClick={handleFlipHorizontal}
                            style={{'color': themeContext.color.white}}>
                        <img src={flipIcon} alt="rotate-icon"/>
                    </Button>
                </div>
            </div>
            <DialogContent>
                <Cropper
                    src={onPendingImage}
                    onReady={onReady}
                    onChange={onChange}
                    className={'w-100 p-2'}
                    minWidth={200}
                    minHeight={200}
                    ref={ref}
                    stencilProps={{
                        movable: true,
                        resizable: true,
                    }}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} style={{'color': themeContext.color.white}}>
                    {t('personalDocuments.modal-refuse-button')}
                </Button>
                <Button style={{'color': themeContext.color.white}} onClick={() => handleAttach()}>
                    {
                        isLoading &&
                        <img src={spinner} className="spinner-img" alt="spinner"/>
                    }
                    {!isLoading && t('personalDocuments.modal-confirm-button')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default CropDialog;