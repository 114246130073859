import React, {Fragment, useContext, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {Col, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {fetchToken} from "../middleware/Firebase.js";
import {Button, Dialog, ListItem, ListItemButton, Slide} from "@mui/material";
import {isIpad, isMac, isStandalone, putElementFromIndexedDB} from "../service/UtilityService.js";
import BellOff from '../../images/bell-icon.svg'
import BellOn from '../../images/bellOn-icon.svg'
import wideLogoLight from '../../images/wideLogo-icon.svg';
import {ClientJS} from 'clientjs';
import {makeStyles} from '@mui/styles';
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import baseMaleAvatar from "../../images/male-icon.svg";
import baseFemaleAvatar from "../../images/female-icon.svg";
import pdfIcon from "../../images/file-png-icon.png";
import addIcon from "../../images/add-blue-icon.svg";
import addIconDisable from "../../images/add-gray-icon.svg";
import vectorIcon from "../../images/policy_branches/vector.svg";
import artCollectionIcon from "../../images/policy_branches/art-collections-icon.svg";
import autoCvtIcon from "../../images/policy_branches/auto-cvt-icon.svg";
import aviationIcon from "../../images/policy_branches/aviation-icon.svg";
import buildingsIcon from "../../images/policy_branches/buildings-icon.svg";
import cablewayIcon from "../../images/policy_branches/cableway-icon.svg";
import certifiedTransportationIcon from "../../images/policy_branches/certified-transportation-icon.svg";
import civiliansIcon from "../../images/policy_branches/civilians-icon.svg";
import collectiveLivesIcon from "../../images/policy_branches/collective-lives-icon.svg";
import companiesIcon from "../../images/policy_branches/companies-icon.svg";
import depositsIcon from "../../images/policy_branches/deposits-icon.svg";
import electronicIcon from "../../images/policy_branches/electronic-icon.svg";
import energyIcon from "../../images/policy_branches/energy-icon.svg";
import farmingIcon from "../../images/policy_branches/farming-icon.svg";
import horecaIcon from "../../images/policy_branches/horeca-icon.svg";
import illnessIcon from "../../images/policy_branches/illness-icon.svg";
import individualLivesIcon from "../../images/policy_branches/individual-lives-icon.svg";
import injuresIcon from "../../images/policy_branches/injures-icon.svg";
import jewellersIcon from "../../images/policy_branches/jewellers-icon.svg";
import legalProtectionIcon from "../../images/policy_branches/legal-protection-icon.svg";
import officeLaboratoriesIcon from "../../images/policy_branches/office-laboratories-icon.svg";
import petInsuranceIcon from "../../images/policy_branches/pet-insurance-icon.svg";
import rcAdminIcon from "../../images/policy_branches/rc-admin-icon.svg";
import rcClinicalTrialsIcon from "../../images/policy_branches/rc-clinical-trials-icon.svg";
import rcIndividuals from "../../images/policy_branches/rc-individuals-icon.svg";
import rcMedicalMalpracticeIcon from "../../images/policy_branches/rc-medical-malpractice-icon.svg";
import rcPollutionIcon from "../../images/policy_branches/rc-pollution-icon.svg";
import rcProfessionalIcon from "../../images/policy_branches/rc-professional-icon.svg";
import shipIcon from "../../images/policy_branches/ship-icon.svg";
import technologiesIcon from "../../images/policy_branches/technologies-icon.svg";
import theftIcon from "../../images/policy_branches/theft-icon.svg";
import thirdSectorIcon from "../../images/policy_branches/third-sector-icon.svg";
import tourOperatorIcon from "../../images/policy_branches/tour-operator-icon.svg";
import tradeCreditsIcon from "../../images/policy_branches/trade-credits-icon.svg";
import travelBusinnessLineIcon from "../../images/policy_branches/travel-businnes-line-icon.svg";
import wheaterIcon from "../../images/policy_branches/wheater-icon.svg";
import downloadIcon from "../../images/download.svg";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import nothing from "../../images/nothing-icon.svg";
import Card from '@mui/material/Card';
import CardContent from "@mui/material/CardContent";
import FileSaver from "file-saver";
import exitIcon from "../../images/exit-icon.svg";
import {useMediaQuery} from "react-responsive";
import Moment from "react-moment";
import AppShareComponent from "../components/AppShare.js";
import Header from "../components/Header.js";
import DialogSmall from "../components/DialogSmall.js";
import DialogDoubleAction from "../components/DialogDoubleAction.js";
import baseCompanyAvatar from "../../images/company-avatar.svg";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";
import {StyledBackdrop} from "../styles/Styles.js";
import ErrorPage from "../pages/ErrorPage.js";
import {NavbarContext} from "../middleware/NavbarContext.js";
import {fromBlob} from 'file-type/browser';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100% !important',
    },
    card: {
        margin: "0 auto !important",
        marginBottom: "18px !important",
        background: `${themeContext.color.white} 0% 0% no-repeat padding-box !important`,
        boxShadow: `2px 3px 10px ${themeContext.color.boxShadow} !important`,
        borderRadius: "10px !important",
        opacity: "1 !important",
        minHeight: "140px !important"
    },
    cardTextMid: {
        color: `${themeContext.color.neutral600} !important`,
        textAlign: 'left !important',
        lineHeight: '1 !important',
        fontWeight: '400 !important',
        fontSize: '12px !important',
        paddingTop: '10px !important',
        paddingBottom: '7px !important',
    },
    personAvatar: {
        width: "30px !important",
        height: "30px !important",
    },
    listItem: {
        width: "100% !important",
        fontSize: "14px !important",
        color: `${themeContext.color.neutral400} !important`,
        paddingTop: '18px !important',
        paddingBottom: '18px !important',
    },
    listDocument: {
        color: `${themeContext.color.main} !important`,
        fontSize: "16px !important",
        textDecoration: "underline !important"
    },
    subtitle: {
        textAlign: "left !important",
        fontSize: "18px !important",
        fontStyle: "normal !important",
        lineHeight: "normal !important",
        fontWeight: "700 !important",
        color: `${themeContext.color.neutral400} !important`,
    },
    list: {
        fontWeight: "bold !important",
    },
    dialogTitle: {
        textAlign: 'left !important',
        font: 'normal normal 700 20px/28px Inter !important',
        paddingBottom: '24px !important'
    },
    dialogDescription: {
        textAlign: 'left !important',
        font: 'normal normal normal 14px/140% Inter !important',
        paddingBottom: '24px !important'
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Signing() {
    const checkReadUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/checkRead`;
    const getDocumentToSignedUrl = `${process.env.REACT_APP_BASE_URL}/media/expectedDocuments/getAllDocuments`;
    const uploadDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/expectedDocuments/uploadDocument`;
    const uploadedDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/expectedDocuments/uploadDocument`;
    const getLastSignedDocumentsUrl = `${process.env.REACT_APP_BASE_URL}/media/policy/lastSignedDocuments`;
    const getWhitelistPoliciesUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies/whitelistPolicies`;
    const downloadDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/policy/documentFile`;
    const userInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/info`;
    const token = sessionStorage.getItem('token');
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [openUploadedDialog, setOpenUploadedDialog] = useState(false);
    const [openSignForceDialog, setOpenSignForceDialog] = useState(false);
    const [reOpenSignForceDialog, setReOpenSignForceDialog] = useState(false);
    const [openUploadConfirmDialog, setOpenUploadConfirmDialog] = useState(false);
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const [searchValue, setSearchValue] = useState("");
    const [isUploadValid, setIsUploadValid] = useState(false);
    const [openListNotSignedArray, setOpenListNotSignedArray] = useState([]);
    const [openListSignedArray, setOpenListSignedArray] = useState([1, 2, 3, 4, 5, 6]);
    const [images, setImages] = useState([]);
    const [imagesUploaded, setImagesUploaded] = useState([]);
    const [whiteListCompany, setWhiteListCompany] = useState([]);
    const [file, setFile] = useState(null);
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'})
    const [uploadError, setUploadError] = useState(false);
    const {toggleNavbar} = useContext(NavbarContext);


    useEffect(() => {
        toggleNavbar(true)
        sessionStorage.setItem('root', '/signing');
        if (navigator.onLine) {
            if (sessionStorage.getItem("notificationId") != null) {
                const url = sessionStorage.getItem("notificationId").replaceAll('"', '')
                navigateToMessageDetails(url);
            }
            getInfo();
            handleRinging();
            getWhitelistPolicies();
            setImages(current => [...current, addIcon])
        } else {
            //
        }
        if (!localStorage.getItem('firstLogin') && localStorage.getItem('biometrics')) {
            setOpenDialog(true);
        }
        localStorage.setItem('firstLogin', true);
    }, [searchValue]);

    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({
        title: '',
        body: ''
    });
    const client = new ClientJS();
    const [openDialog, setOpenDialog] = useState(false);
    const [bellRinging, setBellRinging] = useState(false);
    const classes = useStyles();
    let [people, setPeople] = useState([]);
    const [getPermission, setGetPermission] = useState(false);
    const [selectedRadio, setSelectedRadio] = useState(null);
    const [selectedPeople, setSelectedPeople] = useState(null);
    const [selectedPolicy, setSelectedPolicy] = useState(null);
    const [selectedPersonId, setSelectedPersonId] = useState(null);
    const [selectedGender, setSelectedGender] = useState(null);
    const [selectedIdDocument, setSelectedIdDocument] = useState(null);
    const [selectedPolicyId, setSelectedPolicyId] = useState(null);
    const [selectedDocumentStatus, setSelectedDocumentStatus] = useState(null);
    const [selectedTitleId, setSelectedTitleId] = useState(null);
    const [selectedFilename, setSelectedFilename] = useState(null);
    const [selectedPolicyBranch, setSelectedPolicyBranch] = useState(null);
    const [dateFirstUpdate, setDateFirstUpdate] = useState(null);
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [openNoRadioSelectedDialog, setOpenNoRadioSelectedDialog] = useState(false);
    const [noDocuments, setNoDocuments] = useState(false);
    const [lastSignedDocuments, setLastSignedDocuments] = useState([])
    const [noLastSignedDocuments, setNoLastSignedDocuments] = useState(false);
    const [contentType, setContentType] = useState("");
    const [user, setUser] = useState("");
    const [temporaryContentType, setTemporaryContentType] = useState("");
    const [contentTypeError, setContentTypeError] = useState(false);
    const [sizeExceededError, setSizeExceededError] = useState(false);
    const acceptedMimeTypes = [
        'application/pdf', 'image/png', 'image/jpg', 'image/jpeg', 'application/pkcs7-mime',
        'application/pkcs7-signature', 'application/x-pkcs7-certificates', 'application/pkix-cert',
        'application/x-x509-ca-cert', 'application/x-pkcs12', 'application/x-pem-file',
        'application/x-iwork-keynote-sffkey', 'application/timestamped-data', 'application/timestamp-query',
        'application/x-xades+xml', 'application/xslt+xml', 'application/pgp-signature', 'application/pgp-encrypted',
        'application/pgp-signature', 'application/x-pkcs7-certreqresp', 'application/x-pkcs12',
        'application/vnd.ssh.key', 'application/x-putty-private-key', 'application/x-openvpn-profile',
        'application/x-keepass-database', 'application/pkix-crl', 'application/pkcs10'
    ];
    const acceptedFileExtensions = [
        '.pdf', '.png', '.jpg', '.jpeg', '.p7m', '.p7s', '.p7b', '.spc', '.cer', '.crt', '.der', '.p12', '.pfx', '.pem',
        '.key', '.tsd', '.tst', '.xades', '.xslt', '.asc', '.gpg', '.sig', '.p7c', '.p7r', '.pub', '.ppk',
        '.ovpn', '.kdbx', '.crl', '.csr',
    ];

    const extensionMap = {
        'pdf': 'application/pdf',
        'png': 'image/png',
        'jpg': 'image/jpeg',
        'jpeg': 'image/jpeg',
        'p7m': 'application/pkcs7-mime',
        'p7s': 'application/pkcs7-signature',
        'p7b': 'application/x-pkcs7-certificates',
        'spc': 'application/pkix-cert',
        'cer': 'application/x-x509-ca-cert',
        'crt': 'application/x-x509-ca-cert',
        'der': 'application/x-x509-ca-cert',
        'p12': 'application/x-pkcs12',
        'pfx': 'application/x-pkcs12',
        'pem': 'application/x-pem-file',
        'key': 'application/x-pem-file',
        'tsd': 'application/x-iwork-keynote-sffkey',
        'tst': 'application/timestamped-data',
        'xades': 'application/x-xades+xml',
        'xslt': 'application/xslt+xml',
        'asc': 'application/pgp-signature',
        'gpg': 'application/pgp-encrypted',
        'sig': 'application/pgp-signature',
        'p7c': 'application/x-pkcs7-certreqresp',
        'p7r': 'application/x-pkcs7-certreqresp',
        'pub': 'application/vnd.ssh.key',
        'ppk': 'application/x-putty-private-key',
        'ovpn': 'application/x-openvpn-profile',
        'kdbx': 'application/x-keepass-database',
        'crl': 'application/pkix-crl',
        'csr': 'application/pkcs10',
    }

    if (((client.isMobileIOS() || isIpad()) && client.getOSVersion() < "16.4") || (isMac() && client.getOSVersion() < "13") || ((client.isMobileIOS() || isIpad()) && !(client.getOSVersion() < "16.4") && !isStandalone())) {
        console.log('notification not allowed')
    } else if (!getPermission) {
        requestPermission();
        setGetPermission(true);
    }
    fetchToken();

    function requestPermission() {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
            }
        })
    }

    if (((client.isMobileIOS() || isIpad()) && client.getOSVersion() < "16.4") || (isMac() && client.getOSVersion() < "13") || ((client.isMobileIOS() || isIpad()) && !(client.getOSVersion() < "16.4") && !isStandalone())) {
        console.log('notification not allowed')
    } else {
        const channel = new BroadcastChannel('sw-messages');
        channel.onmessage = (event) => {
            setBellRinging(true)
        };
        channel.onmessageerror = (event) => {
        };
    }

    const navigateToMessageDetails = (url) => {
        return navigate(`/messages/principal/${url}`);
    }

    function getInfo() {
        fetch(userInfoUrl, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            i18n.changeLanguage(data.preferredLanguage).then(r => {
            })
            if (data.status !== -1) {
                setUser(data)
                localStorage.setItem('wideAPP_userId', data.id)
                putElementFromIndexedDB('profileInfo', data)
            }
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const handleRinging = () => {
        fetch(checkReadUrl, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json',},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.toRead) {
                setBellRinging(true);
            } else {
                setBellRinging(false);
            }
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const getDocumentToSigned = () => {
        setIsLoadingData(true)
        fetch(getDocumentToSignedUrl, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length === 0) {
                setNoDocuments(true)
            } else {
                let finder = false;
                let countDocuments = 0;
                data = data.map((person) => {
                    person.policies = person.policies.map((policy) => {
                        finder = true
                        if (policy.documents.length > 0) {
                            countDocuments = countDocuments + (policy.documents.length);
                            return policy;
                        }
                    }).filter(e => e)
                    if (person.policies.length > 0) {
                        person.countDocuments = countDocuments;
                        countDocuments = 0;
                        return person;
                    }
                }).filter(e => e)
                if (data.length === 0) {
                    setNoDocuments(true)
                }
                setPeople(data)
            }
            getLastSignedDocuments();
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const base64toBlob = (b64Data, contentType = 'image/jpeg', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, {type: contentType});
    }

    const uploadDocument = (titleId, filename) => {
        setIsLoadingData(true)
        fetch(uploadDocumentUrl, {
            method: 'POST',
            headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({
                doc: file.split(',')[1],
                titleId: titleId,
                filename: filename,
                contentType: contentType
            })
        }).then((response) => {
            return response.json();
        }).then(() => {
            setImages([addIcon])
            setImagesUploaded([])
            setOpenUploadConfirmDialog(false)
            getDocumentToSigned();
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const getLastSignedDocuments = () => {
        fetch(getLastSignedDocumentsUrl, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length === 0) {
                setNoLastSignedDocuments(true)
            } else {
                setLastSignedDocuments(data)
            }
            setIsLoadingData(false)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const uploadedDocuments = (policyId, isPending: boolean) => {
        setIsLoadingData(true)
        fetch(`${uploadedDocumentUrl}/${policyId}`, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setIsLoadingData(false)
            if (data.status === 500 && data.message === "List is empty.") {
                if (!isPending) {
                    setImages([addIcon])
                    setImagesUploaded([])
                    setOpenUploadDialog(true);
                }
            } else {
                setImagesUploaded([])
                data.documents.forEach((element) => {
                    setImagesUploaded(current => [...current, element])
                })
                setDateFirstUpdate(data.documents[0].createdAt)
            }
            setTemporaryContentType(data.documents[0].contentType);
        }).catch((e) => {
            setUploadError(true)
            setIsLoadingData(false)
        });
    }

    function reload() {
        window.location.reload();
    }

    const handleClick = (index) => {
        if (!openListNotSignedArray.includes(index)) {
            setOpenListNotSignedArray(current => [...current, index])
        } else {
            setOpenListNotSignedArray(openListNotSignedArray.filter(item => item !== index))
        }
    }

    const handleClickSigned = (index) => {
        if (!openListSignedArray.includes(index)) {
            setOpenListSignedArray(current => [...current, index])
        } else {
            setOpenListSignedArray(openListSignedArray.filter(item => item !== index))
        }
    }

    const linkToUploadDocumentToVerify = (personId, documentId, policyId, titleId, gender) => {
        if (gender === 'C') {
            navigate(`/signing/${personId}/uploadBack?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}`)
        } else {
            navigate(`/signing/${personId}/upload?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}`)
        }
    }

    const getBranchIcon = (policyType) => {
        switch (policyType) {
            case "TUTELA LEGALE":
                return legalProtectionIcon;
            case "AGRICOLTURA":
                return farmingIcon;
            case "AUTO (RCA - CVT)":
            case "AUTO (CVT)":
            case "CVT AUTO D'EPOCA E SPORTIVE":
            case "CVT SOCCORSO":
            case "KASKO CHILOMETRICA":
                return autoCvtIcon;
            case "PET INSURANCE":
                return petInsuranceIcon;
            case "CAUZIONI":
                return depositsIcon;
            case "CREDITI COMMERCIALI":
                return tradeCreditsIcon;
            case "ELETTRONICA":
                return electronicIcon;
            case "ENERGY":
                return energyIcon;
            case "RC AMMINISTRATORI (D&O)":
            case "RC COLPA GRAVE":
                return rcAdminIcon;
            case "FINE ART E COLLEZIONI":
                return artCollectionIcon;
            case "FURTO":
            case "FURTO PRIVATI":
                return theftIcon;
            case "GLOBALE GIOIELLERIE":
                return jewellersIcon;
            case "GLOBALE FABBRICATI":
                return buildingsIcon;
            case "INFORTUNI":
            case "INFORTUNI COLLETTIVA":
            case "INFORTUNI CONDUCENTI":
            case "INFORTUNI E MALATTIA":
            case "INFORTUNI TERZO SETTORE":
            case "PRESTAZIONI DI ASSISTENZA SANITARIA":
                return injuresIcon;
            case "MALATTIA":
                return illnessIcon;
            case "METEO":
                return wheaterIcon;
            case "VIAGGI LINEA BUSINESS":
            case "VIAGGI LINEA PERSONA":
                return travelBusinnessLineIcon;
            case "MULTIRISCHI AZIENDE":
            case "PROPERTY AZIENDE":
            case "RC AZIENDE":
                return companiesIcon;
            case "MULTIRISCHI HO.RE.CA.":
                return horecaIcon;
            case "MULTIRISCHI TERZO SETTORE":
            case "RC TERZO SETTORE":
                return thirdSectorIcon;
            case "MULTIRISCHI CIVILI":
            case "PROPERTY RISCHI CIVILI":
                return civiliansIcon;
            case "MULTIRISCHI UFFICI/LABORATORI":
                return officeLaboratoriesIcon;
            case "RC INQUINAMENTO":
                return rcPollutionIcon;
            case "RC MEDICAL MALPRACTICE":
                return rcMedicalMalpracticeIcon;
            case "RC PRIVATI":
                return rcIndividuals;
            case "RC PROFESSIONALE":
                return rcProfessionalIcon;
            case "RC SPERIMENTAZIONE CLINICA":
                return certifiedTransportationIcon;
            case "TECNOLOGICI":
                return technologiesIcon;
            case "AGENZIE VIAGGI":
            case "TOUR OPERATOR / AGENZIA VIAGGI":
                return tourOperatorIcon;
            case "TRASPORTI CERTIFICATI IN ABBONAMENTO":
            case "TRASPORTO MERCI":
            case "STOCK & TRANSIT":
            case "SPEDIZIONIERI E VETTORI":
                return rcClinicalTrialsIcon;
            case "CORPI":
            case "RC E CORPI NATANTI":
            case "P&I PROTECTION & INDEMNITY":
                return shipIcon;
            case "VITA COLLETTIVE":
            case "RC PATRIMONIALE COLPA LIEVE":
                return collectiveLivesIcon;
            case "VITA INDIVIDUALI":
                return individualLivesIcon;
            case "AVIAZIONE":
                return aviationIcon;
            case "PERDITE PECUNIARIE":
                return depositsIcon;
            case "CYBER RISK":
                //icona mancante
                return theftIcon;
            case "MULTIRISCHI IMPIANTI A FUNE":
                return cablewayIcon;
            default:
                return vectorIcon;
        }
    }

    const handleDocument = (documentId, personId, policyId, branch) => {
        setSelectedRadio(documentId);
        setSelectedPeople(personId);
        setSelectedPolicy(policyId);
        setSelectedPolicyBranch(branch)
    }

    const downloadDocument = (id) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                documentId: id,
            })
        };
        fetch(downloadDocumentUrl, requestOptions)
            .then((response) => {
                return response.json();
            }).then((data) => {
            FileSaver.saveAs(b64toFile(data.base64, data.filename, "application/pdf"), data.filename);
        }).catch(() => {
            setErrorPage(true)
        });
    }

    function b64toFile(b64Data, filename, contentType) {
        const sliceSize = 512;
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const file = new File(byteArrays, filename, {type: contentType});
        return file;
    }

    const handleClickFile = event => {
        const input = document.querySelector("#image-file");
        input.click();
    };

    async function getMimeType(file) {
        const mime = await fromBlob(file);
        return mime ? mime.mime : 'application/octet-stream';
    }

    const handleChange = async event => {
        setContentTypeError(null)
        setSizeExceededError(null)
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            let type = event.target.files[0].type;
            let name = event.target.files[0].name;
            if ((type !== undefined && (acceptedMimeTypes.includes(type))) || (type === "" && acceptedFileExtensions.some(ext => name.endsWith(ext)))) {
                if (fileUploaded.size < 20000000) {
                    const mimetype = await getMimeType(fileUploaded);
                    if (type === "" && mimetype === 'application/octet-stream') {
                        const extension1 = name.split('.').pop();

                        if (extensionMap[extension1] !== undefined) {
                            type = extensionMap[extension1];
                        }
                    }
                    setSizeExceededError(false)
                    getBase64(fileUploaded).then(res => {
                        setFile(res)
                        setContentType(type)
                        setImages(current => [...current, pdfIcon])
                        setIsUploadValid(true)
                    })
                    handleFile(fileUploaded);
                } else {
                    setContentTypeError(true)
                }
            } else {
                setContentTypeError(true)
            }
        }
    }

    const handleFile = (file) => {
        const formData = new FormData();
        formData.append('file', file);
    }

    async function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = reject
        })
    }

    const getWhitelistPolicies = () => {
        fetch(getWhitelistPoliciesUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setWhiteListCompany(data)
            getDocumentToSigned(data);
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const trimDescription = (description) => {
        let text = description.replace('rejected: ', '')
        return text.slice(0, 1).toUpperCase() + text.slice(1, text.length);
    }

    const truncate = (str) => {
        return str.length > 25 ? str.substring(0, 22) + "..." : str;
    }

    const openFile = (file) => {
        if (file.contentType === 'application/pkcs7-mime' || file.contentType === 'application/pkcs7-signature') {
            FileSaver.saveAs(b64toFile(file.base64, file.name, file.contentType), file.name);
        } else {
            window.open(file.url, '_blank', 'noopener,noreferrer');
        }
    }

    const renderCustomThumbs = (document) => {
        return (
            <Fragment>
                {images.map((item, index) => (
                    index === 0 ?
                        <Fragment key={index}>
                            <div
                                className={'card-button-container d-flex flex-column justify-content-center align-items-center w-100'}
                                style={{
                                    gap: 16,
                                    margin: '0 auto'
                                }}>
                                <Button
                                    onClick={() => {
                                        downloadDocument(selectedIdDocument)
                                    }}
                                    target="_blank"
                                    href={imagesUploaded.url}
                                    rel="noreferrer"
                                    className={'outline-button'}>
                                    <div
                                        className={'d-flex flex-row justify-content-center align-items-center'}
                                        style={{gap: 10}}>
                                        <span
                                            className={'blue d-flex font-weight-bold'}>
                                            {t('signing.download-file-button')}
                                        </span>
                                        <div>
                                            <img style={{width: 16, borderRadius: 0}}
                                                 key={index}
                                                 src={downloadIcon}
                                                 alt="First Thumbnail"
                                            />
                                        </div>
                                    </div>
                                </Button>
                                {
                                    selectedDocumentStatus !== null && !selectedDocumentStatus.startsWith('rejected:') &&
                                    <Button
                                        onClick={() => {
                                            if (images.length === 1) {
                                                handleClickFile()
                                            }
                                        }}
                                        target="_blank"
                                        href={imagesUploaded.url}
                                        rel="noreferrer"
                                        disabled={index === 0 && images.length !== 1}
                                        className={index === 0 && images.length !== 1 ? 'outline-disable-button-multiple' : 'outline-button-multiple'}>
                                        <div
                                            className={'d-flex flex-column justify-content-center align-items-center'}
                                            style={{gap: 10}}>
                                            <div>
                                                <img style={{width: 18}}
                                                     key={index}
                                                     src={index === 0 && images.length !== 1 ? addIconDisable : addIcon}
                                                     alt="First Thumbnail"
                                                />
                                            </div>
                                            <span
                                                className={index === 0 && images.length !== 1 ? 'grey d-flex justify-content-center font-weight-bold' : 'blue d-flex justify-content-center font-weight-bold'}>
                                                    {t('signing.upload-button')}
                                            </span>
                                        </div>
                                    </Button>
                                }
                                {
                                    selectedDocumentStatus && selectedDocumentStatus.startsWith('rejected:') &&
                                    <Button
                                        onClick={() => {
                                            if (images.length === 1) {
                                                handleClickFile()
                                            }
                                        }}
                                        target="_blank"
                                        href={imagesUploaded.url}
                                        rel="noreferrer"
                                        disabled={index === 0 && images.length !== 1}
                                        className={index === 0 && images.length !== 1 ? 'outline-delete-button-disable' : 'outline-delete-button'}>
                                        <span
                                            className={index === 0 && images.length !== 1 ? 'grey d-flex justify-content-center' : 'red d-flex justify-content-center font-weight-bold'}>
                                                {t('signing.re-upload-button')}
                                        </span>
                                    </Button>
                                }
                            </div>
                        </Fragment>
                        :
                        <div key={index}>
                            <div className={'position-relative d-flex justify-content-center flex-column'}
                                 style={{gap: 15}}>
                                <div className={'mx-auto'} onClick={(event) => {
                                    let link = document.createElement("a");
                                    link.setAttribute('download', '');
                                    link.href = file;
                                    document.body.appendChild(link);
                                    link.click();
                                }}>
                                    <img style={{
                                        width: '94px',
                                    }}
                                         key={index}
                                         src={index === 0 ? addIcon : pdfIcon}
                                         alt="First Thumbnail"
                                    />
                                </div>
                                <button className="delete-small"
                                        style={{marginLeft: 'auto', marginRight: "auto", width: 75}}
                                        onClick={(event) => {
                                            images.splice(1, 1)
                                            setIsUploadValid(false)
                                        }}>{t('personalDocuments.delete-button')}</button>
                            </div>
                        </div>
                ))}
            </Fragment>
        )
    }

    return (errorPage ? <ErrorPage/> : <Fragment>
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                <Row className="nav-top container" style={{
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col>
                        <Header iconSrc={wideLogoLight}
                                rightSrc={bellRinging ? BellOn : BellOff}
                                rightAction={() => navigate('/messages')}/>
                    </Col>
                </Row>
                <Container className="top-of-the-language"></Container>
                <Row className="mt-2" style={{'backgroundColor': themeContext.color.neutral10}}>
                    <Col className="col-list">
                        {isLoadingData &&
                            <div style={{'height': '85vh'}} className="background-loading-spinner"></div>
                        }
                        {!isLoadingData && noDocuments && noLastSignedDocuments &&
                            <Container style={{
                                'margin': '20vh auto',
                                'backgroundColor': themeContext.color.neutral10
                            }}>
                                <Row>
                                    <Col>
                                        <img src={nothing} alt={t('signing.nothing-text-1')}></img>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <span className="no-messages-text">{t('signing.nothing-text-1')}</span>
                                    </Col>
                                </Row>
                            </Container>
                        }
                        {!isLoadingData && !noDocuments &&
                            <Fragment>
                                <List
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                    className={classes.root}>
                                    {people.map((value, index) => (
                                        <Fragment key={index}>
                                            {index === 0 && <Container>
                                                <Row className="float-left mt-2 mb-2">
                                                    <Col>
                                                        <h6 className={classes.subtitle}>{t('signing.title')}</h6>
                                                    </Col>
                                                </Row>
                                            </Container>}
                                            <ListItemButton onClick={() => handleClick(index)}
                                                            className={classes.listItem}>
                                                <ListItemIcon>
                                                    {value.image === null && value.gender === 'M' &&
                                                        <Avatar className={classes.personAvatar}
                                                                src={baseMaleAvatar}
                                                                alt="avatar-image"/>}
                                                    {value.image === null && value.gender === 'F' &&
                                                        <Avatar className={classes.personAvatar}
                                                                src={baseFemaleAvatar}
                                                                alt="avatar-image"/>}
                                                    {value.image === null && value.gender === 'C' &&
                                                        <Avatar className={classes.personAvatar}
                                                                src={baseCompanyAvatar}
                                                                alt="avatar-image"/>}
                                                    {value.image === null && value.gender === null &&
                                                        <Avatar className={classes.personAvatar}
                                                                src={baseMaleAvatar}
                                                                alt="avatar-image"/>}
                                                    {value.image &&
                                                        <Avatar className={classes.personAvatar} src={value.image}
                                                                alt="avatar-image"/>}
                                                </ListItemIcon>
                                                {((value.firstName === null || value.firstName === "") && (value.lastName === null || value.lastName === "")) ?
                                                    <ListItemText className="list-label"
                                                                  secondary={value.name.toLowerCase()}
                                                                  style={{
                                                                      textTransform: 'capitalize',
                                                                      color: themeContext.color.neutral600
                                                                  }}/> :
                                                    <ListItemText className="list-label"
                                                                  secondary={`${value.firstName.toLowerCase()} ${value.lastName.toLowerCase()}`}
                                                                  style={{
                                                                      textTransform: 'capitalize',
                                                                      color: themeContext.color.neutral600
                                                                  }}/>}
                                                <span style={{
                                                    textAlign: 'right',
                                                    fontSize: '12px',
                                                    fontWeight: '700'
                                                }}>{t('signing.to-sign-label')} {value.countDocuments}
                                        </span>
                                                {openListNotSignedArray.includes(index) ? <ExpandLess/> : <ExpandMore/>}
                                            </ListItemButton>
                                            <Collapse in={!openListNotSignedArray.includes(index)} timeout="auto"
                                                      unmountOnExit>
                                                <Container>
                                                    <Row>
                                                        <List component="div" className="col div-under-list">
                                                            {value.policies.map((policy) => {
                                                                return (
                                                                    policy.documents.map((document, index) => (
                                                                        <Fragment key={index}>
                                                                            <Card className={classes.card}>
                                                                                <CardContent>
                                                                                    {
                                                                                        document.documentCustomerSignedStatus === 'pending' &&
                                                                                        <Row style={{
                                                                                            marginBottom: document.documentCustomerSignedStatus === 'pending' ? 'unset' : '12px'
                                                                                        }}>
                                                                                            <Col className="col-auto">
                                                                                                <h6 onClick={() => {
                                                                                                    setSelectedPolicyBranch(policy.branchName)
                                                                                                    uploadedDocuments(document.ownedId, true)
                                                                                                    setOpenUploadedDialog(true)
                                                                                                }}
                                                                                                    style={{
                                                                                                        color: themeContext.color.yellow,
                                                                                                        textDecoration: 'underline',
                                                                                                        fontWeight: 'bold'
                                                                                                    }}>
                                                                                                    {t('signing.revision-status')}
                                                                                                </h6>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    }

                                                                                    <Row style={{marginBottom: '12px'}}>
                                                                                        <Col className="col-auto mr-0"
                                                                                             style={{'paddingRight': '0px'}}>
                                                                                            <img
                                                                                                src={getBranchIcon(policy.branchName)}
                                                                                                alt="type-policy-icon"/>
                                                                                        </Col>
                                                                                        <Col className="col-7">
                                                                                            <h6 className={classes.cardTextMid}>{policy.branchName}</h6>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <h6 className={"card-text-content ellipsis-1-v2"}>{t('dashboard.expiration-label')}
                                                                                                <span
                                                                                                    className="font-weight-bold">
                                                                                                <Moment locale="it"
                                                                                                        format="DD/MM/YYYY"
                                                                                                        className="black">
                                                                                                    {policy.insuranceEnd}
                                                                                                </Moment>
                                                                                            </span>
                                                                                            </h6>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <h6 className={"card-text-content ellipsis-1-v2"}>
                                                                                                {t('dashboard.company-label')}
                                                                                                <span
                                                                                                    className="font-weight-bold black">
                                                                                                {policy.companyName}
                                                                                            </span>
                                                                                            </h6>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <h6 className={"card-text-content ellipsis-1-v2"}>
                                                                                                {t('dashboard.policy-number-label')}
                                                                                                <span
                                                                                                    className="font-weight-bold black">
                                                                                                {policy.policyNr}
                                                                                            </span>
                                                                                            </h6>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <div
                                                                                        className={'card-button-container d-flex flex-column justify-content-center align-items-center w-100'}
                                                                                        style={{
                                                                                            gap: 16,
                                                                                            maxWidth: 320,
                                                                                            margin: '0 auto'
                                                                                        }}>
                                                                                        <Button
                                                                                            onClick={() => {
                                                                                                downloadDocument(document.idDocument)
                                                                                            }}
                                                                                            target="_blank"
                                                                                            href={imagesUploaded.url}
                                                                                            rel="noreferrer"
                                                                                            className={'outline-button'}>
                                                                                            <div
                                                                                                className={'d-flex flex-row justify-content-center align-items-center'}
                                                                                                style={{gap: 10}}>
                                                                                            <span
                                                                                                className={'blue d-flex font-weight-bold'}>
                                                                                                    {t('signing.download-file-button')}
                                                                                            </span>
                                                                                                <div>
                                                                                                    <img
                                                                                                        style={{width: 16}}
                                                                                                        key={index}
                                                                                                        src={downloadIcon}
                                                                                                        alt="First Thumbnail"
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </Button>
                                                                                        {(!(document.documentCustomerSignedStatus === 'pending' && (!whiteListCompany.includes(policy.companyName))) && !(document.documentCustomerSignedStatus === 'pending' && (!whiteListCompany.includes(policy.companyName)))) &&
                                                                                            <Divider/>
                                                                                        }
                                                                                        {
                                                                                            document.documentCustomerSignedStatus === 'pending' && (!whiteListCompany.includes(policy.companyName)) &&
                                                                                            <Fragment></Fragment>
                                                                                        }
                                                                                        {
                                                                                            document.documentCustomerSignedStatus.startsWith('rejected') &&
                                                                                            <span
                                                                                                className={'red d-flex align-items-start'}>
                                                                                                    • {trimDescription(document.documentCustomerSignedStatus)}
                                                                                        </span>
                                                                                        }
                                                                                        {
                                                                                            document.documentCustomerSignedStatus === 'docsent' && (whiteListCompany.includes(policy.companyName)) &&
                                                                                            // CASO in cui il documento sia in stato docsent e la compagnia della polizza in whitelist
                                                                                            <Fragment>
                                                                                                <Button
                                                                                                    className="outline-filled-button"
                                                                                                    onClick={() => {
                                                                                                        setSelectedPersonId(value.personId)
                                                                                                        setSelectedGender(value.gender)
                                                                                                        setSelectedIdDocument(document.idDocument)
                                                                                                        setSelectedDocumentStatus(document.documentCustomerSignedStatus)
                                                                                                        setSelectedPolicyId(policy.policyId)
                                                                                                        setSelectedPolicyBranch(policy.branchName)
                                                                                                        setSelectedTitleId(document.ownedId)
                                                                                                        setSelectedFilename(document.name)
                                                                                                        if (value.pubblicaAmministrazione !== 1) {
                                                                                                            setOpenSignForceDialog(true)
                                                                                                        }
                                                                                                    }}
                                                                                                    variant="outlined"
                                                                                                    size="large"
                                                                                                    type="submit">{t('signing.upload-button')}
                                                                                                </Button>
                                                                                                {value.pubblicaAmministrazione !== 1 &&
                                                                                                    <Button
                                                                                                        className="primary-button"
                                                                                                        onClick={() => {
                                                                                                            linkToUploadDocumentToVerify(value.personId, document.idDocument, policy.policyId, document.ownedId, value.gender)
                                                                                                        }}
                                                                                                        size="large"
                                                                                                        type="submit">{t('signing.sign-button')}
                                                                                                    </Button>}
                                                                                            </Fragment>
                                                                                        }
                                                                                        {
                                                                                            document.documentCustomerSignedStatus === 'docsent' && (!whiteListCompany.includes(policy.companyName)) &&
                                                                                            // CASO in cui il documento sia in stato docsent e la compagnia della polizza non in whitelist
                                                                                            <Button
                                                                                                className="outline-filled-button"
                                                                                                onClick={() => {
                                                                                                    setSelectedPersonId(value.personId)
                                                                                                    setSelectedGender(value.gender)
                                                                                                    setSelectedIdDocument(document.idDocument)
                                                                                                    setSelectedDocumentStatus(document.documentCustomerSignedStatus)
                                                                                                    setSelectedPolicyId(policy.policyId)
                                                                                                    setSelectedPolicyBranch(policy.branchName)
                                                                                                    setSelectedTitleId(document.ownedId)
                                                                                                    setSelectedFilename(document.name)
                                                                                                    setOpenUploadDialog(true)
                                                                                                }}
                                                                                                variant="outlined"
                                                                                                size="large"
                                                                                                type="submit">
                                                                                                {t('signing.upload-button')}
                                                                                            </Button>
                                                                                        }
                                                                                        {
                                                                                            document.documentCustomerSignedStatus === 'pending' && (!whiteListCompany.includes(policy.companyName)) &&
                                                                                            // CASO in cui il documento sia in stato pending e la compagnia della polizza non in whitelist
                                                                                            <Fragment></Fragment>
                                                                                        }
                                                                                        {
                                                                                            document.documentCustomerSignedStatus === 'pending' && (whiteListCompany.includes(policy.companyName)) && document.hasRevision !== true && value.pubblicaAmministrazione !== 1 &&
                                                                                            // CASO in cui il documento sia in stato pending e la compagnia della polizza in whitelist
                                                                                            <Button
                                                                                                className="primary-button"
                                                                                                onClick={() => {
                                                                                                    linkToUploadDocumentToVerify(value.personId, document.idDocument, policy.policyId, document.ownedId, value.gender)
                                                                                                }}
                                                                                                variant="outlined"
                                                                                                size="large"
                                                                                                type="submit">
                                                                                                {t('signing.sign-button')}
                                                                                            </Button>
                                                                                        }
                                                                                        {
                                                                                            document.documentCustomerSignedStatus.startsWith('rejected') && (whiteListCompany.includes(policy.companyName)) &&
                                                                                            // CASO in cui il documento sia in stato rejected e la compagnia della polizza in whitelist
                                                                                            <Fragment>
                                                                                                {value.pubblicaAmministrazione !== 1 &&
                                                                                                    <Button
                                                                                                        className="primary-button"
                                                                                                        onClick={() => {
                                                                                                            linkToUploadDocumentToVerify(value.personId, document.idDocument, policy.policyId, document.ownedId, value.gender, value.gender)
                                                                                                        }}
                                                                                                        variant="outlined"
                                                                                                        size="large"
                                                                                                        type="submit">
                                                                                                        {t('signing.sign-button')}
                                                                                                    </Button>}
                                                                                                <Button
                                                                                                    className="outline-delete-button"
                                                                                                    onClick={() => {
                                                                                                        setSelectedPersonId(value.personId)
                                                                                                        setSelectedGender(value.gender)
                                                                                                        setSelectedIdDocument(document.idDocument)
                                                                                                        setSelectedDocumentStatus(document.documentCustomerSignedStatus)
                                                                                                        setSelectedPolicyId(policy.policyId)
                                                                                                        setSelectedPolicyBranch(policy.branchName)
                                                                                                        setSelectedTitleId(document.ownedId)
                                                                                                        setSelectedFilename(document.name)
                                                                                                        if (value.pubblicaAmministrazione !== 1) {
                                                                                                            setReOpenSignForceDialog(true)
                                                                                                        }
                                                                                                    }}
                                                                                                    variant="outlined"
                                                                                                    size="large"
                                                                                                    type="submit">
                                                                                                    {t('signing.re-upload-button')}
                                                                                                </Button>
                                                                                            </Fragment>
                                                                                        }
                                                                                        {
                                                                                            document.documentCustomerSignedStatus.startsWith('rejected') && (!whiteListCompany.includes(policy.companyName)) &&
                                                                                            // CASO in cui il documento sia in stato rejected e la compagnia della polizza non in whitelist
                                                                                            <Button
                                                                                                className="outline-delete-button"
                                                                                                onClick={() => {
                                                                                                    setSelectedPersonId(value.personId)
                                                                                                    setSelectedGender(value.gender)
                                                                                                    setSelectedIdDocument(document.idDocument)
                                                                                                    setSelectedDocumentStatus(document.documentCustomerSignedStatus)
                                                                                                    setSelectedPolicyId(policy.policyId)
                                                                                                    setSelectedPolicyBranch(policy.branchName)
                                                                                                    setSelectedTitleId(document.ownedId)
                                                                                                    setSelectedFilename(document.name)
                                                                                                    uploadedDocuments(document.ownedId, false)
                                                                                                    setOpenUploadDialog(true)
                                                                                                }}
                                                                                                variant="outlined"
                                                                                                size="large"
                                                                                                type="submit">
                                                                                                {t('signing.re-upload-button')}
                                                                                            </Button>
                                                                                        }
                                                                                    </div>
                                                                                </CardContent>
                                                                            </Card>
                                                                        </Fragment>
                                                                    )))
                                                            })}
                                                        </List>
                                                    </Row>
                                                </Container>
                                            </Collapse>
                                        </Fragment>))
                                    }
                                </List>
                            </Fragment>
                        }
                        {!isLoadingData && !noLastSignedDocuments && <Fragment>
                            <List
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                className={classes.root}>
                                {lastSignedDocuments.map((value, index) => (<Fragment key={index}>
                                    {index === 0 && <Container>
                                        <Row className="float-left mt-4 mb-2">
                                            <Col>
                                                <h6 className={classes.subtitle}>{t('signing.last-signed-document-title')}</h6>
                                            </Col>
                                        </Row>
                                    </Container>}
                                    <ListItemButton onClick={() => handleClickSigned(index)}
                                                    className={classes.listItem}>
                                        <ListItemIcon>
                                            {value.image === null && value.gender === 'M' &&
                                                <Avatar className={classes.personAvatar}
                                                        src={baseMaleAvatar}
                                                        alt="avatar-image"/>}
                                            {value.image === null && value.gender === 'F' &&
                                                <Avatar className={classes.personAvatar}
                                                        src={baseFemaleAvatar}
                                                        alt="avatar-image"/>}
                                            {value.image === null && value.gender === 'C' &&
                                                <Avatar className={classes.personAvatar}
                                                        src={baseCompanyAvatar}
                                                        alt="avatar-image"/>}
                                            {value.image === null && value.gender === null &&
                                                <Avatar className={classes.personAvatar}
                                                        src={baseMaleAvatar}
                                                        alt="avatar-image"/>}
                                            {value.image && <Avatar className={classes.personAvatar}
                                                                    src={value.image}
                                                                    alt="avatar-image"/>
                                            }
                                        </ListItemIcon>
                                        {((value.firstName === null || value.firstName === "") && (value.lastName === null || value.lastName === "")) ?
                                            <ListItemText className="list-label"
                                                          secondary={value.name.toLowerCase()}
                                                          style={{
                                                              textTransform: 'capitalize',
                                                              color: themeContext.color.neutral600
                                                          }}/> :
                                            <ListItemText className="list-label"
                                                          secondary={`${value.firstName.toLowerCase()} ${value.lastName.toLowerCase()}`}
                                                          style={{
                                                              textTransform: 'capitalize',
                                                              color: themeContext.color.neutral600
                                                          }}/>}
                                        {openListSignedArray.includes(index) ? <ExpandLess/> : <ExpandMore/>}
                                    </ListItemButton>
                                    <Collapse in={!openListSignedArray.includes(index)} timeout="auto"
                                              unmountOnExit>
                                        <List component="div" disablePadding>
                                            {value.policies.map((policy, index) => {
                                                return (<Fragment key={index}>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <img src={getBranchIcon(policy.branch)}
                                                                 alt="type-policy-icon"
                                                                 style={{
                                                                     marginLeft: '11px',
                                                                 }}/>
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            className={classes.cardTextMid}
                                                            primary={policy.branch}></ListItemText>
                                                    </ListItem>
                                                    {policy.documents.map((document, index) => (
                                                        <ListItemButton
                                                            className={classes.nested}
                                                            key={index}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleDocument(document.idDocument, value.personId, policy.policyId, policy.branch)
                                                            }}>
                                                            <ListItemIcon>
                                                                <img src={pdfIcon} style={{width: '24px'}}
                                                                     alt="pdf-icon"/>
                                                            </ListItemIcon>
                                                            <a href={document.url}
                                                               target="_blank"
                                                               rel="noopener noreferrer"
                                                               style={{'textDecoration': 'none'}}>
                                                                <ListItemText
                                                                    className={classes.listDocument}
                                                                    primary={document.name}/>
                                                            </a>
                                                        </ListItemButton>))}
                                            </Fragment>)
                                        })}
                                    </List>
                                </Collapse>
                            </Fragment>))}
                        </List>
                    </Fragment>}
                    <Container>
                        <AppShareComponent type={'signing'}></AppShareComponent>
                    </Container>
                </Col>
            </Row>
            <Container className="bottom-with-navbar" style={{'backgroundColor': themeContext.color.neutral10}}></Container>
        </Container>
        <DialogSmall open={openNoRadioSelectedDialog}
                     title={t('signing.no-radio-selected-title')}
                     description={t('signing.no-radio-selected-description')}
                     handleClose={() => setOpenNoRadioSelectedDialog(false)}
                     buttonText={t('signing.no-radio-selected-button')}/>
        <Dialog
            onClose={() => {
                setOpenUploadDialog(false)
                setImages([addIcon])
                setImagesUploaded([])
                setIsUploadValid(false)
                setContentTypeError(false)
                setUploadError(false)
            }}
            fullScreen={!isDesktop}
            fullWidth={isDesktop}
            maxWidth={'sm'}
            PaperProps={{
                style: {
                    bottom: isDesktop ? '5%' : '0',
                    color: themeContext.color.neutral600,
                    borderTopLeftRadius: '20px',
                    borderTopRightRadius: '20px',
                    borderRadius: isDesktop ? '20px' : 'none',
                    height: isDesktop ? '90%' : '100%',
                    marginTop: '10%'
                }
            }}
            open={openUploadDialog}
            TransitionComponent={Transition}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle id="alert-dialog-slide-title" className="dialog-title"
                         style={{textAlign: 'center'}}>
                <Row style={{justifyContent: 'end'}}>
                    <img src={exitIcon} className="exit-button" alt="close" onClick={() => {
                        setOpenUploadDialog(false)
                        setImages([addIcon])
                        setImagesUploaded([])
                        setIsUploadValid(false)
                        setContentTypeError(false)
                        setUploadError(false)
                    }} style={{float: 'inline-end'}}/>
                </Row>
                <Row style={{marginTop: '-12px'}}>
                    <Col className="col-1">
                        <img className="align-baseline" src={getBranchIcon(selectedPolicyBranch)}
                             alt="type-policy-icon"/>
                    </Col>
                    <Col className="col-10">
                        <h6 className="dialog-form-title-1 float-left">{selectedPolicyBranch}</h6>
                    </Col>
                </Row>
                {
                    selectedDocumentStatus !== null && selectedDocumentStatus.startsWith('rejected:') &&
                    <Row>
                        <Col>
                            <h6 className="dialog-form-title-2 float-left text-left">
                                {t('signing.re-upload-subtitle')}
                            </h6>
                        </Col>
                    </Row>
                }
                {
                    selectedDocumentStatus !== null && !selectedDocumentStatus.startsWith('rejected:') &&
                    <Fragment>
                        <Row>
                            <Col>
                                <h6 className="dialog-form-title-2 float-left text-left">
                                    {t('signing.upload-subtitle')}
                                </h6>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <h6 className="dialog-form-title-3 float-left text-left">
                                    {t('signing.upload-description')}
                                </h6>
                            </Col>
                        </Row>
                    </Fragment>
                }
            </DialogTitle>
            <DialogContent className="no-scrollbar">
                {
                    isLoadingData &&
                    <div style={{'height': '30vh'}} className="background-loading-spinner"></div>
                }
                {!isLoadingData && uploadError && <ErrorPage/> }
                {
                    !isLoadingData && !uploadError &&
                    <div className="dialog-main-container d-flex flex-column justify-content-between h-100"
                         style={{gap: 16}}>
                        {renderCustomThumbs(document)}
                        {
                            images.map((item, index) => (
                                <Fragment key={index}>
                                    <input id="image-file" type="file" onChange={handleChange} hidden/>
                                </Fragment>
                            ))
                        }
                        {
                            contentTypeError &&
                            <div className="px-4 text-center dialog-form-title-3 red">
                                <span
                                        dangerouslySetInnerHTML={{__html: t('The uploaded format is not supported') + '<br />' + t('Only png, jpeg, pdf and all digital signature file types are supported')}}/>
                                </div>
                            }
                            {
                                sizeExceededError &&
                                <div className="px-4 text-center dialog-form-title-3 red">
                                <span
                                    dangerouslySetInnerHTML={{__html: t('File not supported') + '<br />' + t('The maximum file upload size is 20MB')}}/>
                                </div>
                            }
                            {
                                !isUploadValid && selectedDocumentStatus !== null && selectedDocumentStatus.startsWith('rejected:') &&
                                <Fragment>
                                    <div className={'d-flex justify-content-center flex-column'} style={{gap: 15}}>
                                        {
                                            imagesUploaded.map((item, index) => (
                                                <div className={'d-flex justify-content-center'} key={index}>
                                                    <img src={item.thumb_url !== "" ? item.thumb_url : pdfIcon}
                                                         alt={item.url}
                                                         onClick={() => {
                                                             openFile(item);
                                                         }}
                                                         style={item.thumb_url !== "" ?
                                                             {
                                                                 boxShadow: `0px 3px 10px 0px ${themeContext.color.boxShadow}`,
                                                                 borderRadius: "8px", height: 100, width: 100
                                                             } : {width: 94}
                                                         }/>
                                                </div>
                                            ))
                                        }
                                        <h6 className={'font-weight-bold text-center'}
                                            style={{color: themeContext.color.red}}>{trimDescription(selectedDocumentStatus)}</h6>
                                        {
                                            dateFirstUpdate &&
                                            <Row>
                                                <Col className="mt-2">
                                                    <h6 className="dialog-form-title-3">{t('signing.re-uploaded-creation-date')}
                                                        <span className="dialog-form-title-3">
                                                    <Moment locale="it" format="DD/MM/YYYY">
                                                        {dateFirstUpdate}
                                                    </Moment>
                                                </span>
                                                    </h6>
                                                </Col>
                                                <Col className="mt-2">
                                                    <h6 className="dialog-form-title-3 float-right">{t('signing.re-uploaded-creation-time')}
                                                        <span className="dialog-form-title-3">
                                                    <Moment locale="it" format="HH:mm">
                                                        {dateFirstUpdate}
                                                    </Moment>
                                                </span>
                                                </h6>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            </Fragment>
                        }
                        {
                            images.length === 1 && (selectedDocumentStatus && !selectedDocumentStatus.startsWith('rejected')) &&
                            <div className="px-4 text-center dialog-form-title-3">
                                {t('signing.nothing-text-2')}
                            </div>
                        }
                        <div className={'d-flex justify-content-center'} style={{paddingBottom: 48}}>
                            <Button
                                className={!isUploadValid ? "disable-button" : "primary-button"}
                                variant="outlined"
                                size="large"
                                style={{
                                    color: themeContext.color.white,
                                    width: '288px',
                                    textTransform: 'none'
                                }}
                                type="submit"
                                disabled={!isUploadValid}
                                onClick={() => {
                                    setOpenUploadConfirmDialog(true)
                                }}>{t('signing.save-button')}
                            </Button>
                        </div>
                    </div>
                }
            </DialogContent>
        </Dialog>
        <Dialog
            fullScreen={!isDesktop}
            fullWidth={isDesktop}
            maxWidth={'sm'}
            PaperProps={{
                style: {
                    bottom: isDesktop ? '5%' : '0',
                    color: themeContext.color.neutral600,
                    borderTopLeftRadius: '20px',
                    borderTopRightRadius: '20px',
                    borderRadius: isDesktop ? '20px' : 'none',
                    height: isDesktop ? '90%' : '100%',
                    marginTop: '10%'
                }
            }}
            open={openUploadedDialog}
            TransitionComponent={Transition}
            onClose={() => {
                setImagesUploaded([])
                setDateFirstUpdate(null)
                setOpenUploadedDialog(false)
                setContentTypeError(false)
                setUploadError(false)
            }}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle id="alert-dialog-slide-title" className="dialog-title"
                         style={{textAlign: 'center'}}>
                <Row style={{justifyContent: 'end'}}>
                    <img src={exitIcon} className="exit-button" alt="close" onClick={() => {
                        setImagesUploaded([])
                        setDateFirstUpdate(null)
                        setOpenUploadedDialog(false)
                        setContentTypeError(false)
                        setUploadError(false)
                    }} style={{float: 'inline-end'}}/>
                </Row>
                <Row style={{marginTop: '-12px'}}>
                    <Col className="col-1">
                        <img className="align-baseline" src={getBranchIcon(selectedPolicyBranch)}
                             alt="type-policy-icon"/>
                    </Col>
                    <Col className="col-10">
                        <h6 className="dialog-form-title-1 float-left">{selectedPolicyBranch}</h6>
                    </Col>
                </Row>
            </DialogTitle>
            <DialogContent className="no-scrollbar">
                <div className="dialog-main-container"
                     style={{paddingBottom: "96px"}}>
                    {isLoadingData &&
                        <div style={{'height': '30vh'}} className="background-loading-spinner"></div>
                    }
                    {!isLoadingData && uploadError && <ErrorPage/> }
                    {
                        !isLoadingData && !uploadError &&
                        <div>
                            {
                                dateFirstUpdate &&
                                <Row>
                                    <Col className="mt-2">
                                        <h6 className="dialog-form-title-3">{t('signing.re-uploaded-creation-date')}
                                            <span className="dialog-form-title-3">
                                                    <Moment locale="it" format="DD/MM/YYYY">
                                                        {dateFirstUpdate}
                                                    </Moment>
                                                </span>
                                            </h6>
                                        </Col>
                                        <Col className="mt-2">
                                            <h6 className="dialog-form-title-3 float-right">{t('signing.re-uploaded-creation-time')}
                                                <span className="dialog-form-title-3">
                                                    <Moment locale="it" format="HH:mm">
                                                        {dateFirstUpdate}
                                                    </Moment>
                                                </span>
                                            </h6>
                                        </Col>
                                    </Row>
                                }
                                <div>
                                    {
                                        imagesUploaded.map((item, index) => (
                                            <div className={'d-flex justify-content-center'} key={index}
                                                 style={{marginTop: 40}}>
                                                <img src={item.thumb_url !== "" ? item.thumb_url : pdfIcon}
                                                     alt={item.url}
                                                     onClick={() => {
                                                         openFile(item);
                                                     }}
                                                     style={item.thumb_url !== "" ?
                                                         {
                                                             boxShadow: `0px 3px 10px 0px ${themeContext.color.boxShadow}`,
                                                             borderRadius: "8px", height: 100, width: 100
                                                         } : {width: 94}
                                                     }/>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                components={{
                    Backdrop: StyledBackdrop
                }}
                PaperProps={{style: {color: themeContext.color.neutral600, padding: '24px'}}}
                open={openSignForceDialog}
                onClose={() => setOpenSignForceDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <h6 className={classes.dialogTitle}>{t('signing.quickly-selected-title')}</h6>
                <h6 className={classes.dialogDescription}>{t('signing.quickly-selected-description')}</h6>
                <DialogActions className="justify-content-center">
                    <Button className="primary-button"
                            onClick={() => {
                                linkToUploadDocumentToVerify(selectedPersonId, selectedIdDocument, selectedPolicyId, selectedTitleId, selectedGender)
                            }}
                            variant="outlined"
                            size="large"
                            type="submit"
                            style={{
                                'color': themeContext.color.white,
                                'marginTop': '12px',
                                'textTransform': 'none',
                                'height': '40px',
                                'width': '300px'
                            }}>{t('signing.sign-button')}
                    </Button>
                </DialogActions>
                <DialogActions className="justify-content-center">
                    <Button
                        className={selectedDocumentStatus && selectedDocumentStatus.startsWith('rejected:') ? "outline-delete-button" : "outline-button"}
                        onClick={() => {
                            setOpenSignForceDialog(false)
                            if (selectedDocumentStatus.startsWith('rejected:')) {
                                uploadedDocuments(selectedTitleId, false)
                            }
                            setOpenUploadDialog(true)
                        }}
                        variant="outlined"
                        size="large"
                        type="submit"
                        style={{
                            'color': themeContext.color.white,
                            'marginTop': '4px',
                            'textTransform': 'none',
                            'height': '40px',
                            'width': '300px'
                        }}>
                        {selectedDocumentStatus && selectedDocumentStatus.startsWith('rejected:') ? t('signing.re-upload-button') : t('signing.upload-button')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                components={{
                    Backdrop: StyledBackdrop
                }}
                PaperProps={{style: {color: themeContext.color.neutral600, padding: '24px'}}}
                open={reOpenSignForceDialog}
                onClose={() => setReOpenSignForceDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <h6 className={classes.dialogTitle}>{t('signing.quickly-selected-title')}</h6>
                <h6 className={classes.dialogDescription}>{t('signing.quickly-selected-description')}</h6>
                <DialogActions className="justify-content-center">
                    <Button className="primary-button"
                            onClick={() => {
                                linkToUploadDocumentToVerify(selectedPersonId, selectedIdDocument, selectedPolicyId, selectedTitleId, selectedGender)
                            }}
                            variant="outlined"
                            size="large"
                            type="submit"
                            style={{
                                'color': themeContext.color.white,
                                'marginTop': '12px',
                                'textTransform': 'none',
                                'height': '40px',
                                'width': '300px'
                            }}>{t('signing.sign-button')}
                    </Button>
                </DialogActions>
                <DialogActions className="justify-content-center">
                    <Button
                        className={selectedDocumentStatus && selectedDocumentStatus.startsWith('rejected:') ? "outline-delete-button" : "outline-button"}
                        onClick={() => {
                            setReOpenSignForceDialog(false)
                            if (selectedDocumentStatus.startsWith('rejected:')) {
                                uploadedDocuments(selectedTitleId, false)
                            }
                            setOpenUploadDialog(true)
                        }}
                        variant="outlined"
                        size="large"
                        type="submit"
                        style={{
                            'color': themeContext.color.white,
                            'marginTop': '4px',
                            'textTransform': 'none',
                            'height': '40px',
                            'width': '300px'
                        }}>
                        {selectedDocumentStatus && selectedDocumentStatus.startsWith('rejected:') ? t('signing.re-upload-button') : t('signing.upload-button')}
                    </Button>
                </DialogActions>
            </Dialog>
            <DialogDoubleAction open={openUploadConfirmDialog}
                                title={t('signing.upload-confirm-title')}
                                description={t('signing.upload-confirm-description')}
                                handleClose={() => setOpenUploadConfirmDialog(false)}
                                handleNo={() => setOpenUploadConfirmDialog(false)}
                                handleYes={() => {
                                    uploadDocument(selectedTitleId, selectedFilename)
                                    setOpenUploadConfirmDialog(false)
                                    setOpenUploadDialog(false)
                                }}
                                buttonYesText={t('signing.save-button')}
                                buttonNoText={t('settings.modal-refuse-button')}/>
        </Fragment>)
}
