import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../images/back-icon.svg";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from 'react-i18next'
import documentPlaceholder from '../../images/document-photo-placeholder.svg';
import exit from "../../images/exit-icon.svg";
import PlaceholderScreen from "../components/PlaceholderScreen.js";
import DialogDoubleAction from "../components/DialogDoubleAction.js";
import DialogSmall from "../components/DialogSmall.js";
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";
import themeContext from "../components/ThemeContext.js";
import ErrorPage from "../pages/ErrorPage.js";

export default function UploadBankRefs() {
    const root = 'changeIBAN';
    const navigate = useNavigate();
    const params = useParams();
    const personId = params.personId;
    const {t} = useTranslation();
    const [isLoadingData, setIsLoadingData] = useState(false);
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [openExitDialog, setOpenExitDialog] = useState(false);
    const [openFallbackDialog, setOpenFallbackDialog] = useState(false);
    const authCtx = useContext(AuthContextMiddleware);
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    useEffect(() => {
        if (navigator.onLine) {
            console.log(authCtx.bankRefsToUpdate,authCtx.bankRefsToUpdate.iban, authCtx.bankRefsToUpdate.personId, authCtx.bankRefsToUpdate.accountHolder )
            if (authCtx.bankRefsToUpdate.iban === '' && authCtx.bankRefsToUpdate.personId === '' && authCtx.bankRefsToUpdate.accountHolder === '') {
                //console.log('dati bancari non presenti')
                setOpenFallbackDialog(true)
            }
        }
    }, []);

    function reload() {
        window.location.reload();
    }

    const turnBack = () => {
        return navigate(-1);
    }

    const navigateToPersonalDocuments = () => {
        if (window.location.href.includes('refunds')) {
            return navigate(`/refunds/${personId}/upload/personalDocuments`);
        } else {
            return navigate(`/settings/people/${personId}/bank-references/upload/personalDocuments`);
        }
    }

    return (
        <Fragment>
            { errorPage && <ErrorPage/> }
            {
                !errorPage &&
                <Container className="mt-0 full-height linear-gradient">
                    <div style={{'opacity': '1'}}>
                        <Row className="nav-top container border-bottom"
                             style={{
                                 'backgroundColor': themeContext.color.white,
                                 'paddingTop': '19.5px',
                                 'paddingBottom': '20px',
                                 'paddingLeft': '0',
                                 'paddingRight': '0',
                             }}>
                            <Col className="col-1 float-left">
                                <img src={back} onClick={turnBack} className="nav-top-logo float-left cursor-pointer"
                                     height="27px"
                                     alt="back"/>
                            </Col>
                            <Col className="col-10">
                                <h6 className="header-title ellipsis-1-v2">{t('uploadDocumentToVerify.title')}</h6>
                            </Col>
                            <Col className="col-1">
                                <img src={exit} onClick={() => setOpenExitDialog(true)}
                                     className="nav-top-logo float-right cursor-pointer" alt="exit-icon" height="27px"/>
                            </Col>
                        </Row>
                        <Container className="top-of-the-preferences"></Container>
                        <PlaceholderScreen isLoadingData={isLoadingData} title={t('uploadDocumentToVerify.subtitle')}
                                           description={t(`${root}.description`)}
                                           placeholder={documentPlaceholder}
                                           buttonText={t('uploadDocumentToVerify.button')}
                                           buttonAction={navigateToPersonalDocuments}/>
                    </div>
                </Container>
            }
            <DialogDoubleAction
                open={openExitDialog}
                handleClose={() => setOpenExitDialog(false)}
                title={t('dialog.interruption-title')}
                description={t('dialog.interruption-description')}
                buttonYesText={t('dialog.button-yes')}
                handleYes={() => {
                    navigate(`/refunds`);
                }}
                buttonNoText={t('dialog.button-no')}
                handleNo={() => setOpenExitDialog(false)}
            ></DialogDoubleAction>

            <DialogSmall buttonText={t('uploadDocumentToVerify.fallback-dialog-button')}
                         title={t('uploadDocumentToVerify.fallback-dialog-title')}
                         description={t('uploadDocumentToVerify.fallback-dialog-description')}
                         handleClose={() => {
                             if (window.location.href.includes('refunds')) {
                                 navigate(`/refunds`);
                             } else if (window.location.href.includes('bank-references')) {
                                 navigate(`/settings/people/${personId}/bank-references`);
                             }
                         }}
                         open={openFallbackDialog}/>
        </Fragment>
    )
}