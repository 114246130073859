import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useLocation, useNavigate} from 'react-router-dom'
import AuthContextMiddleware from '../middleware/AuthContextMiddleware.js';
import {Button, InputAdornment, Link, Slide, TextField} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import wideLogoLightBetaBig from '../../images/wide-logo-white.svg';
import wideLogoLight from "../../images/wideLogo-icon.svg";
import Divider from "@mui/material/Divider";
import {makeStyles, withStyles} from "@mui/styles";
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from "@mui/material/ClickAwayListener";
import DialogSmall from "../components/DialogSmall.js";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";
import {regexPassword} from "../service/UtilityService.js";
import {StyledBackdrop} from "../styles/Styles.js";
import ErrorPage from "../pages/ErrorPage.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: themeContext.color.neutral600,
        color: themeContext.color.neutral10,
        fontSize: '12px',
        width: '100%',
        lineHeight: '1.5',
        marginTop: '8px'
    },
}))(Tooltip);

const useStyles = makeStyles(() => ({
    dialogTitle: {
        'textAlign': 'center !important',
        'lineHeight': '1.3 !important',
        'padding': '16px 24px !important',
        'fontSize': '15px !important',
        'fontWeight': '500 !important'
    },
}));

export default function NewPassword() {

    window.addEventListener('offline', () => {
        window.location.reload();
        return 0;
    });

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const invitationToken = query.get('invitation_token');
    const authCtx = useContext(AuthContextMiddleware);
    const navigate = useNavigate();
    const token = sessionStorage.getItem('token');
    const [passwordDialog, setPasswordDialog] = useState(false);
    const newPasswordUrl = `${process.env.REACT_APP_BASE_URL}/management/profile/newPassword`;
    const checkPasswordUrl = `${process.env.REACT_APP_BASE_URL}/management/profile/checkPassword`;
    const verifyTokenUrl = `${process.env.REACT_APP_BASE_URL}/management/auth/invitation/verify`;
    const loginUrl = `${process.env.REACT_APP_BASE_URL}/management/auth/login`;
    const [newPasswordVisibility, setNewPasswordVisibility] = useState(false);
    const [confirmNewPasswordVisibility, setConfirmNewPasswordVisibility] = useState(false);
    const [passwordCriteriasError, setPasswordCriteriasError] = useState(false);
    const [passwordNotEqualError, setPasswordNotEqualError] = useState(false);
    const [isPassword, setIsPassword] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [email, setEmail] = useState("")
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [openSupportDialog, setOpenSupportDialog] = useState(false);
    const [openTooltip, setOpenTooltip] = useState(false)
    const {t, i18n} = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        if (navigator.onLine) {
            checkPassword();
        }
    }, []);

    const defaultValues = {
        newPassword: "",
        confirmNewPassword: "",
    };
    const formValues = useRef(defaultValues);

    const checkPassword = () => {
        setIsLoadingData(true)
        fetch(checkPasswordUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: invitationToken
            }),
        }).then((res) => {
            return res.json();
        }).then((res) => {
            if (res.ok) {
                setEmail(res.message)
                setIsPassword(false)
            } else {
                setIsPassword(true)
                if (res.status === 500) {
                    setIsValid(false)
                }
            }
            setIsLoadingData(false)
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const submitHandler = () => {
        setOpenTooltip(false)
        if (formValues.current.newPassword === formValues.current.confirmNewPassword && (formValues.current.newPassword !== "" && formValues.current.confirmNewPassword !== "")) {
            if (formValues.current.newPassword.match(regexPassword)) {
                sessionStorage.setItem('newPassword', formValues.current.newPassword)
                return navigate(`/users/invitation/accept/otp?invitation_token=${invitationToken}`)
            } else {
                setPasswordNotEqualError(false);
                setPasswordCriteriasError(true)
                setPasswordDialog(true)
            }
        } else {
            setPasswordCriteriasError(false)
            setPasswordNotEqualError(true);
            setPasswordDialog(true);
        }
    }

    const handleInputChange = (e) => {
        const {
            name,
            value
        } = e.target;
        formValues.current = {
            ...formValues.current,
            [name]: value,
        }
    };

    const handlePasswordDialog = () => {
        setPasswordDialog(current => !current);
    }

    const handleNewPasswordVisibility = e => {
        const password = document.querySelector("#newPassword")
        const type = password.getAttribute("type") === "password" ? "text" : "password";
        password.setAttribute("type", type);
        setNewPasswordVisibility(current => !current)
    }

    const handleConfirmNewPasswordVisibility = e => {
        const password = document.querySelector("#confirmNewPassword")
        const type = password.getAttribute("type") === "password" ? "text" : "password";
        password.setAttribute("type", type);
        setConfirmNewPasswordVisibility(current => !current)
    }

    const navigateToLogin = e => {
        return navigate('/login')
    }

    function reload() {
        window.location.reload();
    }

    function MailTo({
                        email,
                        subject,
                        body,
                        ...props
                    }) {
        return (
            <a style={{
                'color': themeContext.color.main,
                'textAlign': "center",
                'textDecoration': 'none',
                'width': '100%'
            }}
               href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
                {props.children}
            </a>
        );
    }

    const linkToRequestCredentials = () => {
        return navigate('/login/request-credentials');
    }

    const handleCloseSupportDialog = () => {
        setOpenSupportDialog(false);
    }

    return (
        <div className="container-fluid" style={{'backgroundColor': themeContext.color.white}}>
            <div className="row no-gutter">
                <div className="col-md-6 d-none d-md-flex bg-image">
                    <Row style={{'margin': 'auto'}}>
                        <Col>
                            <img style={{
                                'height': '74.67px',
                                'marginBottom': '27px'
                            }} src={wideLogoLightBetaBig} alt="wide-logo-light"/>
                            <h6 style={{
                                'color': themeContext.color.white,
                                'fontSize': '16px',
                                'fontWeight': '300'
                            }}>{t('login.payoff-left-1')}</h6>
                            <h6 style={{
                                'color': themeContext.color.white,
                                'fontSize': '16px',
                                'fontWeight': '300'
                            }}>{t('login.payoff-left-2')}</h6>
                            <h6 style={{
                                'color': themeContext.color.white,
                                'fontSize': '16px',
                                'fontWeight': '300'
                            }}>{t('login.payoff-left-3')}</h6>
                        </Col>
                    </Row>
                </div>
                <div className="col-md-6">
                    <div className="login d-flex">
                        <div className="container">
                            <Row className="d-flex justify-content-center ">
                                { errorPage && <ErrorPage/> }
                                {!errorPage && isLoadingData &&
                                    <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                                }
                                {!errorPage && !isLoadingData && !isPassword && isValid &&
                                    <Container>
                                        <Row style={{'marginTop': '36%'}}>
                                            <Col>
                                                <img src={wideLogoLight} height="60px"
                                                     alt="wide-logo"/>
                                            </Col>
                                        </Row>
                                        <Row style={{'marginTop': '32px'}}>
                                            <Col>
                                                <h2 className="login-title align-center">{t('newPassword.title')}</h2>
                                            </Col>
                                        </Row>
                                        <Row style={{'marginTop': '30px'}}>
                                            <Col>
                                                <TextField
                                                    id="email"
                                                    className="custom-textfield mb-4"
                                                    fullWidth
                                                    //label={t('newPassword.email')}
                                                    variant="outlined"
                                                    name="email"
                                                    type="email"
                                                    value={email}
                                                    disabled={true}
                                                    color="primary"
                                                    InputProps={{
                                                        style: {
                                                            'borderRadius': '4px'
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                                                    <HtmlTooltip
                                                        open={openTooltip}
                                                        onClose={() => setOpenTooltip(false)}
                                                        disableFocusListener
                                                        disableHoverListener
                                                        disableTouchListener
                                                        title={
                                                            <React.Fragment>
                                                                <h6 style={{'fontSize': '12px'}}>{t('newPassword.tooltip-title')}</h6>
                                                                <li>
                                                                    {t('newPassword.tooltip-1')}
                                                                </li>
                                                                <li>
                                                                    {t('newPassword.tooltip-2')}
                                                                </li>
                                                                <li>
                                                                    {t('newPassword.tooltip-3')}
                                                                </li>
                                                                <li>
                                                                    {t('newPassword.tooltip-4')}
                                                                </li>
                                                                <li>
                                                                    {t('newPassword.tooltip-5')}
                                                                </li>
                                                                <h6 className="mt-2 text-uppercase"
                                                                    style={{'fontSize': '12px'}}>{t('newPassword.tooltip-6')}</h6>
                                                                <h6 style={{
                                                                    'fontSize': '12px',
                                                                    'color': themeContext.color.neutral200
                                                                }}>{t('newPassword.tooltip-7')}</h6>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <TextField
                                                            id="newPassword"
                                                            className="custom-textfield mb-4"
                                                            fullWidth
                                                            label={t('newPassword.new-password-textfield')}
                                                            variant="outlined"
                                                            name="newPassword"
                                                            type="password"
                                                            required={true}
                                                            value={formValues.newPassword}
                                                            onChange={handleInputChange}
                                                            onClick={() => setOpenTooltip(true)}
                                                            color="primary"
                                                            error={passwordNotEqualError || passwordCriteriasError}
                                                            InputProps={{
                                                                style: {
                                                                    'borderRadius': '4px'
                                                                },
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        {newPasswordVisibility &&
                                                                            <span
                                                                                className="material-icons toggle-password float-right unselectable darkgrey"
                                                                                onClick={handleNewPasswordVisibility}>visibility_off</span>}
                                                                        {!newPasswordVisibility &&
                                                                            <span
                                                                                className="material-icons toggle-password float-right unselectable darkgrey"
                                                                                onClick={handleNewPasswordVisibility}>visibility</span>}
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </HtmlTooltip>
                                                </ClickAwayListener>

                                            </Col>
                                        </Row>

                                        {openTooltip ? <Row style={{'marginTop': '200px'}}>
                                            </Row> :
                                            <Row style={{'marginTop': '0px'}}>
                                            </Row>
                                        }
                                        <Row>
                                            <Col>
                                                <TextField
                                                    id="confirmNewPassword"
                                                    className="custom-textfield"
                                                    fullWidth
                                                    label={t('newPassword.confirm-password-textfield')}
                                                    variant="outlined"
                                                    name="confirmNewPassword"
                                                    type="password"
                                                    required={true}
                                                    value={formValues.confirmNewPassword}
                                                    onChange={handleInputChange}
                                                    color="primary"
                                                    error={passwordNotEqualError || passwordCriteriasError}
                                                    InputProps={{
                                                        style: {
                                                            'borderRadius': '4px',
                                                            //'height': '50px',
                                                        },
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {confirmNewPasswordVisibility &&
                                                                    <span
                                                                        className="material-icons toggle-password float-right unselectable darkgrey"
                                                                        onClick={handleConfirmNewPasswordVisibility}>visibility_off</span>}
                                                                {!confirmNewPasswordVisibility &&
                                                                    <span
                                                                        className="material-icons toggle-password float-right unselectable darkgrey"
                                                                        onClick={handleConfirmNewPasswordVisibility}>visibility</span>}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col>
                                                <Link
                                                    className="float-left login-subtitle cursor-pointer"
                                                    color="primary" target="_new"
                                                    onClick={() => setOpenSupportDialog(true)} style={{
                                                    'color': themeContext.color.main
                                                }}>
                                                    {t('login.support-title')}
                                                </Link>
                                            </Col>
                                        </Row>
                                        <Row className="mb-4"
                                             style={openTooltip ? {'marginTop': '100px'} : {'marginTop': '145px'}}>
                                            <Col>
                                                <Button onClick={submitHandler} className="btn-custom-2 text-uppercase"
                                                        variant="outlined"
                                                        size="large" style={{'color': themeContext.color.white}}
                                                        type="submit">
                                                    {t('newPassword.submit-button')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                }

                                {!errorPage && !isLoadingData && isPassword && isValid &&
                                    <Container>
                                        <Row style={{'marginTop': '36%'}}>
                                            <Col>
                                                <img src={wideLogoLight} height="60px"
                                                     alt="wide-logo"/>
                                            </Col>
                                        </Row>
                                        <Row style={{'marginTop': '32px'}}>
                                            <Col>
                                                <h6 className="login-title align-center">{t('newPassword.isPassword-title')}</h6>
                                                <h6 className="mt-2">{t('newPassword.isPassword-description-1')}</h6>
                                                <h6 className="mt-1">{t('newPassword.isPassword-description-2')}</h6>
                                            </Col>
                                        </Row>
                                        <Row style={{
                                            'marginTop': '70px'
                                        }}>
                                            <Col>
                                                <Button className="btn-custom text-uppercase"
                                                        variant="outlined"
                                                        size="large"
                                                        type="submit"
                                                        style={{
                                                            'color': themeContext.color.white,
                                                            'width': '265px'
                                                        }}
                                                        onClick={navigateToLogin}
                                                >{t('newPassword.isPassword-button')}
                                                </Button>
                                            </Col>

                                        </Row>
                                        <Row className="mt-4">
                                            <Col>
                                                <Link
                                                    className="float-left login-subtitle cursor-pointer"
                                                    color="primary" target="_new"
                                                    onClick={() => setOpenSupportDialog(true)} style={{
                                                    'color': themeContext.color.main
                                                }}>
                                                    {t('login.support-title')}
                                                </Link>
                                            </Col>
                                        </Row>

                                    </Container>
                                }

                                {!errorPage && !isLoadingData && isPassword && !isValid &&
                                    <Container>
                                        <Row style={{'marginTop': '36%'}}>
                                            <Col>
                                                <img src={wideLogoLight} height="60px"
                                                     alt="wide-logo"/>
                                            </Col>
                                        </Row>
                                        <Row style={{'marginTop': '32px'}}>
                                            <Col>
                                                <h6 className="login-title align-center">{t('newPassword.isNotValid-title')}</h6>
                                                <h6 className="mt-2">{t('newPassword.isNotValid-description')}</h6>
                                            </Col>
                                        </Row>
                                        <Row style={{
                                            'marginTop': '100px'
                                        }}>
                                            <Col>
                                                <Button className="btn-custom text-uppercase"
                                                        variant="outlined"
                                                        size="large"
                                                        type="submit"
                                                        style={{
                                                            'color': themeContext.color.white,
                                                            'width': '265px'
                                                        }}
                                                        onClick={navigateToLogin}
                                                >{t('newPassword.isPassword-button')}
                                                </Button>
                                            </Col>

                                        </Row>
                                        <Row className="mt-4">
                                            <Col>
                                                <Link
                                                    className="float-left login-subtitle cursor-pointer"
                                                    color="primary" target="_new"
                                                    onClick={() => setOpenSupportDialog(true)} style={{
                                                    'color': themeContext.color.main
                                                }}>
                                                    {t('login.support-title')}
                                                </Link>
                                            </Col>
                                        </Row>
                                    </Container>
                                }

                                <DialogSmall open={passwordDialog}
                                             title={<Fragment>
                                                 {passwordNotEqualError && t('newPassword.modal-text')}
                                                 {passwordCriteriasError && t('newPassword.modal-criterias-title')}
                                             </Fragment>}
                                             description={passwordCriteriasError ? t('newPassword.tooltip-title') + '<ul>' +
                                                 '<li>' + t('newPassword.tooltip-1') + '</li>' +
                                                 '<li>' + t('newPassword.tooltip-2') + '</li>' +
                                                 '<li>' + t('newPassword.tooltip-3') + '</li>' +
                                                 '<li>' + t('newPassword.tooltip-4') + '</li>' +
                                                 '<li>' + t('newPassword.tooltip-5') + '</li></ul>' : ''}
                                             handleClose={handlePasswordDialog}
                                             buttonText={t('change-password.modal-button')}/>
                                <Dialog
                                    components={{Backdrop: StyledBackdrop}}
                                    open={openSupportDialog}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleCloseSupportDialog}
                                    aria-labelledby="alert-dialog-slide-title"
                                    aria-describedby="alert-dialog-slide-description"
                                    PaperProps={{
                                        style: {
                                            'position': 'absolute',
                                            'bottom': '0',
                                            'left': 'auto',
                                            'right': 'auto',
                                            'width': '95%',
                                            'borderRadius': '10px',
                                            'color': themeContext.color.neutral600
                                        },
                                    }}>
                                        <span className={classes.dialogTitle}>
                                        {t('login.support-title')}
                                        </span>
                                    <Divider/>
                                    <DialogActions>
                                        <MailTo email={process.env.REACT_APP_MY_WIDE_SUPPORT_EMAIL}
                                                subject={t('login.email-subject')}
                                            //body={`${t('login.email-body-1')}%0D%0A%0D%0A%0D%0A${t('login.email-body-2')}%0D%0A%0D%0A`}
                                                body={`${t('login.email-body-problem')}%0D%0A%0D%0A`}>
                                            <Button style={{
                                                'color': themeContext.color.main,
                                                'textTransform': 'none'
                                            }}
                                                    fullWidth>
                                                {t('login.support-contact-us')}
                                            </Button>
                                        </MailTo>
                                    </DialogActions>
                                    <Divider/>
                                    <DialogActions>
                                        <Button onClick={handleCloseSupportDialog}
                                                style={{
                                                    'color': themeContext.color.neutral400,
                                                    'textTransform': 'none'
                                                }}
                                                fullWidth>
                                            {t('login.refuse-button')}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}