import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import AuthContextMiddleware from '../middleware/AuthContextMiddleware.js';
import Container from 'react-bootstrap/Container';
import {Col, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {fetchToken} from "../middleware/Firebase.js";
import {
    deleteElementFromIndexedDB,
    getElementFromIndexedDB,
    isIpad,
    isMac,
    isStandalone,
    putElementFromIndexedDB
} from "../service/UtilityService.js";
import BellOff from '../../images/bell-icon.svg'
import BellOn from '../../images/bellOn-icon.svg'
import baseFemaleAvatar from '../../images/female-icon.svg'
import wideLogoLight from '../../images/wideLogo-icon.svg';
import {ClientJS} from 'clientjs';
import {makeStyles} from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Moment from "react-moment";
import car from "../../images/car-blue.svg";
import baseMaleAvatar from "../../images/male-icon.svg";
import {upperCaseFirst} from "upper-case-first";
import searchIcon from "../../images/search-icon.svg";
import exitIcon from "../../images/exit-icon.svg";
import nothing from "../../images/nothing-icon.svg";
import scrollbarUsersPlaceholder from "../../images/user-placeholder.svg";
import cardPlaceholder_min from "../../images/card-placeholder_min.svg";
import cardPlaceholder_678 from "../../images/card-placeholder_678.svg";
import cardPlaceholder_918 from "../../images/card-placeholder_918.svg";
import cardPlaceholder_full from "../../images/card-placeholder_full.svg";
import {useMediaQuery} from "react-responsive";
import {Button} from "@mui/material";
import Header from "../components/Header.js";
import baseCompanyAvatar from "../../images/company-avatar.svg";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";
import ErrorPage from "../pages/ErrorPage.js";
import {NavbarContext} from "../middleware/NavbarContext.js";

const useStyles = makeStyles((theme) => ({
    card: {
        margin: "0 auto !important",
        marginTop: "10px !important",
        marginBottom: "18px !important",
        background: `${themeContext.color.white} 0% 0% no-repeat padding-box !important`,
        boxShadow: `2px 3px 10px ${themeContext.color.boxShadow} !important`,
        borderRadius: "10px !important",
        opacity: "1 !important",
        minHeight: "140px !important"
    },
    titles: {
        color: `${themeContext.color.neutral200} !important`,
        textAlign: 'left !important',
        /*lineHeight: '1',*/
        fontWeight: '500 !important',
        fontSize: '12px !important',
        textTransform: 'uppercase !important',
    },
    cardAvatar: {
        float: "right !important",
        width: "35px !important",
        height: "35px !important",
        /*marginTop: "-8px"*/
    },
    avatar: {
        width: '56px !important',
        height: '56px !important',
        margin: '4px 7px 4px 7px !important',
    },
    bigAvatar: {
        width: '66px !important',
        height: '66px !important',
        margin: '0 0 0 5px !important',
        boxShadow: `0px 2px 5px ${themeContext.color.boxShadow} !important`,
    },
}));

export default function Claims() {
    const userInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/info`;
    const checkReadUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/checkRead`;
    const personalClaimsUrl = `${process.env.REACT_APP_BASE_URL}/claims/personal`;
    const peopleClaimsUrl = `${process.env.REACT_APP_BASE_URL}/claims/people`;
    const getPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/people`;
    const webAuthNRegistrationUrl = `${process.env.REACT_APP_BASE_URL}/management/webauthn/registration`;
    const searchPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/search`;

    const token = sessionStorage.getItem('token');
    const {t, i18n} = useTranslation();
    const [isLoadingPersonal, setIsLoadingPersonal] = useState(false);
    const [isLoadingPeople, setIsLoadingPeople] = useState(false);
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [isLoadingScrollbar, setIsLoadingScrollbar] = useState(false);
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState("");
    const [isNothingPersonal, setIsNothingPersonal] = useState(false);
    const [isNothingPeople, setIsNothingPeople] = useState(false);
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const {toggleNavbar} = useContext(NavbarContext);

    const width_1199 = useMediaQuery({
        query: '(min-width: 1199px)'
    })
    const width_991 = useMediaQuery({
        query: '(min-width: 991px)'
    })
    const width_767 = useMediaQuery({
        query: '(min-width: 766px)'
    })
    const width_min = useMediaQuery({
        query: '(min-width: 0px)'
    })

    useEffect(() => {
        toggleNavbar(true)
        sessionStorage.setItem('root', '/claims');
        if (navigator.onLine) {
            if (sessionStorage.getItem("notificationId") != null) {
                const url = sessionStorage.getItem("notificationId").replaceAll('"', '')
                navigateToMessageDetails(url);
            }
            getInfo();
            if (!searchMode) {
                getPeople();
            }
            handleRinging();
            if (!searchMode) {
                getPeopleClaims();
            }

        } else {
            /*
            getInfoOffline();
            getPeopleOffline();
            getPersonalClaimsOffline();
            getPeopleClaimsOffline();
             */
        }
        if (!localStorage.getItem('firstLogin')) {
            setOpenDialog(true);
        }
        localStorage.setItem('firstLogin', true);


        if (searchMode) {
            const delayDebounceFn = setTimeout(() => {
                searchPeople(searchValue)
            }, 1000)
            return () => clearTimeout(delayDebounceFn)
        }


    }, [searchValue]);

    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [userID, setUserID] = useState("");
    const [user, setUser] = useState("");
    const authCtx = useContext(AuthContextMiddleware);
    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({
        title: '',
        body: ''
    });
    const client = new ClientJS();
    const [openDialog, setOpenDialog] = useState(false);
    const [bellRinging, setBellRinging] = useState(false);
    const classes = useStyles();
    const [personalClaims, setPersonalClaims] = useState([]);
    const [peopleClaims, setPeopleClaims] = useState([]);
    const [people, setPeople] = useState([]);
    //const [selectedAvatar, setSelectedAvatar] = useState("");
    const selectedAvatar = useRef("null");
    const [searchMode, setSearchMode] = useState(false);
    const [timeoutAvatar, setTimeoutAvatar] = useState(false);
    const [noPeople, setNoPeople] = useState(false);
    const [getPermission, setGetPermission] = useState(false);
    //const [showNavigation, setShowNavigation] = useState(true);


    if (((client.isMobileIOS() || isIpad()) && client.getOSVersion() < "16.4") || ((client.isMobileIOS() || isIpad()) && !(client.getOSVersion() < "16.4") && !isStandalone()) || (isMac() && client.getOSVersion() < "13")) {
        //console.log('notification not allowed')
    } else if (!getPermission) {
        requestPermission();
        setGetPermission(true);
    }
    fetchToken();

    function requestPermission() {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
            }
        })
    }

    if (((client.isMobileIOS() || isIpad()) && client.getOSVersion() < "16.4") || ((client.isMobileIOS() || isIpad()) && !(client.getOSVersion() < "16.4") && !isStandalone()) || (isMac() && client.getOSVersion() < "13")) {
        //console.log('notification not allowed')
    } else {
        const channel = new BroadcastChannel('sw-messages');
        channel.onmessage = (event) => {
            setBellRinging(true)
        };
        channel.onmessageerror = (event) => {
        };
    }

    const navigateToMessageDetails = (url) => {
        return navigate(`/messages/principal/${url}`);
    }

    function getInfo() {
        fetch(userInfoUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            i18n.changeLanguage(data.preferredLanguage).then(r => {
            })
            if (data.status !== -1) {
                setUser(data)
                putElementFromIndexedDB('profileInfo', data)
                if (!!data.pinCode) {
                    putElementFromIndexedDB('pin', data.pinCode)
                    localStorage.setItem('pinIsActive', true)
                } else {
                    localStorage.removeItem('pinIsActive')
                    deleteElementFromIndexedDB('pin')
                }
            }
        }).catch(() => {
            setErrorPage(true)
        })
    }

    function getInfoOffline() {
        getElementFromIndexedDB('profileInfo').then((response) => {
            return response.value;
        }).then((data) => {
            setUser([])
            setUser(data)
        });
    }

    function reload() {
        window.location.reload();
    }

    function getPeople() {
        setIsLoadingScrollbar(true)
        fetch(getPeopleUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let count = 0;
            setPeople([])
            data.map((item, index) => {
                if (item.status !== -1) {
                    count++;
                    if (!personalClaims.includes(item)) {
                        setPeople(current => [...current, item])
                    }
                }
            })
            if (count === 0) {
                sessionStorage.setItem('noPeople', true);
                setNoPeople(true);
            } else {
                sessionStorage.removeItem('noPeople');
                setNoPeople(false);
            }
            putElementFromIndexedDB('people', data)
            setIsLoadingScrollbar(false)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    function getPeopleOffline() {
        getElementFromIndexedDB('people').then((response) => {
            return response.value;
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length === 0) {
                sessionStorage.setItem('noPeople', true);
            } else {
                sessionStorage.removeItem('noPeople');
            }
            setPeople([])
            for (let i = 0; i < length; i++) {
                if (!personalClaims.includes(data[i])) {
                    setPeople(current => [...current, data[i]])
                }
            }
        });
    }

    function getPersonalClaims() {
        setIsLoadingPersonal(true)
        fetch(personalClaimsUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let count = 0;
            setPersonalClaims([])
            data.map((item, index) => {
                if (item.status !== -1) {
                    count++;
                    if (!personalClaims.includes(item)) {
                        setPersonalClaims(current => [...current, item])
                    }
                }
            })
            if (count === 0) {
                setIsNothingPersonal(true)
            } else {
                setIsNothingPersonal(false)
            }

            setIsLoadingPersonal(false)
            putElementFromIndexedDB('personalClaims', data)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    function getPeopleClaims() {
        setIsLoadingPeople(true)
        fetch(peopleClaimsUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let count = 0;
            setPeopleClaims([])
            data.map((item, index) => {
                if (item.status !== -1) {
                    count++;
                    if (!peopleClaims.includes(item)) {
                        setPeopleClaims(current => [...current, item])
                    }
                }
            })
            if (count === 0) {
                setIsNothingPeople(true)
            } else {
                setIsNothingPeople(false)
            }
            setIsLoadingPeople(false)
            putElementFromIndexedDB('peopleClaims', data)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    function getPersonClaims(id) {
        setIsLoadingPeople(true)
        fetch(`${peopleClaimsUrl}?personId=${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let count = 0;
            setPeopleClaims([])
            data.map((item, index) => {
                if (item.status !== -1) {
                    count++;
                    if (!peopleClaims.includes(item)) {
                        setPeopleClaims(current => [...current, item])
                    }
                }
            })
            if (count === 0) {
                setIsNothingPeople(true)
            } else {
                setIsNothingPeople(false)
            }
            setIsLoadingPeople(false)
            putElementFromIndexedDB('peopleClaims', data)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    function getPersonalClaimsOffline() {
        setIsLoadingPersonal(true)
        getElementFromIndexedDB('personalClaims').then((response) => {
            return response.value;
        }).then((data) => {
            let length = Object.keys(data).length;
            setPersonalClaims([])
            for (let i = 0; i < length; i++) {
                if (!personalClaims.includes(data[i])) {
                    setPersonalClaims(current => [...current, data[i]])
                }
            }
            setIsLoadingPersonal(false)
        });
    }

    function getPeopleClaimsOffline() {
        getElementFromIndexedDB('peopleClaims').then((response) => {
            return response.value;
        }).then((data) => {
            let length = Object.keys(data).length;
            setPeopleClaims([])
            for (let i = 0; i < length; i++) {
                if (!peopleClaims.includes(data[i])) {
                    setPeopleClaims(current => [...current, data[i]])
                }
            }
        });
    }

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleRinging = () => {
        fetch(checkReadUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.toRead) {
                setBellRinging(true);
            } else {
                setBellRinging(false);
            }
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const setStatusColor = (value) => {
        switch (value) {
            case 1:
                return themeContext.color.neutral200;
            case 2:
                return themeContext.color.green;
            case 3:
                return themeContext.color.yellow;
            case 4:
                return themeContext.color.red;
        }
    }

    const handleClickPersonal = () => {
        setTimeoutAvatar(true);
        setPeopleClaims([]);
        if (selectedAvatar.current === 'personal') {
            selectedAvatar.current = "null";
            getPersonalClaims();
            getPeopleClaims()
        } else {
            selectedAvatar.current = 'personal';
            getPersonalClaims();
        }
        setTimeout(function () {
            setTimeoutAvatar(false);
        }, 1000);
    }

    const handleClickPerson = (id) => {
        setTimeoutAvatar(true);
        setPersonalClaims([]);
        setPeopleClaims([]);
        if (selectedAvatar.current === id) {
            selectedAvatar.current = "null";
            getPeopleClaims()
        } else {
            selectedAvatar.current = id;
            getPersonClaims(id);
        }
        setTimeout(function () {
            setTimeoutAvatar(false);
        }, 1000);

    }

    const handleSearchMode = () => {
        if (searchMode && searchValue !== "") {
            setSearchValue("")
            document.querySelector('#search-input').value = "";
            document.querySelector('#search-input').focus();
        } else {
            setSearchMode(current => !current);
            getPeople();
            getPeopleClaims();
        }
    }

    const searchPeople = (text) => {
        setIsLoadingSearch(true)
        fetch(`${searchPeopleUrl}?text=${text}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setPeople([]);
            data.map((item, index) => {
                if (item.status !== -1) {
                    setPeople(current => [...current, item])
                }
            })
            setIsLoadingSearch(false)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const nothingFunction = () => {
        // questa funziona non fa nulla
    }

    const onClickClaim = (id) => {
        return navigate(`/claims/claim/${id}`);
    }

    const setGrayScale = (value) => {
        switch (value) {
            case 1:
                return 'grayscale(1)';
            case 2:
            case 3:
            case 4:
                return '';
        }
    }

    return (
        errorPage ?
            <ErrorPage/>
            :
            <Fragment>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                    <Row className="nav-top container" style={{
                        'paddingLeft': '0',
                        'paddingRight': '0',
                    }}>
                        <Col>
                            <Header iconSrc={wideLogoLight}
                                    rightSrc={bellRinging ? BellOn : BellOff}
                                    rightAction={() => navigate('/messages')}/>
                            {!noPeople &&
                                <Fragment>
                                    <Row style={{'marginTop': '11px'}}>
                                        <Col className="col-auto float-left">
                                            <h6 className={classes.titles}>{t('claims.beneficiaries-title')}</h6>
                                        </Col>
                                        <Col className="col float-right" style={{'marginTop': '-7px'}}>
                                            {
                                                searchMode &&
                                                <Row className="div-search" style={{'marginRight': '0'}}>
                                                    <Col className="col-10">
                                                        <input id="search-input" className="input-search"
                                                               onChange={e => setSearchValue(e.target.value)}
                                                               autoFocus
                                                               style={{
                                                                   'border': 'none',
                                                                   'marginLeft': '-5px'
                                                               }}></input>
                                                    </Col>
                                                    <Col className="col-2"
                                                         style={{
                                                             'paddingRight': '2px',
                                                             'textAlign': 'right'
                                                         }}>
                                                        <img src={exitIcon} alt="close" onClick={handleSearchMode}
                                                             height="25px" width="25px"/>
                                                    </Col>
                                                </Row>
                                            }
                                            {
                                                !searchMode &&
                                                <img src={searchIcon}
                                                     className="nav-top-logo float-right cursor-pointer"
                                                     height="27px"
                                                     alt="search-icon"
                                                     onClick={handleSearchMode}/>
                                            }

                                        </Col>
                                    </Row>

                                    {isLoadingSearch &&
                                        <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                                    }
                                    {
                                        !isLoadingSearch &&
                                        <Row style={{'marginTop': '10px'}}>
                                            <Col className="col-list">
                                                <Container className="people-scrollable-row">
                                                    {
                                                        /*
                                                        <div className="card card-avatar fixed-avatar">
                                                        <CardContent className="card-avatar-content"
                                                                     style={{'marginRight': '10px'}}>
                                                            <Row style={{'width': '50px'}}>
                                                                {
                                                                    user.image === null && user.gender === 'M' &&
                                                                    <Avatar alt="my-avatar" src={baseMaleAvatar}
                                                                            className={selectedAvatar.current === 'personal' ? classes.bigAvatar : classes.avatar}
                                                                            style={{
                                                                                filter: `${setGrayScale(user.status)}`,
                                                                                border: `3px solid ${setStatusColor(user.status)}`
                                                                            }}
                                                                            onClick={!timeoutAvatar ? handleClickPersonal : nothingFunction}/>
                                                                }
                                                                {
                                                                    user.image === null && user.gender === 'F' &&
                                                                    <Avatar alt="my-avatar" src={baseFemaleAvatar}
                                                                            className={selectedAvatar.current === 'personal' ? classes.bigAvatar : classes.avatar}
                                                                            style={{
                                                                                filter: `${setGrayScale(user.status)}`,
                                                                                border: `3px solid ${setStatusColor(user.status)}`
                                                                            }}
                                                                            onClick={!timeoutAvatar ? handleClickPersonal : nothingFunction}/>
                                                                }
                                                                {
                                                                    user.image === null && user.gender === 'C' &&
                                                                    <Avatar alt="my-avatar" src={baseMaleAvatar}
                                                                            className={selectedAvatar.current === 'personal' ? classes.bigAvatar : classes.avatar}
                                                                            style={{
                                                                                filter: `${setGrayScale(user.status)}`,
                                                                                border: `3px solid ${setStatusColor(user.status)}`
                                                                            }}
                                                                            onClick={!timeoutAvatar ? handleClickPersonal : nothingFunction}/>
                                                                }
                                                                {
                                                                    user.image === null && user.gender === null &&
                                                                    <Avatar alt="my-avatar" src={baseMaleAvatar}
                                                                            className={selectedAvatar.current === 'personal' ? classes.bigAvatar : classes.avatar}
                                                                            style={{
                                                                                filter: `${setGrayScale(user.status)}`,
                                                                                border: `3px solid ${setStatusColor(user.status)}`
                                                                            }}
                                                                            onClick={!timeoutAvatar ? handleClickPersonal : nothingFunction}/>
                                                                }
                                                                {
                                                                    user.image &&
                                                                    <Avatar alt="my-avatar" src={user.image}
                                                                            className={selectedAvatar.current === 'personal' ? classes.bigAvatar : classes.avatar}
                                                                            style={{
                                                                                filter: `${setGrayScale(user.status)}`,
                                                                                border: `3px solid ${setStatusColor(user.status)}`
                                                                            }}
                                                                            onClick={!timeoutAvatar ? handleClickPersonal : nothingFunction}/>
                                                                }
                                                            </Row>
                                                            <Row
                                                                style={selectedAvatar.current === 'personal' ? {'width': '73px'} : {'width': '67px'}}>
                                                                <h6 className={selectedAvatar.current === 'personal' ? "avatar-text-big text-uppercase" : "avatar-text text-uppercase"}
                                                                    style={selectedAvatar.current === 'personal' ? {color: `${setStatusColor(user.status)}`} : {}}>
                                                                    {user.firstName === null && user.lastName === null ?
                                                                        <span
                                                                            className="text-uppercase ellipsis-1">{user.name} </span> :
                                                                        <Fragment>
                                                                        <span
                                                                            className="text-uppercase ellipsis-1">{user.firstName} </span>
                                                                            <span
                                                                                className="text-uppercase ellipsis-1">{user.lastName}</span>
                                                                        </Fragment>
                                                                    }
                                                                </h6>
                                                            </Row>
                                                        </CardContent>
                                                    </div>
                                                         */
                                                    }
                                                    {
                                                        isLoadingScrollbar &&
                                                        <Row className="mt-2 w-100">
                                                            <Col style={{textAlign: 'left'}}>
                                                                <img src={scrollbarUsersPlaceholder}
                                                                     alt="card-scrollbar" className="mr-1"/>
                                                                <img src={scrollbarUsersPlaceholder}
                                                                     alt="card-scrollbar" className="mr-1"/>
                                                                <img src={scrollbarUsersPlaceholder}
                                                                     alt="card-scrollbar" className="mr-1"/>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    {!isLoadingScrollbar &&
                                                        <Container className="scrolling-wrapper-flexbox no-scrollbar"
                                                                   id="scroll-container">
                                                            {
                                                                people.map((person, index) => (
                                                                    <div className="card card-avatar" key={index}
                                                                         style={{backgroundColor: themeContext.color.neutral10}}>
                                                                        <CardContent className="card-avatar-content"
                                                                                     style={{backgroundColor: themeContext.color.neutral10}}>
                                                                            <Row style={{'width': '50px'}}>

                                                                                {
                                                                                    person.image === null && person.gender === 'M' &&
                                                                                    <Avatar alt="my-avatar"
                                                                                            src={baseMaleAvatar}
                                                                                            className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                                            style={{
                                                                                                filter: `${setGrayScale(person.status)}`,
                                                                                                border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                                            }}
                                                                                            onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : nothingFunction}/>
                                                                                }
                                                                                {
                                                                                    person.image === null && person.gender === 'F' &&
                                                                                    <Avatar alt="my-avatar"
                                                                                            src={baseFemaleAvatar}
                                                                                            className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                                            style={{
                                                                                                filter: `${setGrayScale(person.status)}`,
                                                                                                border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                                            }}
                                                                                            onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : nothingFunction}/>
                                                                                }
                                                                                {
                                                                                    person.image === null && person.gender === 'C' &&
                                                                                    <Avatar alt="my-avatar"
                                                                                            src={baseCompanyAvatar}
                                                                                            className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                                            style={{
                                                                                                filter: `${setGrayScale(person.status)}`,
                                                                                                border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                                            }}
                                                                                            onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : nothingFunction}/>
                                                                                }
                                                                                {
                                                                                    person.image === null && person.gender === null &&
                                                                                    <Avatar alt="my-avatar"
                                                                                            src={baseMaleAvatar}
                                                                                            className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                                            style={{
                                                                                                filter: `${setGrayScale(person.status)}`,
                                                                                                border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                                            }}
                                                                                            onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : nothingFunction}/>
                                                                                }
                                                                                {
                                                                                    person.image &&
                                                                                    <Avatar alt="my-avatar"
                                                                                            src={person.image}
                                                                                            className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                                            style={{
                                                                                                filter: `${setGrayScale(person.status)}`,
                                                                                                border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                                            }}
                                                                                            onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : nothingFunction}/>
                                                                                }
                                                                            </Row>
                                                                            <Row
                                                                                style={selectedAvatar.current === person.id ? {'width': '73px'} : {'width': '67px'}}>
                                                                                <h6 className={selectedAvatar.current === person.id ? "avatar-text-big text-uppercase" : "avatar-text text-uppercase"}
                                                                                    style={selectedAvatar.current === person.id ? {color: `${setStatusColor(person.status)}`} : {}}>
                                                                                    {((person.firstName === null || person.firstName === "") && (person.lastName === null || person.lastName === "")) ?
                                                                                        upperCaseFirst(`${person.name}`)
                                                                                        : <span><span
                                                                                            className="ellipsis-1">{upperCaseFirst(`${person.firstName}`)} </span><span
                                                                                            className="ellipsis-1">{upperCaseFirst(`${person.lastName}`)}</span></span>
                                                                                    }
                                                                                </h6>
                                                                            </Row>
                                                                        </CardContent>
                                                                    </div>
                                                                ))
                                                            }
                                                        </Container>
                                                    }
                                                </Container>
                                            </Col>
                                        </Row>
                                    }
                                </Fragment>
                            }
                            <Row className={'d-flex align-items-center my-2'}>
                                <Col className={'d-flex justify-content-start'}>
                                    <span className={classes.titles}>{t('claims.title')}</span>
                                </Col>
                                <Col className={'d-flex justify-content-end'}>
                                    <button onClick={() => navigate('/claims/history')}
                                            className={'small-outline-button'}>{t('claims.claims-history-button')}</button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {
                        !noPeople ?
                            <Container className="top-of-the-dashboard"></Container>
                            :
                            <Container className="top-of-the-dashboard" style={{'paddingTop': '112px'}}></Container>
                    }
                    <Row className="mt-2" style={{
                        paddingBottom: '57px'
                    }}>
                        <Col>
                            {
                                isLoadingPersonal &&
                                <Fragment>
                                    <Row className="mt-2">
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                            {
                                isLoadingPeople &&
                                <Fragment>
                                    <Row className="mt-2">
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                            {
                                !isLoadingPeople && isNothingPeople && selectedAvatar.current === 'null' &&
                                <Container>
                                    <Row>
                                        <img src={nothing} className="noMessages"
                                             alt={t('messages.nothing-text-1')}
                                        ></img>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="no-messages-text">{t('claims.nothing-text-1')}</span>
                                        </Col>
                                    </Row>
                                </Container>
                            }
                            {
                                !isLoadingPeople && isNothingPeople && selectedAvatar.current !== 'null' &&
                                <Container>
                                    <Row>
                                        <img src={nothing} className="noMessages"
                                             alt={t('messages.nothing-text-1')}
                                        ></img>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="no-messages-text">{t('claims.nothing-text-1')}</span>
                                        </Col>
                                    </Row>
                                </Container>
                            }
                            {!isLoadingPeople && !isNothingPeople &&
                                peopleClaims.map((value, index) => (
                                    <Card className={classes.card}
                                          key={index}
                                          onClick={() => onClickClaim(value.id)}>
                                        <CardContent style={{'paddingBottom': '16px'}}>
                                            <Row>
                                                <Col className="col-8">
                                                    <h6 className={'card-text-mid ellipsis-1-v2'}>{value.claimtype}</h6>
                                                </Col>
                                                <Col className="col-4">
                                                    {
                                                        value.image === null && value.gender === 'M' &&
                                                        <Avatar src={baseMaleAvatar} className={classes.cardAvatar}
                                                                alt="male-avatar"/>
                                                    }
                                                    {
                                                        value.image === null && value.gender === 'F' &&
                                                        <Avatar src={baseFemaleAvatar} className={classes.cardAvatar}
                                                                alt="male-avatar"/>
                                                    }
                                                    {
                                                        value.image === null && value.gender === 'C' &&
                                                        <Avatar src={baseCompanyAvatar} className={classes.cardAvatar}
                                                                alt="male-avatar"/>
                                                    }
                                                    {
                                                        value.image === null && value.gender === null &&
                                                        <Avatar src={baseMaleAvatar} className={classes.cardAvatar}
                                                                alt="male-avatar"/>
                                                    }
                                                    {
                                                        value.image &&
                                                        <Avatar src={value.image} className={classes.cardAvatar}
                                                                alt="male-avatar"/>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className={'card-text-content ellipsis-1-v2'}>
                                                        {t('claims.practise-number')}
                                                        <span
                                                            className="font-weight-bold black">
                                                                {value.claimnr}
                                                        </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className={'card-text-content ellipsis-1-v2'}>{t('claims.open-date')}<span
                                                        className="font-weight-bold black">
                                                        <Moment locale="it"
                                                                format="DD/MM/YYYY">{value.eventdate}</Moment>
                                                </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            {value.controparte && <Row>
                                                <Col>
                                                    <h6 className={'card-text-content ellipsis-1-v2'}>
                                                        {t('claims.counterpart')}
                                                        <span className="font-weight-bold black">
                                                            {value.controparte}
                                                        </span>
                                                    </h6>
                                                </Col>
                                            </Row>}
                                            <Row>
                                                <Col>
                                                    <h6 className={'card-text-content ellipsis-1-v2'}>
                                                        {t('claims.status')}
                                                        <span className="font-weight-bold">
                                                            <span
                                                                style={(value.settledValueCents > 0 && value.closeType === 'SINISTRO CHIUSO') ? {color: themeContext.color.green}
                                                                    : value.closeType === 'SINISTRO APERTO' ? {color: themeContext.color.mainActive}
                                                                        : (value.closeType === 'SINISTRO CHIUSO' ? {color: themeContext.color.red}
                                                                            : (value.closeType === 'SENZA SEGUITO' ? {color: themeContext.color.neutral200} : {}))}>
                                                                {value.closeType}
                                                            </span>
                                                        </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            {value.settledValueCents > 0 && <Row>
                                                <Col>
                                                    <h6 className={'card-text-content ellipsis-1-v2'}>
                                                        {t('claims.settled-value')}
                                                        <span className="font-weight-bold black">
                                                            {value.settledValue}
                                                        </span>
                                                    </h6>
                                                </Col>
                                            </Row>}
                                            {
                                                value.plate !== null &&
                                                <Row>
                                                    <div className="col-plate">
                                                        <img src={car} alt="car icon"/>
                                                        <span className={'card-text-content'}
                                                              style={{'marginLeft': '7px'}}>{value.plate}</span>
                                                    </div>
                                                </Row>
                                            }
                                        </CardContent>
                                    </Card>
                                ))
                            }
                        </Col>
                    </Row>
                    <Container className="fixed-bottom"
                               style={{
                                   'background': 'transparent',
                                   'opacity': '1',
                                   'paddingBottom': "100px"
                               }}>
                        <Row>
                            <Col>
                                <Button className="primary-button"
                                        variant="outlined"
                                        size="large"
                                        style={{width: 288}} type="submit"
                                        onClick={() => navigate('/claims/open')}
                                >{t('claims.open-claims-button')}</Button>
                            </Col>
                        </Row>
                    </Container>
                    <Container className="bottom-with-navbar" style={{'backgroundColor': themeContext.color.neutral10}}></Container>
                </Container>
            </Fragment>
    )
}