import React, {useContext, useEffect, useRef, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useLocation, useNavigate} from 'react-router-dom'
import AuthContextMiddleware from '../middleware/AuthContextMiddleware.js';
import {Button, FormHelperText, Link, Slide, TextField} from "@mui/material";
import jwt_decode from 'jwt-decode';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import wideLogoLightBetaBig from '../../images/wide-logo-white.svg';
import wideLogoLight from "../../images/wideLogo-icon.svg";
import Divider from "@mui/material/Divider";
import {makeStyles} from "@mui/styles";
import back from "../../images/back-icon.svg";
import DialogContentText from "@mui/material/DialogContentText";
import 'react-phone-number-input/style.css'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import {useForm} from "react-hook-form"
import MaterialPhoneNumber from "../middleware/PhoneNumber.js";
import spinner from "../../images/spinner.svg";
import exitIcon from "../../images/exit-icon.svg";
import {useMediaQuery} from "react-responsive";
import DialogDoubleAction from "../components/DialogDoubleAction.js";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";
import {StyledBackdrop} from "../styles/Styles.js";
import ErrorPage from "../pages/ErrorPage.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
    dialogTitle: {
        'textAlign': 'center !important',
        'lineHeight': '1.3 !important',
        'padding': '16px 24px !important',
        'fontSize': '15px !important',
        'fontWeight': '500 !important'
    },
}));

export default function LoginOtpVerify() {
    const {
        // Either pass a `control` property to the component
        // or wrap it in a `<FormProvider/>`.
        control,
        handleSubmit
    } = useForm()

    window.addEventListener('offline', () => {
        window.location.reload();
        return 0;
    });

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const invitationToken = query.get('invitation_token');
    const authCtx = useContext(AuthContextMiddleware);
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const [passwordDialog, setPasswordDialog] = useState(false);
    const checkPasswordUrl = `${process.env.REACT_APP_BASE_URL}/management/profile/checkPassword`;
    const acceptInvitationUrl = `${process.env.REACT_APP_BASE_URL}/management/auth/login/verify/number`;
    const loginWithUser = `${process.env.REACT_APP_BASE_URL}/management/auth/login/verify/user`;
    const loginOtpUrl = `${process.env.REACT_APP_BASE_URL}/management/auth/login/number`;
    const [passwordCriteriasError, setPasswordCriteriasError] = useState(false);
    const [passwordNotEqualError, setPasswordNotEqualError] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [email, setEmail] = useState("")
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [openSupportDialog, setOpenSupportDialog] = useState(false);
    const classes = useStyles();
    const [openOTPDialog, setOpenOTPDialog] = useState(false);
    const [openSelectUserDialog, setOpenSelectUserDialog] = useState(false);
    const [phoneValue, setPhoneValue] = useState(sessionStorage.getItem('phone'))
    const [password, setPassword] = useState(sessionStorage.getItem('newPassword'))
    const [phoneLang, setPhoneLang] = useState(sessionStorage.getItem('phone-lang'))
    const [isValid, setIsValid] = useState(true);
    const otpTimer = process.env.REACT_APP_ENV === 'STAGE' || process.env.REACT_APP_ENV === 'DEV' ? 10 : 60;
    const [seconds, setSeconds] = useState(otpTimer);
    const [disabled, setDisabled] = useState(true)
    const [people, setPeople] = useState([])
    const [selectedPerson, setSelectedPerson] = useState(null)
    const [userNotSelectedDialog, setUserNotSelectedDialog] = useState(false)
    const [otpError, setOtpError] = useState(null)
    const [loginWithUserError, setLoginWithUserError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'})
    const isRepeat = useMediaQuery({query: '(min-width: 600px)'})
    const [isLoadingPeopleList, setIsLoadingPeopleList] = useState(false);
    const [openConfirmExitDialog, setOpenConfirmExitDialog] = useState(false);


    useEffect(() => {
        if (navigator.onLine) {
            //checkPassword();
        }
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                clearInterval(interval);
                setDisabled(false)
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const defaultValues = {
        otpCode: "",
    };

    const handleOTPClose = () => {
        setOpenOTPDialog(false)
    }

    const handleSelectUserClose = () => {
        setOpenSelectUserDialog(false)
    }

    const formValues = useRef(defaultValues);

    const acceptInvitation = () => {
        let otpCode = formValues.current.otpCode;
        fetch(acceptInvitationUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                phone: phoneValue,
                code: otpCode
            }),
        }).then((res) => {
            return res.json();
        }).then((data) => {
            if (data.userList) {
                let length = Object.keys(data.userList).length;
                let email = ""
                if (length === 1) {
                    data.userList.map((value, index) => {
                        setSelectedPerson(value.email)
                        email = value.email
                    })
                    loginUserSelected(email);
                } else if (length > 1) {
                    data.userList.map((value, index) => {
                        setPeople(current => [...current, value])
                    })
                    setOpenSelectUserDialog(true)
                }
            } else {
                if (data.status === 400) {
                    //expired
                    setOtpError(data.status)
                } else if (data.status === 404) {
                    //wrong
                    setOtpError(data.status)
                }
            }
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const loginUserSelected = (email) => {
        setIsLoading(true)
        let otpCode = formValues.current.otpCode;
        fetch(loginWithUser, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                phone: phoneValue,
                code: otpCode
            }),
        }).then((res) => {
            return res.json();
        }).then((data) => {
            if (data.isLegacy === true) {
                setIsLoading(false)
                postRedirect(`${process.env.REACT_APP_MY_WIDE_URL}`, {
                    accessToken: data.accessToken,
                    expiresIn: data.expiresIn,
                    isLegacy: data.isLegacy,
                    pinCode: data.pinCode,
                    refreshExpiresIn: data.pinCode,
                    refreshToken: data.refreshToken,
                    userId: data.userId
                })
            } else {
                let decoded = jwt_decode(data.accessToken);
                if (localStorage.getItem('username-wide')) {
                    let prevUsername = localStorage.getItem('username-wide');
                    if (prevUsername !== decoded.email) {
                        sessionStorage.clear();
                        localStorage.removeItem('webauthn')
                    }
                }
                localStorage.setItem('username-wide', decoded.email);
                sessionStorage.removeItem('login_error');
                authCtx.login(data.accessToken, data.refreshToken, data.pinCode);
                setIsLoading(false)
                if (localStorage.getItem('people')) {
                    navigate('/settings/people');
                    localStorage.removeItem('people')
                } else {
                    navigate('/dashboard');
                }
            }
        }).catch(() => {
            setLoginWithUserError(true)
            setIsLoading(false)
        })
    }

    const handleInputChange = (e) => {
        setOtpError(null)
        const {
            name,
            value
        } = e.target;
        formValues.current = {
            ...formValues.current,
            [name]: value,
        }
    };

    const handlePasswordDialog = () => {
        setPasswordDialog(current => !current);
    }

    function reload() {
        window.location.reload();
    }

    function MailTo({
                        email,
                        subject,
                        body,
                        ...props
                    }) {
        return (
            <a style={{
                'color': themeContext.color.main,
                'textAlign': "center",
                'textDecoration': 'none',
                'width': '100%'
            }}
               href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
                {props.children}
            </a>
        );
    }

    const linkToRequestCredentials = () => {
        return navigate('/login/request-credentials');
    }

    const handleCloseSupportDialog = () => {
        setOpenSupportDialog(false);
    }

    const turnBack = () => {
        return navigate(-1);
    }

    const resendOtp = () => {
        setSeconds(otpTimer)
        setDisabled(true)
        fetch(loginOtpUrl, {
            method: 'POST',
            body: JSON.stringify({
                phone: phoneValue,
                lang: phoneLang
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            return res.json();
        }).then((res) => {
            if (res.ok) {
                setOpenOTPDialog(true)
            }
        });
    }

    const navigateToLogin = e => {
        return navigate('/login')
    }

    const handleUserNotSelectedDialogClose = () => {
        setUserNotSelectedDialog(false)
    }

    function postRedirect(path, params, method = 'post') {
        const form = document.createElement('form');
        form.method = method;
        form.action = path;
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = key;
                hiddenField.value = params[key];
                form.appendChild(hiddenField);
            }
        }
        document.body.appendChild(form);
        form.submit();
    }

    return (
        <div className="container-fluid" style={{'backgroundColor': themeContext.color.white}}>
            <div className="row no-gutter">
                <div className="col-md-6 d-none d-md-flex bg-image">
                    <Row style={{'margin': 'auto'}}>
                        <Col>
                            <img style={{
                                'height': '74.67px',
                                'marginBottom': '27px'
                            }} src={wideLogoLightBetaBig} alt="wide-logo-light"/>
                            <h6 style={{
                                'color': themeContext.color.white,
                                'fontSize': '16px',
                                'fontWeight': '300'
                            }}>{t('login.payoff-left-1')}</h6>
                            <h6 style={{
                                'color': themeContext.color.white,
                                'fontSize': '16px',
                                'fontWeight': '300'
                            }}>{t('login.payoff-left-2')}</h6>
                            <h6 style={{
                                'color': themeContext.color.white,
                                'fontSize': '16px',
                                'fontWeight': '300'
                            }}>{t('login.payoff-left-3')}</h6>
                        </Col>
                    </Row>
                </div>
                <div className="col-md-6">
                    <div className="login d-flex align-items-center">
                        <div className="container">
                            <Row className="d-flex justify-content-center align-items-center">
                                {   errorPage &&  <ErrorPage/>  }
                                {!errorPage && isLoadingData &&
                                    <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                                }
                                {!errorPage && !isLoadingData &&
                                    <Container style={{'backgroundColor': themeContext.color.white}}>
                                        <Row className="nav-top container"
                                             style={{
                                                 'backgroundColor': themeContext.color.white,
                                                 'paddingTop': '19.5px',
                                                 'paddingBottom': '20px',
                                                 'paddingLeft': '0',
                                                 'paddingRight': '0',
                                             }}>
                                            <Col className="col-2 float-left">
                                                <img src={back} onClick={turnBack}
                                                     className="nav-top-logo float-left cursor-pointer"
                                                     height="27px"
                                                     alt="back"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <img src={wideLogoLight} height="60px"
                                                     alt="wide-logo"/>
                                            </Col>
                                        </Row>
                                        <Row style={{'marginTop': '32px'}}>
                                            <Col>
                                                <h2 className="login-title align-center">{t('newOtp.title')}</h2>
                                                <h6 className="mt-4" style={{
                                                    'color': themeContext.color.neutral600,
                                                    'fontSize': '14px'
                                                }}>{t('newOtp.description-1')}</h6>
                                            </Col>
                                        </Row>
                                        <Row style={{'marginTop': '54px'}}>
                                            <Col>
                                                <PhoneInputWithCountry
                                                    disabled
                                                    label={t('newOtp.phone')}
                                                    value={phoneValue}
                                                    defaultValue={phoneValue}
                                                    //onChange={setPhoneValue}
                                                    defaultCountry={phoneLang ? phoneLang.toUpperCase() : 'IT'}
                                                    name="phoneInput"
                                                    control={control}
                                                    rules={{required: true}}
                                                    smartCaret
                                                    limitMaxLength
                                                    inputComponent={MaterialPhoneNumber}
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{'marginTop': '25px'}}>
                                            <Col>
                                                <TextField
                                                    id="otpCode"
                                                    className="custom-textfield"
                                                    fullWidth
                                                    label={t('loginOtpVerify.otp-code')}
                                                    variant="outlined"
                                                    name="otpCode"
                                                    type={"number"}
                                                    required={true}
                                                    value={formValues.otpCode}
                                                    onChange={handleInputChange}
                                                    color="primary"
                                                    error={otpError !== null}
                                                    InputProps={{
                                                        inputProps: {min: 0},
                                                        style: {
                                                            'borderRadius': '4px'
                                                        },
                                                    }}
                                                />
                                                {
                                                    (otpError === 400 || otpError === 404) &&
                                                    <FormHelperText style={{
                                                        color: themeContext.color.red,
                                                        marginTop: '5px'
                                                    }}>{t('loginOtpVerify.otp-error')}</FormHelperText>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col>
                                                <Link
                                                    className="float-left login-subtitle cursor-pointer"
                                                    color="primary" target="_new"
                                                    onClick={() => setOpenSupportDialog(true)} style={{
                                                    'color': themeContext.color.main
                                                }}>
                                                    {t('login.support-title')}
                                                </Link>
                                            </Col>
                                        </Row>
                                        <Row style={{'marginTop': '48px'}}>
                                            <Col>
                                                <Button className="btn-custom-2 text-uppercase"
                                                        onClick={acceptInvitation}
                                                        variant="outlined"
                                                        size="large" style={{'color': themeContext.color.white}}
                                                        type="submit">
                                                    {t('loginOtpVerify.button')}
                                                </Button>
                                            </Col>
                                        </Row>
                                        {seconds !== 60 &&
                                            <Row className="mt-4">
                                                <Col>
                                                    {
                                                        disabled &&
                                                        <Link
                                                            className="login-subtitle cursor-pointer"
                                                            target="_new"
                                                            style={{
                                                                'color': themeContext.color.neutral400
                                                            }}>
                                                            {t('loginOtpVerify.resend-disabled-1')} {seconds}{t('loginOtpVerify.resend-disabled-2')}
                                                        </Link>
                                                    }
                                                    {
                                                        !disabled &&
                                                        <Link
                                                            className="login-subtitle cursor-pointer"
                                                            onClick={resendOtp}
                                                            target="_new"
                                                            style={{
                                                                'color': themeContext.color.main
                                                            }}>
                                                            {t('loginOtpVerify.resend')}
                                                        </Link>
                                                    }
                                                </Col>
                                            </Row>
                                        }
                                    </Container>
                                }

                                <Dialog
                                    PaperProps={{
                                        style: {
                                            'color': themeContext.color.neutral600
                                        }
                                    }}
                                    open={passwordDialog}
                                    onClose={handlePasswordDialog}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title"
                                                 className="dialog-title">
                                        {passwordNotEqualError && t('newPassword.modal-text')}
                                        {passwordCriteriasError && t('newPassword.modal-criterias-title')}
                                    </DialogTitle>
                                    {
                                        passwordCriteriasError &&
                                        <DialogContent>
                                        <span>
                                            {t('newPassword.modal-criterias-description')}
                                        </span>
                                        </DialogContent>
                                    }
                                    <DialogActions>
                                        <Button onClick={handlePasswordDialog} color="primary"
                                                className="text-uppercase">
                                            {t('change-password.modal-button')}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    components={{Backdrop: StyledBackdrop}}
                                    open={openSupportDialog}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleCloseSupportDialog}
                                    aria-labelledby="alert-dialog-slide-title"
                                    aria-describedby="alert-dialog-slide-description"
                                    PaperProps={{
                                        style: {
                                            'position': 'absolute',
                                            'bottom': '0',
                                            'left': 'auto',
                                            'right': 'auto',
                                            'width': '95%',
                                            'borderRadius': '10px',
                                            'color': themeContext.color.neutral600
                                        },
                                    }}>
                        <span className={classes.dialogTitle}>
                        {t('login.support-title')}
                        </span>
                                    <Divider/>
                                    <DialogActions>
                                        <MailTo email={process.env.REACT_APP_MY_WIDE_SUPPORT_EMAIL}
                                                subject={t('login.email-subject')}
                                            //body={`${t('login.email-body-1')}%0D%0A%0D%0A%0D%0A${t('login.email-body-2')}%0D%0A%0D%0A`}
                                                body={`${t('login.email-body-problem')}%0D%0A%0D%0A`}>
                                            <Button style={{
                                                'color': themeContext.color.main,
                                                'textTransform': 'none'
                                            }}
                                                    fullWidth>
                                                {t('login.support-contact-us')}
                                            </Button>
                                        </MailTo>
                                    </DialogActions>
                                    <Divider/>
                                    <DialogActions>
                                        <Button onClick={handleCloseSupportDialog}
                                                style={{
                                                    'color': themeContext.color.neutral400,
                                                    'textTransform': 'none'
                                                }}
                                                fullWidth>
                                            {t('login.refuse-button')}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    components={{Backdrop: StyledBackdrop}}
                                    PaperProps={{
                                        style: {
                                            'color': themeContext.color.neutral600
                                        }
                                    }}
                                    open={openOTPDialog}
                                    onClose={handleOTPClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description">
                                    <DialogTitle id="alert-dialog-title"
                                                 className="dialog-title">{t('newOtp.dialog-title')}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description"
                                                           className="dialog-content-text">
                                            {t('newOtp.dialog-text')}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleOTPClose} color="primary">
                                            {t('newOtp.dialog-button')}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                fullScreen={!isDesktop}
                fullWidth={isDesktop}
                maxWidth={'sm'}
                PaperProps={{
                    style: {
                        bottom: isDesktop ? '5%' : '0',
                        color: themeContext.color.neutral600,
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                        borderRadius: isDesktop ? '20px' : 'none',
                        height: isDesktop ? '90%' : '100%',
                        marginTop: '10%'
                    }
                }}
                open={openSelectUserDialog}
                TransitionComponent={Transition}
                onClose={() => {
                    setOpenSelectUserDialog(false)
                }}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle id="alert-dialog-slide-title" className="dialog-title"
                             style={{textAlign: 'center'}}>
                    <Row style={{justifyContent: 'end'}}>
                        <img src={exitIcon} className="exit-button" alt="close" onClick={() => {
                            setOpenConfirmExitDialog(true)
                        }} style={{float: 'inline-end'}}/>
                    </Row>
                    <Row style={{marginTop: '-12px'}}>
                        <Col className={'pr-5'}>
                            <h6 className="dialog-form-title-1 float-left">{t('loginOtpVerify.dialog-title')}</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className="dialog-form-title-2 text-left">{t('loginOtpVerify.dialog-description')}</h6>
                        </Col>
                    </Row>
                </DialogTitle>
                <DialogContent className="no-scrollbar" style={{marginTop: '50px'}}>
                    <div className="dialog-main-container"
                         style={{paddingBottom: "96px"}}>
                        {isLoadingPeopleList &&
                            <div style={{'height': '30vh'}} className="background-loading-spinner"></div>
                        }
                        {
                            !isLoadingPeopleList &&
                            <div>
                                {
                                    people.map((value, index) => (
                                        value.email === selectedPerson ?
                                            <Row key={index}
                                                 className={'selected-user'}
                                                 onClick={() => {
                                                     setSelectedPerson(value.email);
                                                     setUserNotSelectedDialog(false);
                                                     setLoginWithUserError(false);
                                                 }}
                                            >
                                                <Col>
                                                    <span
                                                        className="d-flex justify-content-center black"
                                                        style={{fontSize: '17px'}}>{value.email}</span>
                                                </Col>
                                            </Row>
                                            :
                                            <Row key={index}
                                                 className={'not-selected-user'}
                                                 onClick={() => {
                                                     setSelectedPerson(value.email);
                                                     setUserNotSelectedDialog(false);
                                                     setLoginWithUserError(false);
                                                 }}
                                            >
                                                <Col>
                                                    <span
                                                        className="d-flex justify-content-center black"
                                                        style={{fontSize: '17px'}}>{value.email}</span>
                                                </Col>
                                            </Row>
                                    ))
                                }
                                <Row style={{
                                    'marginTop': '27px'
                                }}>
                                    <Col>
                                                <span hidden={!userNotSelectedDialog}
                                                      className="login-otp-error red">
                                                    {t('loginOtpVerify.user-not-selected-dialog-description')}
                                                </span>
                                    </Col>
                                </Row>
                                {loginWithUserError &&
                                    <Row style={{'maxWidth': '424px', 'margin': '0 auto'}}>
                                        <Col>
                                            <h6 className="login-otp-error red">{t('loginOtpVerify.login-error')}</h6>
                                        </Col>
                                    </Row>
                                }
                            </div>
                        }
                        <Container className="position-absolute"
                                   style={
                                       isDesktop ? {
                                           background: 'transparent',
                                           opacity: '1',
                                           left: 0,
                                           bottom: 48,
                                           right: 0
                                       } : {
                                           background: 'transparent',
                                           opacity: '1',
                                           left: 0,
                                           bottom: 68,
                                           right: 0
                                       }
                                   }>
                            <Row style={{textAlign: 'center'}}>
                                <Col>
                                    <Button className="close-button boxShadow"
                                            variant="outlined"
                                            size="large"
                                            style={{
                                                color: themeContext.color.white,
                                                width: '288px',
                                                textTransform: 'none'
                                            }}
                                            type="submit"
                                            disabled={false}
                                            onClick={() => selectedPerson !== null ? loginUserSelected(selectedPerson) : setUserNotSelectedDialog(true)}>
                                        {isLoading &&
                                            <img src={spinner} className="spinner-img-inner-button" alt="spinner"/>
                                        }
                                        {!isLoading && t('loginOtpVerify.dialog-button')}
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </DialogContent>
            </Dialog>
            <DialogDoubleAction open={openConfirmExitDialog}
                                title={t('login.dialog-confirm-exit-title')}
                                description={t('login.dialog-confirm-exit-description')}
                                handleClose={() => setOpenConfirmExitDialog(false)}
                                handleNo={() => setOpenConfirmExitDialog(false)}
                                deleteAction={true}
                                handleYes={() => {
                                    setOpenConfirmExitDialog(false)
                                    setOpenSelectUserDialog(false)
                                }}
                                buttonYesText={t('login.dialog-confirm-exit-confirm')}
                                buttonNoText={t('login.dialog-confirm-exit-refuse')}/>
        </div>
    )
}
