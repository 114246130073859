import React, {createContext, useState} from 'react';

export const InterceptorContext = createContext();

export const InterceptorProvider = ({children}) => {
    const [title, setTitle] = useState(null);
    const [message, setMessage] = useState(null);
    const [apiList, setApiList] = useState([]);

    const handleTitle = (value) => {
        setTitle(`ERRORE DI SISTEMA: ${value}`);
    };

    const handleMessage = (statusText, ok, status, url) => {
        let string = '';
        if (statusText) {
            string += `StatusText: ${statusText}\n`;
        }
        if (ok) {
            string += `OK: ${ok}\n`;
        }
        if (status) {
            string += `Status: ${status}\n`;
        }
        if (url) {
            string += `URL: ${url}`;
        }
        setMessage(string);
    };


    return (
        <InterceptorContext.Provider
            value={{
                title,
                message,
                handleTitle,
                handleMessage,
                apiList,
                setApiList,
            }}>
            {children}
        </InterceptorContext.Provider>
    );
};